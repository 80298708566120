import React, { MutableRefObject, createContext, useContext, useRef, useMemo } from 'react';
import { HmClient, HmInstance, HmContext, initializeAction, updateSettings } from 'HmContext';
import { ResidentSettings, getResidentSettings, saveResidentSettings } from 'api/v1/residents/settings';
import clientsettings, { ClientSettings } from 'api/v1/clientsettings';
import { getBillingFeatures } from 'api/v1/billing/features/get';
import { useAsync } from 'react-async';
import { myStatusClient } from 'api/v1/auth/my';
import { toast } from 'components/HmAlert';
import { dateTimeUtils } from 'lib.core/dates';
import { getDivisions } from 'api/v1/divisions/divisions';
import { saveBillingContacts } from 'api/v1/billing/billingcontacts';
import { Roles } from 'lib.app/enums';
import { useHistory } from 'react-router';
import { setHttpConfig } from 'api';
import { HmUrl } from 'lib.app/routes';

export interface ClientContextState {
  client: HmClient;
  instances: ClientSettings[];
  features: HmInstance[];
  wildcard: MutableRefObject<string>;
  changeClient: (clientId: number, instanceId?: number) => Promise<any>;
  changeWildcard: (wildcard: string) => Promise<any>;
  changeInstance: (instanceId: number) => Promise<any>;
  getDetail: () => Promise<any>;
  saveDetail: (values: any) => Promise<any>;
  getResidentSettings: () => Promise<ResidentSettings>;
  saveResidentSettings: (obj: any) => Promise<ResidentSettings>;
  getDivisions: () => Promise<any>;
}

export const ClientContext = createContext({} as ClientContextState);

const loadClientData = async ({user, client}) => {
  if (!user.isLoggedIn) return { client, instances: [], features: [] };

  const [instances, features ] = await Promise.all([clientsettings.query(), getBillingFeatures()])

  return {client, instances, features};
};

const contextWatch = (cur, prev) => {
  return cur.user.userId !== prev.user.userId || cur.client.clientId !== prev.client.clientId;
}

let divisionsPromise: Promise<any> | null = null;

export const ClientContextProvider = props => {
  const {client, instance, settings, user, dispatch} = useContext(HmContext);
  const {data, isLoading } = useAsync(loadClientData, { user, client, watchFn: contextWatch });
  const wildcard = useRef(instance.wildcard);
  const history = useHistory();
  const {clientId} = client;
  setHttpConfig('setLocation', history.replace);

  const changeClient = useMemo(() => (id: number, instanceId?: number) =>
    myStatusClient(id, instanceId).then(c => {
      if (clientId !== undefined) {
        if (clientId !== id) {
          history.replace(HmUrl.home); // changed client
        }
        else if (c.settings && c.settings.wildcard) {
          if (history.location.pathname.includes(wildcard.current) && wildcard.current !== c.settings.wildcard) {
            const newPath = history.location.pathname.replace(wildcard.current, c.settings.wildcard);
            history.replace(newPath, { wildcardChanged: true });
          }
          wildcard.current = c.settings.wildcard;
        }
      }
      c.hasClient = true;
      dispatch({ type: initializeAction.action, payload: c });
    }).catch(e => {
      toast(e);
    }), [dispatch, history, clientId]);

  const settingsParams = {admin: user.roles && user.roles.includes(Roles.LindseyEmployee)};

  const state: ClientContextState = useMemo(() => {
    if (isLoading || !data) {
      return { client, instances: [], features: [] } as any;
    }

    const updateGoLiveDate = async (values) => {
      if(dateTimeUtils.dateDiff(settings.goLiveDate, values.goLiveDate, 'days') !== 0) {
        settings.goLiveDate = values.goLiveDate;
        await clientsettings.save({values: settings, wildcard: instance.wildcard, admin: false})
        dispatch({ type: updateSettings.action, payload: settings });
      }
      return values;
    }

    divisionsPromise = null;
    const getDivisionsPromise = () => {
      if (divisionsPromise === null) {
        divisionsPromise = new Promise(resolve => {
          resolve(getDivisions(instance));
        });
      }
      return divisionsPromise;
    }

    return {
      ...data,
      wildcard,
      getDetail: ({wildcard}) => clientsettings.get({...settingsParams, wildcard}),
      saveDetail: ({values, wildcard}) => Promise.all([clientsettings.save({values, ...settingsParams, wildcard}), saveBillingContacts({wildcard, values})]),
      getResidentSettings: () => getResidentSettings(instance).then(r => {
        return ({ ...r, goLiveDate: settings.goLiveDate });
      }),
      saveResidentSettings: ({values}) => saveResidentSettings({wildcard: instance.wildcard, values}).then(r => updateGoLiveDate(values)),
      changeClient,
      changeInstance: (instanceId: number) => changeClient(clientId, instanceId),
      changeWildcard: async (wildcard: string) => {
        const instance = data.features.find(f => f.wildcard === wildcard);
        if (!instance) return false;
        return changeClient(clientId, instance.instanceId);
      },
      getDivisions: getDivisionsPromise
    };
  }, [data, user.userId, clientId, instance.instanceId, instance.wildcard, settings.goLiveDate, isLoading]);

  // don't render while loading since there are peices that rely on this information
  if (isLoading) return null;

  return <ClientContext.Provider value={state}>{props.children}</ClientContext.Provider>;
};
