import React, { useState } from 'react';
import { useFormState } from '../../hooks/useFormState';
import { useFormFieldState } from '../../hooks/useFormFieldState';
import { IconChevronLeft, IconChevronRight } from '../icons';
import { Button } from 'react-bootstrap';
import { ui } from '../../lib.core';
import Portal from 'components/layouts/Portal';

export const FormState = ({ className = '' }) => {
  const { api } = useFormState();
  const [show, setShow] = useState(false);
  return (
    <Portal id="drawers">
    <div className={ui.classNames('shadow drawer drawer-right', { show })}>
      <Button
        onClick={e => {
          setShow(!show);
        }}
        variant="light"
        className="form-state shadow">
        {show ? <IconChevronRight /> : <IconChevronLeft />} Form
      </Button>
      <pre>
        <code>FORM: {JSON.stringify(api.state, undefined, 2)}</code>
      </pre>
    </div>
    </Portal>
  );
};

export const FieldState = ({ name, className = '' }) => {
  const field = useFormFieldState(name);
  const [show, setShow] = useState(false);
  const feildName = name.toUpperCase();
  return (
    <Portal id="drawers">
    <div className={ui.classNames('shadow drawer drawer-right', className, { show })}>
      <Button
        onClick={e => {
          setShow(!show);
        }}
        variant="light"
        className="field-state shadow">
        {show ? <IconChevronRight /> : <IconChevronLeft />} {feildName}
      </Button>
      <pre>
        <code>
          {feildName}: {JSON.stringify(field, undefined, 2)}
        </code>
      </pre>
    </div>
    </Portal>
  );
};
