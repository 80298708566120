import React, { FunctionComponent } from 'react';

const headingProps = {
  alert: {
    element: 'h3',
    className: 'mb-4'
  },
  error: {
    element: 'h3',
    className: 'mb-4'
  },
  empty: {
    element: 'p',
    className: 'h1 text-center text-primary'
  },
  filter: {
    element: 'h5',
    className: 'mb-1 h-filter'
  },
  inline: {
    element: 'strong',
    className: 'd-block',
  },
  layout: {
    element: 'h4',
    className: 'mb-0'
  },
  list: {
    element: 'h4',
    className: 'mb-0'
  },
  main: {
    element: 'h1',
    className: 'mb-4 h-main'
  },
  modal: {
    element: 'h4',
    className: 'mb-2',
  },
  navbar: {
    element: 'h4',
    className: 'mb-0 text-light'
  },
  section: {
    element: 'h4',
    className: 'mb-2 h-section'
  },
  splash: {
    element: 'h4',
    className: 'mb-3'
  },
  sub: {
    element: 'h3',
    className: 'mb-4 h-sub'
  },
  tagline: {
    element: 'h4',
    className: 'mb-4'
  },
};
interface HeadingProps {
  variant: keyof typeof headingProps;
  [k: string]: any;
}

export const Heading: FunctionComponent<HeadingProps> = ({ variant, ...props }) => {
  const { element: Header, ...rest } = headingProps[variant] as any;

  return (
    <Header {...rest} {...props}>
      {props.children}
    </Header>
  );
};
