export enum HmFeatures {
  ApplicationAdvancedEligibilityRules,
  ApplicationAdvancedOpeningTypes,
  ApplicationAutoRenewDrop,
  ApplicationCustomQuestionsAndPreferences,
  ApplicationEdit,
  Applications,
  ApplicationTwoFactorAuth,
  AssignApplicationToUser,
  CustomerApplications,
  CustomerResidents,
  CustomerUnits,
  CustomerWordOrders,
  DevEnvironment,
  InstanceBillingContact,
  Ivr,
  Legacy,
  LindseySoftwareOnly,
  MaintenanceRequests,
  MisconfiguredMaintenanceReqeusts,
  MisconfiguredOnlineRent,
  OnlineCustomersOnly,
  OnlineRentCollection,
  ReadOnly,
  ResidentPortal,
  Residents,
  RoleManager,
  SomeOnlineInstances,
  Trial,
  Units,
  WaitingListManagement,
  WordOrders,
}
