import { useEffect } from 'react';
import { toast } from 'components/HmAlert';

let lastActivity = new Date();

export function useActivityTimeout(onTimeout, timeoutInSeconds, suppressFn = () => false) {

  useEffect(() => {
    const iv = setInterval(() => {
      if (suppressFn()) {
        lastActivity = new Date();
        return;
      }
      const diff = (new Date().getTime() - lastActivity.getTime()) / 1000;
      if (diff > timeoutInSeconds) {
        onTimeout();
      } else if (diff > (timeoutInSeconds / 2)) {
        toast(`Your session will end in ${timeoutInSeconds/60} minutes, if no activity is detected.`, { showFor: timeoutInSeconds * 1000, variant:'warning' });
      }
    }, timeoutInSeconds * 1000);
    return () => clearInterval(iv);
  }, [timeoutInSeconds, suppressFn]);
}

export function activityDetected() {
  return lastActivity = new Date();
}