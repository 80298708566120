import React from 'react';
import { useClientState } from '../../hooks/useClientState';
import { HmGuard } from 'components/security/HmGuard';

export const ClientStatus = props => {
  const client = useClientState();

  if (!client || !client.clientId) {
    return null;
  }

  return (
    <span {...props}>
      <strong>{client.clientName}</strong>
      <HmGuard access="employeesOnly">
        <small>{client.accountStatus || 'NO INSTANCE'}</small>
      </HmGuard>
    </span>
  );
};
