import { hasError } from "../hasError";

export function valueInitializer(initialValue) {
	let internalValue = initialValue || '';
	return ({ value, setter, setError, props: { ref, ...fieldProps } = { ref: undefined } }) => {
		return {
			...fieldProps,
			value: internalValue,
			onChange: e => {
				setter((internalValue = e.target.value));
			},
			onBlur: e => {
				if (e.target.willValidate)
					e.target.checkValidity();
				setError(hasError(e.target));
			}
		};
	};
}
