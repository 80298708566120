import { isEquivalent } from './isEquivalent';
import Immutable from 'seamless-immutable';

function isMergebleObject(value) {
  return isNonNullObject(value) && !isSpecial(value);
}

function isNonNullObject(value) {
  return !!value && typeof value === 'object';
}

function isSpecial(value) {
  // see https://github.com/facebook/react/blob/b5ac963fb791d1298e7f396236383bc955f916c1/src/isomorphic/classic/element/ReactElement.js#L21-L25
  const canUseSymbol = typeof Symbol === 'function' && Symbol.for;
  const REACT_ELEMENT_TYPE = canUseSymbol ? Symbol.for('react.element') : 0xeac7;

  return (
    value.$$typeof === REACT_ELEMENT_TYPE
  );
}

export default function merge<T>(obj: T, ...sources: T[]): T {
  if (!sources) {
    return obj;
  }
  return sources.reduce((target: any, src: any) => {
    if (src === undefined || src === target) return target;

    if (Array.isArray(src)) {
      if (!Array.isArray(target)) target = isEquivalent(target, {}) || target === undefined ? [] : [target];
      const seen: any[] = [];
      return (
        target
          .map(i => merge({}, i))
          .concat(src.map(i => merge({}, i)))
          .filter(i => {
            const indx = seen.find(x => isEquivalent(x, i));
            const notExists = indx === undefined;
            if (notExists) seen.push(i);
            return notExists;
          })
      );
    }

    if (target instanceof Date && src) {
      const d = new Date(src);
      if (target.hasOwnProperty('toJSON') || src.hasOwnProperty('toJSON')) {
        d.toJSON = src.toJSON || target.toJSON;
      }
      return d;
    }

    if (src instanceof Date) {
      const d = new Date(src.valueOf());
      if (src.hasOwnProperty('toJSON')) {
        d.toJSON = src.toJSON;
      }
      return d;
    }

    if (!isMergebleObject(src)) {
      return src;
    }

    Object.getOwnPropertyNames(src).forEach(copyProperty);
    Object.getOwnPropertySymbols(src).forEach(copyProperty);

    return target;

    // Copy source's own properties into target's own properties
    function copyProperty(property) {
      const descriptor = Object.getOwnPropertyDescriptor(src, property);
      if (!descriptor || !target) return; // shouldn't happen but...safety

      const srcValue = src[property];
      const targetValue = target[property];

      if (descriptor.enumerable) {
        // Copy in-depth first

        descriptor.value = targetValue !== undefined && srcValue !== undefined ? merge(Object.create(Object.getPrototypeOf(targetValue || srcValue || {})), targetValue, srcValue) : clone(srcValue);
        Object.defineProperty(target, property, descriptor); // shallow copy descriptor
      }
    }
  }, obj);
}

export const clone = <T>(target: T, ...sources: T[]): T => !target ? target : merge(Object.create(Object.getPrototypeOf(target)), target, ...sources);

export const mergeAt = (target, dottedProperty, update) =>
  Immutable.updateIn(target, dottedProperty.split('.'), update);
