import React, { FunctionComponent } from 'react';
import { HmPhone } from './HmPhone';
import { HmEmail } from './HmEmail';
import { ContactProps, HmAddress } from './HmAddress';

export const HmContact: FunctionComponent<ContactProps> = ({ email, phone, ...address }) => {
  return (
    <>
      <HmAddress {...address} />
      {phone && <HmPhone phone={phone} />}
      {email && <HmEmail email={email} />}
    </>
  );
};
