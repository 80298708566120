import React, { FunctionComponent, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { layoutMap } from '.';
import { HmGuard } from '../security/HmGuard';
import { HmGuardProps } from '../../lib.security';
import { usePageTitle, useBodyClassName } from '../../hooks/useHmLayout';
import { useCurrentWildcard } from 'hooks/useInstanceState';
import { useClientContext } from 'hooks/useClientContext';
import { HmUrl } from 'lib.app/routes';

declare type LayoutName = keyof typeof layoutMap;

interface LayoutProps {
  name: LayoutName;
  pageTitle?: string;
  className?: string;
  guard?: HmGuardProps;
  nav?: any;
  sidebar?: any;
  heading?: any;
  append?: any;
  hideFooter?: boolean;
}

export const Layout: FunctionComponent<LayoutProps> = ({
  children,
  pageTitle = '',
  className = '',
  name,
  guard,
  ...props
}) => {
  usePageTitle(pageTitle);
  useBodyClassName(className);
  useWatchWildcard();

  const Layout = layoutMap[name] as any;

  const layout = (
    <Layout {...props}>
      {children}
    </Layout>
  );

  return guard ? <HmGuard to={HmUrl.login} {...guard}>{layout}</HmGuard> : layout;
};

function useWatchWildcard() {
  const wildcard = useCurrentWildcard();
  const { changeWildcard } = useClientContext();
  const history = useHistory();
  const {wildcard: matchWildcard} = useParams<{wildcard?: string}>();

  useEffect(() => {
    if (matchWildcard && wildcard && matchWildcard !== wildcard
        && (history.action === 'PUSH' || history.action === 'POP')) {
      changeWildcard(matchWildcard);
      history.replace({
        search: '',
        state: { wildcardChanged: true}
      });
    }
  }, [wildcard, matchWildcard]);
}