import React from 'react';
import { Form } from 'react-bootstrap';
import { initialize } from 'hooks/useFormFieldState';
import { HmDatePicker, initializeDatePicker, timeDefaults, dateTimeDefaults, dateTimeInputDefaults } from './DatePicker';
import { IconEmail, IconPhone, IconSearch } from '../icons';
import { textMask } from '../../lib.app/masks';
import { initializeToggle, FormToggleButton } from './ToggleButtonField';

export const onOffOptions = [{ label: 'ON', value: true}, {label: 'OFF', value: false}];

export const formFields = {
  text: {
    initializer: initialize.input,
    component: Form.Control
  },
  email: {
    initializer: initialize.input,
    component: Form.Control,
    type: 'email',
    prependText: <IconEmail />
  },
  phone: {
    initializer: initialize.input,
    component: Form.Control,
    type: 'phone',
    prependText: <IconPhone />,
    ...textMask('phone')
  },
  password: {
    initializer: initialize.input,
    component: Form.Control,
    type: 'password'
  },
  date: {
    initializer: initialize.dateFormat(),
    component: Form.Control
  },
  'date-picker': {
    initializer: initializeDatePicker({ outputFormat: 'ISODate'}),
    component: HmDatePicker
  },
  'utcdate-picker': {
    initializer: initializeDatePicker(),
    component: HmDatePicker
  },
  'time-picker': {
    initializer: initializeDatePicker(timeDefaults),
    component: HmDatePicker
  },
  'datetime-picker': {
    initializer: initializeDatePicker(dateTimeDefaults),
    component: HmDatePicker
  },
  'datepicker-timeinput': {
    initializer: initializeDatePicker(dateTimeInputDefaults),
    component: HmDatePicker
  },
  range: {
    initializer: initialize.number,
    component: Form.Control,
  },
  select: {
    initializer: initialize.select,
    component: Form.Control,
    as: 'select',
    className: 'custom-select'
  },
  fax: {
    initializer: initialize.input,
    component: Form.Control,
    ...textMask('phone')
  },
  number: {
    initializer: initialize.number,
    component: Form.Control,
    type: 'number'
  },
  currency: {
    initializer: initialize.number,
    component: Form.Control,
    format: v => Number(v).toFixed(2),
    prependText: '$',
  },
  search: {
    initializer: initialize.input,
    component: Form.Control,
    type: 'search',
    prependText: <IconSearch />
  },
  toggle: {
    initializer: initializeToggle,
    component: FormToggleButton,
    variant: 'primary',
    getOptionValue: o => o.value,
    getOptionLabel: o => o.label
  },
  toggleOnOff: {
    initializer: initializeToggle,
    component: FormToggleButton,
    options: onOffOptions,
    variant: 'primary',
    getOptionValue: o => o.value,
    getOptionLabel: o => o.label
  }
};
