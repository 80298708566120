import {
  preventsAll,
  preventsDefault,
  noPropagation,
  normal
} from './eventHandler';

import classNames from 'classnames';

export default {
  classNames,
  dontPropogate: preventsAll,
  eventHandlers: {
    preventsAll,
    preventsDefault,
    noPropagation,
    normal
  }
};
