import React, { FunctionComponent, useEffect } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormField } from '.';
import { Text } from 'components';
import { useFieldApi } from 'hooks/useFormFieldState';
import { ui } from 'lib.core';
import {dateTimeUtils} from 'lib.core/dates';
import { useCurrentSettings } from 'hooks/useInstanceState';
import matchers from 'lib.core/matching/matchers';

export const DateRange = ({
  label = 'Date',
  startName,
  startLabel = 'Start Date',
  endName,
  endLabel = 'End Date',
}) => {
  return (
    <>
      <strong>{label}</strong>
      <Form.Row className="align-items-center justify-content-md-between">
        <Col>
          <FormField
            name={startName}
            label={startLabel}
            labelSrOnly
            fieldType="date"
          />
        </Col>
        <Col className="align-self-center" sm={1}>
          to
        </Col>
        <Col>
          <FormField
            name={endName}
            label={endLabel}
            labelSrOnly
            fieldType="date"
          />
        </Col>
      </Form.Row>
    </>
  );
};

export const DateRangePicker: FunctionComponent<any> = ({
  legend = 'Date Range',
  startName = 'start',
  startLabel = 'Start',
  endName = 'end',
  endLabel = 'End',
  separator = ' to ',
  inputOnly = false,
  fieldType = 'date-picker',
  instanceTime = false,
  optional = false,
  minDate,
  maxDate
}) => {
  const start = useFieldApi(startName);
  const end = useFieldApi(endName);
  const {timeZoneId} = useCurrentSettings();

  useEffect(() => {
    if (!end.value || matchers.isString(end.value) || matchers.isString(start.value)) return;
    if (fieldType === 'time-picker') {
      const days = dateTimeUtils.differenceInDays(end.value, start.value);
      if (days !== 0) {
        end.setValue(dateTimeUtils.addDays(days, end.value));
      }
    }
    if (fieldType === 'datetime-picker' && end.value < start.value) {
      const days = dateTimeUtils.differenceInDays(end.value, start.value);
      end.setValue(dateTimeUtils.addDays(days + 1, end.value));
    }
  }, [start.value, end.value]);

  const componentProps = {
    outputFormat: instanceTime ? 'ISOLocal' : undefined,
    timeZoneId: instanceTime ? timeZoneId : undefined,
    inputOnly,
    disabledKeyboardNavigation: true,
    isClearable: optional,
    popperProps: {
      positionFixed: true
    },
    popperModifiers: {
      preventOverflow: {
        enabled: true,
        escapeWithReference: false, // force popper to stay in viewport (even when input is scrolled out of view)
        boundariesElement: 'viewport'
      },
    }
  }
  const hasLegend = !!legend;

  return (
    <div className={ui.classNames('field-group', {'reserve-legend': legend})}>
      {legend &&<Text as="legend">{legend}</Text>}
      <FormField
        fieldType={fieldType}
        name={startName}
        label={startLabel}
        componentProps={{
          minDate,
          maxDate: end.value || maxDate,
          popperPlacement: 'bottom-start',
          ...componentProps
        }}
        labelSrOnly={hasLegend}
        required={!optional}
        watch={[end.value]}
      />
      <Text inline className={ui.classNames('field-group-seperator', {'input-group-text': hasLegend })}>
        {separator}
      </Text>
      <FormField
        fieldType={fieldType}
        name={endName}
        label={endLabel}
        disabled={optional ? false : !start.value}
        componentProps={{
          maxDate,
          minDate: start.value || minDate,
          popperPlacement: 'bottom-end',
          ...componentProps
        }}
        labelSrOnly={hasLegend}
        required={!optional}
        watch={[start.value]}
      />
      </div>
  );
};
