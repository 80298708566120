import React from 'react';
import AsyncSelect from 'react-select/async';
import { searchClients } from '../../api/v1/admin/searchClients';
import { MutedText } from 'components';

const Option = (props) => {
  const { innerProps, innerRef } = props;
  return (
    <article ref={innerRef} {...innerProps} className="account-option">
      <strong>{props.data.clientName}</strong>
      <div className="sub">{props.data.city}, {props.data.state} {props.data.hasInstance !== true && <MutedText>NO INSTANCE</MutedText>}</div>
    </article>
  );
};

export const ClientSelect = (props: any) => {
  const getLabel = option => option.clientName;
  const getValue = option => option.clientId;

  const loadOptions = inputValue =>
    searchClients(inputValue).then(result => result.clients);

  return (
    <AsyncSelect
      className="client-select"
      loadOptions={loadOptions}
      components={{ Option }}
      getOptionLabel={getLabel}
      getOptionValue={getValue}
      {...props}
    />
  );
};
