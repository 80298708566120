import React, { FunctionComponent } from 'react';
import { useFilterContext } from 'hooks/useFilter';
import { Form, FormField } from './';
import { ui } from 'lib.core';
import { Button } from 'react-bootstrap';
import { Heading, CollapsePanel } from 'components';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

interface FormFilterProps {
  name?: string;
  replaceValue?: boolean;
  autoSubmit?: string[];
  autoSubmitDelay?: number;
  className?: any;
  showFormState?: any;
}

export const FormFilter: FunctionComponent<FormFilterProps> = ({ name, replaceValue = false, className, children, ...props }) => {
  const {filter, dispatch: setFilter} = useFilterContext();
  const dim = useWindowDimensions();

  const applyFilter = ({values}) => {
    setFilter(f => {
      f.setValue(values, replaceValue);
      f.skip = 0;
    }, replaceValue);
    return Promise.resolve();
  };

  return (
    <Form value={filter.value} onSubmit={applyFilter} className={ui.classNames('filter-card', className)} {...props}>
      {dim.has('md') ? children : (
        <CollapsePanel title={`Filter ${name}`} collapsed className="collapse-panel" headingClassName="filter-btn">
          {children}
        </CollapsePanel>
      )}
    </Form>
  );
};


export const FilterHeading = ({label = 'Filter', reset = false}) => {
  return (
    <Heading variant="filter">
      {label}{' '}
      {reset && <ClearFilter>Reset</ClearFilter>}
    </Heading>
  );
};

export const FilterSearch = ({label, labelSrOnly = true, name }) => {
  const {filter} = useFilterContext();
  return (
    <FormField name={name} label={label} placeholder={`Search ${filter.totalCount} ${label}`} fieldType="search" labelSrOnly={labelSrOnly} watch={[filter.totalCount]} />
  )
}

export const ClearFilter: FunctionComponent<any> = ({children, replace, ...props}) => {
  const {tryAction} = useFilterContext();

  return (
    <Button variant="link" size="sm" className="clear-filters" onClick={() => {
      tryAction('reset');
    }} {...props}>
      {children}
    </Button>
  )
}

export default FormFilter;
