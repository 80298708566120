import React, { FunctionComponent } from 'react';
import { Heading, Text } from 'components/typography';
import Layout from 'components/layouts';
import { HmDetail, HmDate, HmGuard } from 'components';
import { useTour } from './hooks/useTour';
import { TourCard as TourCardType, TourStep as TourStepType } from 'api/v1/tour';
import { Card, CardDeck, CardColumns, CardGroup, ProgressBar, Container, Button } from 'react-bootstrap';
import { IconLock, IconCheck } from 'components/icons';
import { ui } from 'lib.core';
import { Link } from 'react-router-dom';
import { HmUrl } from 'lib.app/routes';

export const TourDashboard : FunctionComponent<any> = (props) => {
  const { tour, actions } = useTour();

  return (
    <Layout name="hm" pageTitle="Tour Dashboard" className="splash-page welcome-page">
      <Container fluid>
        <TourWelcome />
        <HmDetail onInit={actions.activate}>
          <TourCards />
        </HmDetail>
        {!tour.hasClientFinishedTour && <Link to={HmUrl.home} onClick={actions.end} id="explore-link">I've got it. Let me explore on my own</Link>}
      </Container>
    </Layout>
  );
};

const TourWelcome = () => {
  const {tour, actions} = useTour();
  return (
    <HmGuard access={!tour.hasClientFinishedTour} component={
      <>
        <Heading variant="main"><IconCheck /> You're all done</Heading>
        <Link to={HmUrl.home} className="btn btn-primary btn-lg" onClick={actions.finish}>I've got it. Let me explore on my own.</Link>
      </>
    }>
        <Heading variant="main">Welcome to simple, powerful online rental applications.</Heading>
        <Heading variant="tagline">The tasks below will guide you step-by-step through the process of using My.HousingManager.com.</Heading>
    </HmGuard>
  )
}

const TourCards: FunctionComponent<any> = () => {
  const {tour} = useTour();

  if (!tour.cards) return null;

  return (
    <CardDeck>
      {tour.cards.map((c,idx) => <TourCard key={idx} {...c} stepNumber={idx + 1} />)}
    </CardDeck>
  )
}


const TourCard: FunctionComponent<TourCardType & { stepNumber: number }> = ({ duration, isActive, percentageComplete, steps, name, stepNumber, isCompleted, isLocked, nextStep }) => {

  return (
    <>
      <Card className={ui.classNames('tour-card', 'shadow-lg', {active: isActive, locked: isLocked })}>
        <Card.Header>{name}</Card.Header>
        <Card.Body>
          <Card.Text as="div">
            <HmDate variant="muted" date={`1970-01-01T${duration}`} compareDate={new Date('1970-01-01T00:00:00')} prepend>About{' '}</HmDate>
            <ProgressBar variant="success" now={percentageComplete} max={100} />
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Text as="ul" className="list-unstyled">
            {steps.map(step => <TourStep key={step.tourStepId} {...step} />)}
          </Card.Text>
        </Card.Body>
        <Card.Body>
        </Card.Body>
        <Card.Footer>
          {!isLocked && <Button as={Link} block disabled={isCompleted} className={ui.classNames({disabled: isCompleted})} variant="primary" to={(nextStep && nextStep.href) || '/'}>{isCompleted ? 'Complete' : 'Continue'}</Button>}
          {isLocked && <IconLock className="ml-auto text-success" />}
        </Card.Footer>
      </Card>

      {stepNumber % 2 === 0 && <div className="w-100 d-none d-sm-block d-md-none"></div>}
      {stepNumber % 3 === 0 && <div className="w-100 d-none d-md-block d-lg-none"></div>}
      {stepNumber % 4 === 0 && <div className="w-100 d-none d-lg-block"></div>}
    </>
  )
}

const TourStep: FunctionComponent<TourStepType> = ({name, isCompleted, href}) => {
  return (
    <Text as="li">
      {isCompleted ? <IconCheck /> : <span className="bullet">•</span>}&nbsp;
      {!isCompleted ? <Text inline>{name}</Text> : <Link to={href}>{name}</Link>}
    </Text>
  );
};
