import { useSharedState } from "./useSharedState"
import { useEffect, useLayoutEffect } from "react";

const breakpoints = {
  xl: 1200,
  lg: 992,
  md: 768,
  sm: 576,
  xs: 0,
}

type MediaBreakpoint = keyof typeof breakpoints;

type WindowDimensions = {
  width: number;
  height: number;
  breakpoints: MediaBreakpoint[];
  breakpoint: MediaBreakpoint;
  has: (breakpoint: MediaBreakpoint) => boolean;
}

function buildDimensions(): WindowDimensions {
  const dim = {
    width: window.innerWidth,
    height: window.innerHeight,
  }
  const breakpoints = findBreakpoint(dim);
  const [breakpoint] = breakpoints;

  return {
    ...dim,
    breakpoints,
    breakpoint,
    has: bp => breakpoints.includes(bp)
  }
}


let debounceHandle;
let resizeHandler;
function handleResize(setDim) {
  if (debounceHandle) clearTimeout(debounceHandle);
  debounceHandle = setTimeout(() => setDim(buildDimensions()), 200);
}
export function useWindowDimensions(onChange?: (dim:WindowDimensions) => any) {
  const [dim] = useSharedState<WindowDimensions>('windowDimensions',
    ({setValue}) => {
      resizeHandler = () => handleResize(setValue);
      window.addEventListener('resize', resizeHandler)
      return buildDimensions();
    },
    () => resizeHandler && window.removeEventListener('resize', resizeHandler)
  );

  useLayoutEffect(() => {
    onChange && onChange(dim);
  }, [dim])

  return dim || buildDimensions();
}

function findBreakpoint({width}): MediaBreakpoint[] {
  return Object.keys(breakpoints).filter(k => width >= breakpoints[k]) as any;
}
