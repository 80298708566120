import React, { useMemo, useState, useRef, FunctionComponent } from 'react';
import { Popover, Overlay, Row, Col, Badge, Container } from 'react-bootstrap';
import { HmGuard } from 'components/security/HmGuard';
import { HmDetailForm, FormField } from 'components/forms';
import { HmDetail } from 'components/HmDetail';
import { searchApplications } from 'api/v1/search/applications';
import { useDetailState, useDetailActions } from 'hooks/useDetail';
import { ui } from 'lib.core';
import {
  IconCheck,
  IconDelete,
  IconChevronRight,
  IconUser,
  IconBed,
  IconSpinner,
  IconTimes
} from 'components/icons';
import { Link } from 'react-router-dom';
import { HmUrl } from 'lib.app/routes';
import { Heading, Text } from 'components';
import { ClearField } from 'components/forms/FormField';

export const NavSearch = ({ component: Search = ApplicantSearch, ...props }) => {
  return <Search {...props} />;
};

const searchApplicants = async ({ values, context, dispatch }) => {
  context.refresh = false;
  if (!values.searchText || values.searchText.length < 3) return {
    ...values,
    show: false,
    loading: false
  };

  searchApplications(values.searchText).then(applicants =>
    dispatch(state => ({
      ...state,
      applicants: applicants.applicationsearchresults,
      loading: false
    })));

  return {
    ...values,
    applicants: [],
    show: true,
    loading: true
  };
};

const reducers = {
  hideResults: state => ({
    ...state,
    show: false,
    loading: false,
    searchText: ''
  })
};

const ApplicantSearch = () => {
  // use reducer of HmDetail probably
  return (
    <HmGuard access="applicationsAnyInstance">
      <HmDetail
        initialValue={{ show: false }}
        onSave={searchApplicants}
        onSaved={() => void 0}
        reducers={reducers}>
        <HmDetailForm autoSubmit={['searchText']} className="navbar-search" inline>
          <SearchText />
          <ApplicantSearchResult selector=".navbar-search .input-group" />
        </HmDetailForm>
      </HmDetail>
    </HmGuard>
  );
};

const SearchText = () => {
  const [focus, setFocus] = useState(false);
  const { loading } = useDetailState();
  return (
    <div
      onFocus={() => setFocus(true)}
      onBlur={() => setFocus(false)}
      className={ui.classNames({ dark: !focus })}>
      <FormField
        name="searchText"
        label="Search Applicants"
        autoComplete="off"
        fieldType="search"
        labelSrOnly
        append={loading ? <span className="input-overlay"><IconSpinner /></span> : undefined}
        autoFocus={focus}
        watch={[loading]}
      />
    </div>
  );
};

const ApplicantSearchResult = ({ selector }) => {
  const { applicants = [], searchText = '', show, loading } = useDetailState();
  const { hideResults } = useDetailActions();

  const getTarget = useMemo(
    () => () => {
      const target = document.querySelector(selector);
      return target as any;
    },
    [selector]
  );

  if (!show) return null;

  return (
    <Overlay
      placement="bottom-end"
      target={getTarget}
      show={show}
      rootClose
      onHide={hideResults}>
      <Popover id="applicantResults">
        <ApplicantResults applicants={applicants} searchText={searchText} loading={loading} />
      </Popover>
    </Overlay>
  );
};

const HightlightText = ({ text, higlight }) => {
  const parts = text.split(new RegExp(`(${higlight})`, 'gi'));
  return (
    <>
      {parts.map((part, idx) => (
        <Text key={idx} inline bold={part.toLowerCase() === higlight.toLowerCase()}>
          {part}
        </Text>
      ))}
    </>
  );
};


const ApplicantResults: FunctionComponent<any> = ({ applicants = [], searchText, loading }) => {
  if (loading) return <IconSpinner />;
  if (applicants.length === 0) return <NoResults />;

  return (
      <div className="results">
        {applicants.map((applicant, idx) => (
    <Row
      noGutters
      key={idx}
      className="applicant"
      onClick={({ currentTarget, target }) => {
        if (target.className === 'waiting-list') return;
        currentTarget.querySelector('a')!.click();
      }}>
      <Col className="name">
        <HightlightText text={applicant.matchedDisplayName} higlight={searchText} />
        <ApplicantRelationship {...applicant} />
      </Col>
      <Col sm="auto" className="indicators">
        <Applicant {...applicant} />
        <Beds {...applicant} />
      </Col>
      <WaitListItems
        items={applicant.applicationWaitingLists}
        instanceId={applicant.instanceId}
        applicationId={applicant.applicationId}
      />
    </Row>
  ))}
      </div>
    )

};

const ApplicantRelationship = ({ matchedRelationshipId }) => {
  if (matchedRelationshipId !== 1) return null;
  return <Badge variant="secondary">Primary</Badge>;
};

const Applicant = ({ numFamilyMembers }) => {
  return (
    <Text inline className="indicator applicant">
      {numFamilyMembers ? numFamilyMembers : '?'} <IconUser />
    </Text>
  );
};

const Beds = ({ minNumBeds, maxNumBeds }) => {
  let beds = '';
  if (minNumBeds || maxNumBeds) {
    if (minNumBeds === maxNumBeds) {
      beds = minNumBeds;
    } else if (!minNumBeds) {
      beds = maxNumBeds;
    } else if (!maxNumBeds) {
      beds = minNumBeds;
    } else {
      beds = minNumBeds + '-' + maxNumBeds;
    }
  }
  return (
    <Text inline className="indicator beds">
      {beds} <IconBed />
    </Text>
  );
};

const WaitListItems = ({ items, instanceId, applicationId }) => {
  const actions = useDetailActions();
  return items.map((item,idx) => {
    const purged = item.applicationStatusId === -1;

    return (
      <Row noGutters key={idx} className={ui.classNames({ purged })} >
        <Col>
          <Link
            to={HmUrl.applicationDetail(
              instanceId,
              item.waitingListCode,
              item.waitingListName,
              item.applicationWaitingListId,
              applicationId
            )}
            onClick={e => {
              actions.hideResults()
              e.stopPropagation();
            }}
            className="waiting-list">
            <WaitingListIcon {...item} />{' '}
            <Text strikeThrough={item.applicationStatusId === -1} inline>
              {item.waitingListName}
            </Text>
          </Link>
        </Col>
        <Col sm="auto" className="go">
          <IconChevronRight />
        </Col>
      </Row>
    );
  });
};

const WaitingListIcon = ({ applicationStatusId }) => {
  if (applicationStatusId === 4) {
    return <IconCheck />;
  }
  if (applicationStatusId === 3) {
    return <IconDelete />;
  }
  if (applicationStatusId === -1) {
    return <Badge variant="danger">DROPPED</Badge>;
  }

  return <IconCheck className="text-hide" />;
};

const NoResults = () => {
  return (
    <div className="text-center">
      <Heading variant="modal">No results found.</Heading>
      <p>Check your spelling and try again.</p>
      <div className="text-right muted">
        <strong>ESC</strong> to cancel
      </div>
    </div>
  );
};
