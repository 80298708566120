function envMapper<T>(mapper, fallbackDefault: T) {
  return (key, defaultValue = fallbackDefault) => {
    let val = process.env[key];
    if (typeof val === 'undefined') return defaultValue;

    let trimmed = val.trim();

    if (trimmed.length === 0) return defaultValue;

    return mapper(val);
  };
};

const string = envMapper(val => val, '');
const bool = envMapper(
  val => (val.toLowerCase() === 'true' ? true : false),
  false,
);
const number = envMapper(val => Number(val), -1);

export default {
  bool,
  string,
  number,
};
