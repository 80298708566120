import React from 'react';
import { recentClients } from '../../api/v1/admin/recentClients';
import { useAsync } from 'react-async';
import { IconSpinner } from '../icons';
import { Button, Badge } from 'react-bootstrap';

const loadRecent = () => recentClients();

export const RecentClients = ({ onSelect, ...props }) => {
  const { data, isLoading } = useAsync({ promiseFn: loadRecent });

  if (isLoading) return <IconSpinner />;
  if (data) {
    return (
      <div {...props}>
        <strong>Recent clients:</strong>
        {data.map((c, idx) => (
        <div  key={idx} className="recent-client">
          <Button onClick={e => onSelect(e, c)} variant="primary" block className="text-left">
            <Badge variant="primary">{c.firstInstanceFeatures.clientMostProminentAccountStatus}</Badge>
            <strong>{c.clientName}</strong>
            <span className="sub d-block">{c.city}, {c.state}</span>
          </Button>
        </div>
        ))}
      </div>
    );
  }
  return <>No data</>;
};
