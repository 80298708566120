import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';


const pollyfills = [];

try {
	const d = new Date();
	new Intl.DateTimeFormat('en', {
		timeZone: 'America/Los_Angeles',
		timeZoneName: 'long'
	}).format();


} catch (error) {
	pollyfills.push(import(/* webpackChunkName: "timezones" */ 'date-time-format-timezone'))
}

export default pollyfills;