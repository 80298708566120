import React, { ReactNode, FunctionComponent, useMemo } from 'react';
import { OverlayTrigger, Tooltip, Popover } from 'react-bootstrap';
import { IconInfoCircle } from '../icons';
import { Variant } from '../typography/Text';
import { Placement } from 'react-bootstrap/Overlay';

type TriggerType = 'hover' | 'focus' | 'click'

export type TooltipProps = {
  id?: string;
  show?: boolean;
  tooltip: ReactNode;
  placement?: Placement;
  children?: ReactNode;
  trigger?: TriggerType | TriggerType[];
  disabled?: boolean;
  disabledAs?: any;
}

export type PopoverProps = {
  id?: string;
  popover: ReactNode;
  title?: ReactNode | string;
  placement?: Placement;
  children?: ReactNode;
  trigger?: TriggerType | TriggerType[];
}


export type InfoLabelProps = TooltipProps & {
  variant?: Variant;
}

export const InfoLabel: FunctionComponent<InfoLabelProps> = ({variant= 'info', id, placement = 'right', tooltip, children}: any) => {
  const tId = useMemo(() => (id || 'tooltip-'+(tooltips++)), []);
  return (
    <>
      {children} {' '}
      <OverlayTrigger
        placement={placement}
        overlay={<Tooltip id={tId}>{tooltip}</Tooltip>}>
        <IconInfoCircle className={`text-${variant}`} />
      </OverlayTrigger>
    </>
  );
};

let tooltips = 0;
export const HmTooltip: FunctionComponent<TooltipProps> = ({id, placement = 'bottom', tooltip, trigger, children, show = true, disabled = false, disabledAs: As = 'span'}) => {
  const tId = useMemo(() => (id || 'tooltip-'+(tooltips++)), []);

  if(!show) return <>{children}</>;

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={<Tooltip id={tId}>{tooltip}</Tooltip>}>
      {disabled ? <As className="disabled-tooltip">{children}</As> : children}
    </OverlayTrigger>
  )
}


let popovers = 0;
export const HmPopover: FunctionComponent<PopoverProps> = ({id, placement = 'bottom', popover, title, trigger, children}) => {
  const tId = useMemo(() => (id || 'popover-'+(popovers++)), []);

  return (
    <OverlayTrigger
      trigger={trigger}
      placement={placement}
      overlay={<Popover title={title as any} id={tId}>{popover}</Popover>}>
      {children}
    </OverlayTrigger>
  )
}