import { checkInstanceFeature } from 'lib.security';
import { HmFeatures } from 'lib.app/enums';
import { useGuardContext } from './useGuard';
import { HmInstance } from 'HmContext';

export function useFirstInstanceWithFeature(feature: HmFeatures) {
  const { instance, instances } = useGuardContext();
  const check = checkInstanceFeature(feature);

  return check(instance) ? instance : instances.find(check) || { accountStatusId: 0 } as HmInstance;
}

export function useInstancesWithFeature(feature: HmFeatures) {
  const { instances } = useGuardContext();
  const check = checkInstanceFeature(feature);

  return instances.filter(check);
}

export function useClientsWithFeature(feature: HmFeatures) {
  const { clients = [] } = useGuardContext();
  const filtered = useInstancesWithFeature(feature);

  return clients.filter(c => filtered.some(f => f.instanceId === c.id));
}
