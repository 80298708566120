import React, { FunctionComponent } from 'react';
import { ui } from 'lib.core';
import { Portal } from './Portal';

interface LayoutHeaderProps {
  className?: any;
  start?: boolean;
  end?: boolean;
  center?: boolean;
  around?: boolean;
  between?: boolean;
}

export const LayoutHeader: FunctionComponent<LayoutHeaderProps> = ({
  children,
  className,
  start = false,
  end = false,
  center = false,
  around = false,
  between = false,
  ...props
}) => {
  const classNames = {
    'justify-content-start': start,
    'justify-content-end': end,
    'justify-content-center': center,
    'justify-content-between': between,
    'justify-content-around': around
  };
  return (
    <Portal id="hmHeader">
      <header
        className={ui.classNames( 'header-action', classNames, className)}
        {...props}>
        {children}
      </header>
    </Portal>
  );
};
