import React, { FunctionComponent, useEffect, useReducer, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

type ViewState = {
  view: string,
  track: boolean;
  [x:string]: any;
}

function viewReducer(state, action): ViewState {
  const {type = action, payload} = action;
  return type(state, payload);
}


function setViewAction(state: ViewState, {view, viewData}) {
  return {
    ...state,
    view,
    viewData
  }
}

export const SwitchView: FunctionComponent<any> = ({ viewId, children, track = false, ...props }) => {
  const [{view, track: tracking, viewData = {}}, dispatch] = useReducer(viewReducer, {view: viewId, track })
  const setView = useCallback((view, viewData ?: any) => dispatch({type: setViewAction, payload: {view, viewData}}), []);
  const history  = useHistory();
  const location = useLocation();

  useEffect(() => {
    setView(viewId);
  }, [viewId]);

  useEffect(() => {
    if (!tracking) return;
    function popper(e) {
      if (e.state.state && e.state.state.view) {
        e.preventDefault();
        e.stopPropagation();
        setView(e.state.state.view);
      }
    }
    window.addEventListener('popstate', popper);
    return () => window.removeEventListener('popstate', popper);
  }, [tracking])

  useEffect(() => {
    tracking && history.push(location.pathname, {view});
  }, [view, tracking]);

  useEffect(() => {
    const hash = location.hash.substr(1);
    tracking && hash && view !== hash && setView(hash);
  },[location.hash, view, tracking])

  const views = {};

  React.Children.forEach(children, v => {
    if (!React.isValidElement(v)) return;
    const { key } = v;
    if (key) views[key] = v;
  });

  if (view && views[view])
    return React.cloneElement(views[view], { actions: { setView }, ...props, ...viewData });

  return null;
};
