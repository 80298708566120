import { useContext, useRef, useEffect } from 'react';
import { FormContext, IFormContext } from '../components/forms/FormContext';
import { setPathValue, clear, addFieldError } from '../components/forms/reducers';
import { getValue } from '../components/forms/utils';
import { merge } from '../lib.core';
import { inputInitializer, valueInitializer, checkInitializer, checkListInitializer, formatDateInitializer, selectInitializer, numberInitializer, FieldStateInitializer } from './formUtils';
import { activityDetected } from './useActivityTimeout';

export interface FormFieldProps<T = string> {
  value?: T;
  checked?: boolean;
  name: string;
  dependencies?: any[];
  [x: string]: any;
}

interface FormFieldState<T = any> {
  dependencies: any[];
  props: FormFieldProps;
  api: FormFieldApi<T>;
}

export interface FormFieldApi<T = any> {
  setValue: (value: T, replace?: boolean) => void;
  setError: (error: any) => void;
  clear: (touched?: any) => void;
  value: T;
  errors: any;
  valid: boolean;
  touched: boolean;
  meta: any;
}

export const initialize = {
  input: inputInitializer,
  inputValue: valueInitializer,
  checkbox: checkInitializer,
  checkboxList: checkListInitializer,
  dateFormat: formatDateInitializer,
  select: selectInitializer,
  number: numberInitializer
};

function canManageCursor(field) {
  return field && ['text', 'search'].includes(field.type);
}

export function useFormFieldState<T = any>(
  path: string,
  initializer: FieldStateInitializer = initialize.input,
  fieldProps: any = {}
): FormFieldState {

  const { state } = useContext(FormContext) as IFormContext<T>;
  const api = useFieldApi(path);
  const field = useRef<any>();
  const cursor = useRef<number | false>(false);

  useEffect(() => {
    if (canManageCursor(field.current) && cursor.current !== false) {
      setTimeout(() => {
        field.current.setSelectionRange(cursor.current, cursor.current);
        cursor.current = false;
      }, 0);
    }
    activityDetected();
  }, [api.value]);

  const dependencies = [api.value, api.errors, api.touched];
  const { dependencies: fieldDep = [], ...props } = initializer({
    values: state.value,
    value: api.value === undefined ? false : api.value,
    touched: api.touched,
    setter: api.setValue,
    setError: api.setError,
    meta: api.meta,
    cursor,
    props: fieldProps
  });
  return {
    dependencies: dependencies.concat(fieldDep),
    props: {
      name: path,
      ref: field,
      ...props
    },
    api
  };
}

export function useFieldApi<T = any>(path: string): FormFieldApi<T> {
  const { state, dispatch } = useContext(FormContext) as any;
  if (path.endsWith('.')) path = path.slice(0, -1);

  const { validateOnBlur = true, time, fields: meta = {} } = state.meta;

  return {
    value: getValue(state.value, path),
    errors: state.errors[path] || false,
    touched: getValue(state.touched, path, state.validated),

    setValue: (value, replace = false) => dispatch(setPathValue.send({ path, value, replace })),
    setError: error => dispatch(addFieldError.send({ path, error })),

    clear: (touched = false) => dispatch(clear.send(path, touched)),

    valid:
      getValue(state.touched, path, state.validated) === false ||
      getValue(state.errors, path, false) === false,

    meta: merge({ name: path, validateOnBlur, time }, getValue(meta, path, {}))
  };
}
