import React, { createContext, FunctionComponent, useReducer, useCallback, useMemo, useContext, ReactNode, Dispatch, useState, useEffect } from 'react';
import { FormApi } from '../hooks/useFormState';
import { Text, HmAlert } from 'components';
import { toastSuccess, toast, ExceptionMessage } from './HmAlert';
import { HmPageLoading } from './HmLoading';
import { useAsync } from 'react-async';
import { HmDialogContext } from './HmDialog';
import { useDetail } from 'hooks/useDetail';
import { Button } from 'react-bootstrap';
import { clone } from 'lib.core';
import { Redirect } from 'react-router';
import { HmUrl } from 'lib.app/routes';
import { useFilterContext } from 'hooks/useFilter';
import { useSentry } from './errors/SentryErrorBoundary';


export type HmDetailAction<T> = (state: HmDetailContextState<T>) => any;

type DetailContext = {
  keepData: boolean;
  refresh: boolean;
  close?: boolean;
}

export type DetailSaveProps<T = any, A = any> = {
  values: T,
  context: DetailContext,
  [x: string]: any;
  actions: A;
  wildcard : string;
  dispatch: Dispatch<any>;
}
export type DetailSaveFn<T = any, A = any> = (obj: DetailSaveProps<T, A>, nothing?: any) => Promise<T>;

export interface HmDetailProps<T = any, A = any> {
  onSave?: DetailSaveFn<T, A>;
  successMessage?: string;
  initialValue?: object;
  onInit?: (obj: any) => Promise<any>;
  onError?: (e: any) => void;
  onSaved?: (values: any) => void;
  loading?: ReactNode;
  reducers?: any;
  selectors?: any;
  bubble?: boolean;
  [x: string]: any;
}

export type HmDetailContextState<T, A = any> = {
  state: T;
  save: (api: FormApi<T>) => Promise<T>;
  refresh: () => Promise<T>;
  actions: A;
  [x: string]: any;
};

export const HmDetailContext = createContext({} as HmDetailContextState<any>);

function detailReducer(state, action) {
  const { type: typeFn, payload } = action;
  if (typeof typeFn === 'function') action = typeFn(state, payload);
  else if (typeof action === 'function') action = action(state);
  else action = state;
  return action;
}

const watchWildcard = (cur, prev) => cur.wildcard !== prev.wildcard;

export const HmDetail: FunctionComponent<HmDetailProps> = ({
  successMessage = 'Item saved!',
  initialValue = {},
  onInit = () => Promise.resolve(initialValue),
  onSave = () => Promise.resolve(false),
  onError = e => toast(e),
  onSaved = () => toastSuccess(successMessage),
  loading = <HmPageLoading />,
  reducers = {},
  selectors = {},
  actions = {},
  wildcard,
  watchFn = wildcard ? watchWildcard : undefined,
  children,
  bubble = true,
  ...props
}) => {
  const { refresh: parentRefresh,  actions: parentActions = {} } = useContext(HmDetailContext);
  const { tryRefresh } = useFilterContext();

  const promiseFn = useMemo(() => {
    if (onInit) return onInit;
    return () => Promise.resolve(initialValue);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...Object.values(initialValue)]);

  const [state, dispatch] = useReducer(detailReducer, undefined);
  const { reload, error, isLoading } = useAsync({
    promiseFn,
    onResolve: (data) => {
      return dispatch(reducers.init ? { type: reducers.init, payload: data } : () => data);
    },
    wildcard,
    watchFn,
    ...props
  });

  const refresh = useMemo(
    () => async (cancelBubble = false) => {
      await reload();
      if (cancelBubble || !bubble) return;
      if (parentRefresh) return await parentRefresh();
      if (tryRefresh) {
        return tryRefresh();
      }
    },
    [reload]
  );

  const save = useCallback(
    async ({ values, ...formApi }: FormApi<any>) => {
      try {
        const context: DetailContext = { refresh: true, keepData: true };

        const saved = await onSave({...props, wildcard, actions, values, context, dispatch});

        if (saved === undefined || saved === false) return;

        onSaved(saved);

        if (typeof saved === 'object' && context.keepData) {
          dispatch(state => ({...state, ...saved}));
        }

        if (context.refresh) {
          await refresh();
        }
      } catch (e) {
        if (e.message || e.error_description) {
          onError(e);
        } else {
          formApi.setError(e);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSave, actions, onSaved]
  );

  const captureException = useSentry(scope => {
    scope.setExtra('params', props);
  });

  Object.keys(reducers).reduce((action, key) => {
    action[key] = payload => dispatch({type: reducers[key], payload});
    return action;
  }, actions);

  Object.keys(selectors).reduce((action, key) => {
    action[key] = (...payload) => selectors[key]({dispatch, ...state}, ...payload);
    return action;
  }, actions);

  const hmDetail = {
    state,
    save,
    refresh,
    actions: {...parentActions, ...actions },
    wildcard,
    ...props
  };

  if (isLoading) {
    return loading as any;
  }

  if (error) {
    const {response} = error as any;
    if (response && response.status === 401) {
      return <Redirect to={HmUrl.login} />;
    }
    const eventId = captureException(error);
    return <HmAlert variant="danger"><ExceptionMessage error={error} eventId={eventId} /></HmAlert>;
  }

  // not ready to render just yet
  if (state === undefined) return null;



  return (
    <HmDetailContext.Provider
      value={hmDetail}>
      {children}
    </HmDetailContext.Provider>
  );
};

/**
 * Provides a context aware of dialogs.
 *
 * * adds `dialog`, `closeOnSave`, `actions` to `HmDetailContext`
 *
 * ```js
 * <HmDialog {...props}>
 *   <HmDialogDetail onSave={...}>
 *     <HmDetailForm {...}>...</HmDetailForm>
 *   </HmDialogDetail>
 * </HmDialog>
 * ```
 */
export const HmDialogDetail: FunctionComponent<HmDetailProps> = ({
  closeOnSave = true,
  onSave,
  children,
  ...props
}) => {
  const { dialog, actions } = useContext(HmDialogContext);
  const [close, setCloseOnSave] = useState(closeOnSave);

  const modalSave = useCallback(async ({values, context = {}, closeOnSave, ...details}) => {
    context.close =  closeOnSave;
    const saved = onSave && await onSave({...details, values, context});
    if (context.close) {
      dialog(actions.close());
    }
    return saved;
  }, [onSave]);

  const detailProps = {...props, dialog, actions, closeOnSave: close, setCloseOnSave };

  return (
    <HmDetail onSave={modalSave} {...detailProps}>
      {children}
    </HmDetail>
  );
};

export const DetailItem: FunctionComponent<any> = ({selector, action, content: Content = Text, children, actionProps, bindFilter = false, ...props}) => {
  const [data, actions, {refresh}] = useDetail();
  const { actions: filterActions } = useFilterContext();

  useEffect(() => {
    if (bindFilter && filterActions.current) {
      filterActions.current.set('refresh', () => refresh(true));
    }
  }, []);

  if (typeof children === 'function') {
    return children({data, ...props});
  }

  if (selector) {
    const value = selectData(data, selector);
    if (!value) {
      return null;
    }
    return <Content {...props} children={value}></Content>;
  }

  const cData = action && actions[action] ? actions[action](actionProps) : data;
  return React.Children.map(children, (c) => {
    if (React.isValidElement(c)) {
      return React.cloneElement(c, {data: cData, ...props});
    }
    return c;
  });
};

function selectData(data, selector) {
  if (!data) return data;
  return typeof selector === 'string' ? data[selector] : selector(data);
}

export const DetailActionButton: FunctionComponent<any> = ({
  action,
  children,
  reload = false,
  actionArgs,
  ...props
}) => {
  const [state, actions, {refresh, dialog}] = useDetail();
  const handleClick = useCallback(async () => {
    const actOn = typeof action === 'string' && actions[action] ? actions[action] : action;
    await actOn(actionArgs, {state: clone(state), actions, dialog});
    if (reload) await refresh();
  }, [action, actionArgs, actions, dialog, refresh, reload, state]);

  return (
    <Button onClick={handleClick} {...props}>
      {children}
    </Button>
  );
};