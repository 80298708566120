import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { HmGuard } from '../security/HmGuard';

export const HmNavLink = ({ children, navOrder, ...props }: any) => {
  const Link = (
    <Nav.Link key={props.to} as={NavLink} {...props}>
      {children}
    </Nav.Link>
  );

  if (props.guard) {
    return (
      <HmGuard {...props.guard} component={null}>
        {Link}
      </HmGuard>
    );
  }

  return Link;
};

HmNavLink.defaultProps = {
  to: '#',
  activeClassName: 'active'
};

export default HmNavLink;
