import { Layout } from './Layout';

import { Splash } from './Splash';
import { HousingManager } from './HousingManager';
import { ContentSidebar } from './ContentSidebar';
import { ContentSimple, ContentChild } from './ContentSimple';
import { LayoutHeader } from './LayoutHeader';

export  { Layout, Splash, HousingManager, ContentSidebar, ContentSimple, LayoutHeader };

export const layoutMap = {
  hm: HousingManager,
  content: ContentSimple,
  sidebar: ContentSidebar,
  splash: Splash,
  error: Splash,
  child: ContentChild
};

export { Layout as default };
