import {
  withMock,
  HmInstanceApiRequest,
  Ok200,
  InstanceParams,
  InstanceDetailRequest,
  handleNullResponse,
  noContentMock
} from '../../../';
import { HmRequestOptions } from '../../../http';
import { residentHttp } from '..';

const request = residentHttp;

export const getResidentSettings: HmInstanceApiRequest<InstanceParams, ResidentSettings> = (
  { wildcard },
  options = Ok200
) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './settings.mock.js'))
    .url(`/${wildcard}/settings`)
    .get();

export const saveResidentSettings: HmInstanceApiRequest<
  InstanceDetailRequest<ResidentSettings>,
  ResidentSettings
> = ({ wildcard, values }, options: HmRequestOptions = noContentMock) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ '../../../generic.mock.js'))
    .url(`/${wildcard}/settings`)
    .put({ ...values })
    .catch(handleNullResponse);

export interface ResidentSettings {
  convFeeAchPercentage: number;
  convFeeAchFlat: number;
  convFeeCreditPercentage: number;
  convFeeCreditFlat: number;
  allowConvenienceFee: boolean;
  autoChargeRent: boolean;
  source: number;
  isEditable: boolean;
  shouldChargeHmConvenienceFee: boolean;
  shouldChargeRentLateFee: boolean;
  lateFeeDaysAfterRentDue: number;
  initialRentLateFee: number;
  shouldChargeAdditionalDailyRentLateFee: boolean;
  additionalDailyRentLateFee: number;
  isUsingMoveOutDate: boolean;
  shouldZeroOutBalance: boolean;
  portalTitle: string;
  sources: Source[];
}

export interface Source {
  id: number;
  name: string;
}
