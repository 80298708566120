export const noContentMock = { mock: { status: 204, statusText: 'No Content' } };
export const Ok200 = { mock: { status: 200, statusText: 'OK' } };
export const OkTrue = { mock: { status: 200, statusText: 'TRUE' } };
export const OkFalse = { mock: { status: 200, statusText: 'FALSE' } };
export const genericMockFn = () => import(/* webpackChunkName: "mockApi" */ './generic.mock');

// for those times when nothing is returned, which JSON parsers dislike
export const handleNullResponse = res => {
  // returns empty response on success, ignore this error
  if (res instanceof SyntaxError ||
    (res && res.name === 'SyntaxError')) { // MS Edge (before chromium version) handles this ...wierdly
    return true;
  }
  throw res;
};
