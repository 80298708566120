import React, { useState } from 'react';
import { Layout } from '../components/layouts/Layout';
import { HmForm, FormField } from '../components/forms';
import { Link } from 'react-router-dom';
import { forgotPassword } from '../api/v1/auth/forgot';
import { addPageError } from '../components/HmAlert';
import { HmSubmit } from '../components/forms/HmSubmit';
import { useDeferedAsync } from '../hooks/useDeferedAsync';
import { FormSubmit } from '../hooks/useFormState';
import { Heading } from '../components/typography';

// don't use this as a template, just playing around with thnings
export const ForgotPassword = props => {
  const { isLoading, run } = useDeferedAsync(forgotPassword);
  const [ sentReset, setSentReset ] = useState(false) as any;

  const handleSubmit: FormSubmit = async ({values, ...actions}) => {
    try {
      const request = await run(values);
      setSentReset(request);
    } catch (e) {
      addPageError(e.username);
      actions.setError(e, true);
    }
  };

  if (sentReset) {
    return (
      <Layout name="splash" pageTitle="Forgot My Password">
        <Heading variant="splash">Check Your E-Mail</Heading>
        <p>
          We've sent an email to <strong>{sentReset.email}</strong> containing a temporary
          link that will allow you to reset your password. Please check your
          spam folder if you don't receive the email within a few minutes.
        </p>
        <p>
          The link is <strong>valid for the next {sentReset.expiration} hours.</strong>
        </p>
        <p>
          You may safely close this browser window or return to <Link to="/login">Log In</Link>
        </p>
      </Layout>
    );
  }

  return (
    <Layout name="splash" pageTitle="Forgot My Password">
      <Heading variant="splash">Forgot your password?</Heading>
      <p>
        No problem. Just give us your username and we'll send a link to reset
        your password.
      </p>
      <HmForm value={{ username: '' }} onSubmit={handleSubmit} access={true}>
        <FormField
          name="username"
          label="Username"
          required
          data-value-missing="Username is required!"
          labelSrOnly
          autoFocus
        />
        <HmSubmit btnText="Reset Password" isWorking={isLoading}>
          <Link to="/login">Log In</Link>
        </HmSubmit>
      </HmForm>
    </Layout>
  );
};
