import config from 'config';
import qs from 'qs';


export declare type HmUrlDef = keyof typeof HmUrl | undefined;

export const HmUrl = {
  about: '/about',
  acceptTerms: '/terms',
  applicants: '/applications/applicants',
  application: (instanceId, applicantId) => `/applications/${instanceId}/${applicantId}`,
  applicationEdit: ({instanceId, returnUrl, guid }) => `${config.api.endpoint.replace('/api/v1', '')}/application/edit/${instanceId}/${guid}?${qs.stringify({returnUrl})}`,
  applicationHistory: ({instanceId, code, name, applicationId, waitingListId}) => waitingListId ?  `/applications/${instanceId}/${code}/${encodeURIComponent(name)}/${waitingListId}/${applicationId}/history` : `${HmUrl.application(instanceId, applicationId)}/history`,
  applicationNotifications: ({instanceId, code, name, applicationId, waitingListId}) => waitingListId ? `/applications/${instanceId}/${code}/${encodeURIComponent(name)}/${waitingListId}/${applicationId}/notifications` : `${HmUrl.application(instanceId, applicationId)}/notifications`,
  applicationPrint: ({instanceId, applicationId: applicationIds}) => `${config.api.endpoint}/applications/print/${instanceId}?${qs.stringify({applicationIds}, { arrayFormat: 'repeat' })}`,
  applicationsListing: ({instanceId, code, name}) => `/applications/${instanceId}/${encodeURIComponent(code)}/${encodeURIComponent(name)}`,
  applications: '/applications',
  applicationSettings: (wildcard, page?: 'modules' | 'workflow' | 'renewals') =>
    `${HmUrl.instanceSettings(wildcard, 'applications')}${page ? '/' + page : ''}`,
  applicationDetail: (instanceId, code, name, appWaitingListId, applicationId) =>
  encodeURI(`/applications/${instanceId}/${encodeURIComponent(code)}/${encodeURIComponent(name)}/${appWaitingListId}/${applicationId}`),
  applicationMember: (applicationWaitingListId, applicationId, memberId) =>
    encodeURI(`/applications/${applicationWaitingListId}/${applicationId}/${memberId}`),
  auditing: '/auditing',
  batches: wildcard => `/residents/${wildcard}/batchesReport`,
  batchDetail: batchReference => `batchesReport/${batchReference}`,
  billing: (page: 'ledger' | 'reports' | 'packages' | null = 'packages') =>
    `/billing${page ? '/' + page : ''}`,
  billingSettings: (wildcard, page?: 'pay' | 'autopay' | 'payment' | 'invoice') =>
    `${HmUrl.instanceSettings(wildcard, 'billing')}${page ? '/' + page : ''}`,
  billingInvoice: (wildcard, invoiceId) =>
    `${HmUrl.billingSettings(wildcard, 'invoice')}/${invoiceId}`,
  chooseAccount: '/login/chooseaccount',
  customers: '/customers',
  droppedApplicants: '/applications/droppedapps',
  enterpriseKeys: '/enterprisekeys',
  expiredPassword: '/expired',
  forgotPassword: '/forgot',
  home: '/',
  instanceSettings: (
    wildcard: string,
    page?:
      | 'billing'
      | 'subscriptionplan'
      | 'applications'
      | 'residents'
      | 'companies'
      | 'divisions'
      | 'workorders'
      | 'integrations'
      | 'renewals'
  ) => `${HmUrl.settings}/${wildcard}${page ? '/' + page : ''}`,
  login: '/login',
  logout: '/logout',
  myProfile: '/myaccount',
  notifications: '/notifications',
  notFound: '/notFound',
  passwordReset: (src: 'resetpassword' | 'new', username = ':username', token = ':token') =>
    `/account/${src}/${username}/${token}`,
  portal: '/portal',
  properties: '/applications/properties',
  propertyConfiguration: (instanceId, code) => `/applications/${instanceId}/${code}/edit`,
  remittance: '/remittance',
  reports: (instanceId, code, name) => `/applications/${instanceId}/${code}/${name}/reports`,
  reportsLocked: (instanceId, code, name) =>
    `/applications/${instanceId}/${code}/${name}/reports-locked`,
  residents: (wildcard = '') => `/residents/${wildcard}`,
  resident: (wildcard, page: 'csvimport' | 'payments' | 'batchesreport' | 'emailandtext' | 'messageboard') => `${HmUrl.residents(wildcard)}/${page}`,
  residentProfile: (wildcard, accountNumber, page = 'profile') =>
    `${HmUrl.residents(wildcard)}/detail/${accountNumber}/${page}`,
  residentSettings: (wildcard, page?: 'rentcollection' | 'settings') =>
    `${HmUrl.instanceSettings(wildcard, 'residents')}${page ? '/' + page : ''}`,
  renewalSettings: (wildcard: string) => `${HmUrl.applicationSettings(wildcard, 'renewals')}`,
  security: '/settings/security',
  settings: '/settings',
  tourDashboard: '/tour',
  units: (wildcard = '') => `/units/${wildcard}`,
  unit: (wildcard, page: 'csvimport') => `${HmUrl.units(wildcard)}/${page}`,
  users: '/settings/users',
  userAdd: '/settings/users/add',
  userDetail: id => `/settings/users/edit/${id}`,
  workOrders: (wildcard = '') => `/workorders/${wildcard}`,
  workOrderDetail: (wildcard, workOrderGuid) =>
    `${HmUrl.workOrders(wildcard)}/edit/${workOrderGuid}`,
  workTypes: wildcard => `${HmUrl.workOrders(wildcard)}/worktypes`,
  workCodes: wildcard => `${HmUrl.workOrders(wildcard)}/workcodes`,
  workers: wildcard => `${HmUrl.workOrders(wildcard)}/workers`,
  vendors: wildcard => `${HmUrl.workOrders(wildcard)}/vendors`,
  vendorDetail: (wildcard, id) => `${HmUrl.workOrders(wildcard)}/vendors/${id}`,
  verifyDevice: '/verify',
  inventory: wildcard => `${HmUrl.workOrders(wildcard)}/inventory`,
  inventoryCsv: (wildcard, page: 'csvimport') => `${HmUrl.workOrders(wildcard)}/${page}`,
};

export default HmUrl;
