import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Heading,
  Layout,
  HmDetail,
  Text,
  Button,
  Row,
  Col,
  SwitchView,
  ButtonToolbar,
  HmAlert
} from 'components';
import { HmDetailForm, FormField, HmButton, InfoLabel } from 'components/forms';
import { lookupResidentPortal, PortalInfo } from 'api/v1/registration/residentPortal';
import { Nav } from 'react-bootstrap';
import config from 'config';
import { useFormState } from 'hooks/useFormState';
import qs from 'qs';

const getResidentPortal = async ({ values: request, context, actions }) => {
  const result = await lookupResidentPortal(request);

  context.refresh = false;
  context.keepData = false;

  if (result.length === 0) actions.setView('notfound', {request});
  else actions.setView('found', {portals: result, request});

  return false;
};

export const ResidentPortal = () => {
  return (
    <Layout name="splash" pageTitle="Welcome to HousingManager.com" hideFooter>
      <Heading variant="splash">Welcome to HousingManager.com</Heading>
      <SwitchView viewId="search" track>
        <HmDetail key="search" onSave={getResidentPortal}>
          <HmDetailForm access={true}>
            <Text>To get started, we need some information about you.</Text>
            <Row>
              <Col md={{ order: 1 }}>
                <Text help>Enter first and last name as given to the facility management.</Text>
              </Col>
              <Col>
                <FormField name="firstName" label="First Name" maxLength={50} required />
                <FormField name="lastName" label="Last Name" maxLength={50} required />
              </Col>
            </Row>
            <Row>
              <Col sm={4}>
                <FormField
                  name="amount"
                  label={<InfoLabel tooltip="The amount you pay for Rent, Lease, HOA fees, etc." >Payment Amount</InfoLabel>}
                  fieldType="currency"
                  placeholder="ex 500.00"
                  required
                />
              </Col>
              <Col>
                <Identifier />
              </Col>
            </Row>

            <ButtonToolbar className="main">
              <HmButton type="submit">Next</HmButton>
            </ButtonToolbar>

          </HmDetailForm>
        </HmDetail>

        <NotFound key="notfound" />
        <Found key="found"  />

      </SwitchView>
    </Layout>
  );
};

const Found: FunctionComponent<{portals?: PortalInfo[], request?: any, actions?: any}> = ({portals = [], actions, request: {firstName, lastName, accountNumber, ...request}}) => {
  return (
    <Text container>
      <HmAlert variant="warning" show={portals.length > 1}>
        <Text>
          We found multiple matches. Choose from the options below:
        </Text>
      </HmAlert>
      <HmAlert variant="danger" show={portals.length === 1 && !portals[0].portalAvailable}>
        <Text>
          We found your account, but Resident Portal is not available at this time. Please contact your facility management.
        </Text>
      </HmAlert>

      <Nav className="flex-column">
        {portals.filter(p => p.portalAvailable).map((p,idx) => {
          const info = portals.length > 1 ? '' : '?'+qs.stringify({ lname: lastName, email: p.email, phone: p.phone, accountNumber });
          return (<Nav.Item key={idx}>
            <Nav.Link href={config.app.getPublicUrl(p.wildcard, p.hasLogin ? 'resident/login?username' + p.username : 'resident/register' + info)}>
              {p.hasLogin ? 'Login at ' : 'Register for '}
              {p.companyName} {p.portalTitle} Portal
            </Nav.Link>
          </Nav.Item>);
        })}
      </Nav>

      <ButtonToolbar className="main">
        <Button variant="link" size="sm" onClick={() => actions.setView('search', {initialValue: request})}>
          Search Again?
        </Button>
      </ButtonToolbar>
    </Text>
  )
}

const NotFound: FunctionComponent<any> = ({actions, request: {firstName, lastName, amount, ...request}}) =>{

  return (
    <Text variant="warning">
      We could not locate you. Check the spelling of your first and last name and ensure the payment amount entered is correct.{' '}
      {!!request.last4Ssn && <Text inline>Try searching without the last 4 digits of your SSN.</Text>}
      {!!request.accountNumber && <Text inline>Verify the account number you provided is up to date or try searching without it.</Text>}

      <Button variant="link" size="sm" onClick={() => actions.setView('search', {initialValue: {firstName, lastName, amount}})}>
        Search Again?
      </Button>
    </Text>
  )
}

const Identifier: FunctionComponent<any> = () => {
  const {api} = useFormState();
  const [view, setView] = useState('ssn');

  useEffect(() => () => api.clearValue(view), [view]);

  const views = {
    accountNumber: {
      next: 'ssn',
      title: 'I know my Account #'
    },
    ssn: {
      next: undefined,
      title: 'Try the last 4 digits of my SSN'
    },
    alienId: {
      next: 'unknown',
      title: 'I gave facilty management another ID'
    },
    unknown: {
      next: 'accountNumber',
      title: "I don't know..."
    }
  };
  const showing = views[view];

  return (
    <>
      <SwitchView viewId={view} className="mb-0" optional>
        <FormField name="accountNumber" key="accountNumber" label="Account Number" />
        <FormField name="last4Ssn" key="ssn" label="Last 4 of your SSN" maxLength={4} inputClassName="input-sm-num" />
        <FormField name="alienId" key="alienId" label="Alien Id" />
        <Text key="unknown">
          Don't know your Account Number? No problem, we'll use the information provided to try and find you.
        </Text>
      </SwitchView>
      {showing.next !== undefined && <Button variant="link" size="sm" onClick={() => setView(showing.next)}>
        {views[showing.next].title}
      </Button>}
    </>
  );
};
