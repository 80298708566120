import React, { FunctionComponent, Children } from 'react';
import { Text } from 'components';
import { formatPhone } from 'lib.core/formatters';
import { IconPhone } from './icons';
import { ContactProps } from './HmAddress';

export const HmPhone: FunctionComponent<
  Pick<ContactProps, 'phone'> & {
    link?: boolean;
  }
> = ({ phone, link = false, children }) => {
  const textProps: any = {
    as: link ? 'a' : 'span'
  };
  if (link) {
    textProps.href = `tel:${formatPhone(phone, true)}`;
  }
  return (
    <Text {...textProps}>
      <IconPhone /> {children} {formatPhone(phone)}
    </Text>
  );
};
