import React from 'react';
import { hasError } from '../hasError';

export function selectInitializer({ value, setter, setError, props: { ref = undefined, onSelect, options = [] as any[], placeholder, addPlaceholder = true, placheholderValue = null, transform, ...fieldProps } }) {
  const data = options;
  let indexMod = 0;
  if (transform) {
    options = options.map(transform);
  }
  if (addPlaceholder && placeholder) {
    indexMod = 1;
    options = [{ label: placeholder, value: placheholderValue, empty: true }, ...options];
  }

  const idx = options.findIndex(v => v.value === value || v === value);
  return {
    ...fieldProps,
    value: idx,
    onChange: e => {
      const idx = +e.target.value;
      const v = options[idx];
      onSelect && onSelect(v, data[indexMod + idx]);
      if (v && v.value !== undefined)
        setter(v.value);
      else
        setter(v);
    },
    children: options.map((o, idx) => <option key={idx} value={o.empty === true ? '': idx}>{o.label || o.name || o}</option>),
    onBlur: e => {
      if (e.target.willValidate) {
        e.target.checkValidity();
      }
      setError(hasError(e.target));
    },
    dependencies: [options]
  };
}
