import { useContext } from 'react';
import { HmContext } from 'HmContext';

export function usePortalInfo(pluralize = true) {
  var { settings = {} }: any = useContext(HmContext);
  const title = `${settings.portalTitle || 'Resident'}${pluralize ? 's': ''}`;

  return {
    title,
    contract: settings.contract || 'Monthly Rent',
    contractType: settings.contractType || 'Rent'
  };
}
