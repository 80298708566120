export const currentLocation = () => window.location;

export const changeUrl = newUrl => {
  if (window.history) window.history.pushState({}, '', newUrl);
  else window.location.href = newUrl;
};

export const currentUrl = () => {
  let location = currentLocation();
  return location.origin + location.pathname;
};
