import { authHttp } from '../..';

export const residentHttp = authHttp.url('/residents');
export { residentResourceHttp, ResidentResource } from './ResidentResource';


export interface Resident {
  residentId:                 number;
  instanceId:                 number;
  accountNumber:              string;
  middleName:                 string;
  lastName:                   string;
  firstName:                  string;
  name:                       string;
  userName:                   string;
  email:                      string;
  phone:                      string;
}

export interface ResidentDetail extends Resident {
  canMoveOut:                 boolean;
  canMoveIn:                  boolean;
  isAlienId:                  boolean;
  canEditPersonIdentifier:    boolean;
  personUniqueId:             string;
  maskedPersonUniqueId:       string;
  maskedDateOfBirth:          string;
  name:                       string;
  rentPerMonth:               number;
  balance:                    number;
  invitationNotificationDate: string;
  registeredDate:             string;
  personUniqueIdentifier:     PersonUniqueIdentifier;
  birthDate:                  string;
  paymentsDisabled:           boolean;
  address:                    Address;
  email:                      string;
  phone:                      string;
  physicalUnitNumber:         string;
  physicalAddressLine1:       string;
  physicalAddressLine2:       string;
  physicalCity:               string;
  physicalState:              string;
  physicalZip:                string;
  physicalCounty:             string;
  mailingUnitNumber:          string;
  mailingAddressLine1:        string;
  mailingAddressLine2:        string;
  mailingCity:                string;
  mailingState:               string;
  mailingZip:                 string;
  mailingCounty:              string;
  moveOutDate:                Date;
  moveOutDateString:          string;
  moveOutCreationDate:        Date;
  unitId:                     number;
  profileEmail:               string;
  profilePhone:               string;
  fullyQualifiedUnit:         string;
}

interface Address {
  addressLine1: string;
  city:         string;
  state:        string;
  zip:          string;
}

interface PersonUniqueIdentifier {
  mask: string;
}
