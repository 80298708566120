import { hasError } from '../hasError';
import { isEquivalent } from 'lib.core';

export function checkListInitializer({ value, setter, setError, props: { value: checkValue, onChange } = { value: undefined, onChange: undefined as any } }) {
  value = Array.isArray(value) ? value : [];
  return {
    value: checkValue,
    checked: Array.isArray(value) && value.find(v => isEquivalent(v, checkValue)) !== undefined,
    onChange: e => {
      const stage: any = {};
      if (e.target.checked) {
        stage.value = [...value, checkValue];
      }
      else {
        stage.value = value.filter(v => !isEquivalent(v, checkValue));
      }
      onChange && onChange(e, stage);
      setter(stage.value);
    },
    onBlur: e => {
      if (e.target.willValidate)
        e.target.checkValidity();
      setError(hasError(e.target));
    }
  };
}
