import { createValue, cloneAndMerge as merge, removeValue, getValue } from './utils';
import { clone, isEquivalent } from '../../lib.core';
import { FormState } from './FormContext';

function formReducer(state, action) {
  switch (action.type) {
    case addError.actionType:
      return addError(state, action.errors, action.clear || false);

    case addFieldError.actionType:
      return addFieldError(state, action.path, action.error);

    case setValues.actionType:
      return setValues(state, {value: action.value, touched: action.touched});

    case mergeValues.actionType:
      return mergeValues(state, action.value);

    case setPathValue.actionType:
      return setPathValue(state, action.path, action.value, action.replace);

    case setSubmitting.actionType:
      return setSubmitting(state, action.value);

    case reset.actionType:
      return reset(state);

    case clear.actionType:
      return clear(state, action.path, action.touched);

    case setPristine.actionType:
      return setPristine(state, action.pristine);

    default:
      return state;
  }
}

function cleanError(error) {
  if (Array.isArray(error)) {
    error = error.join(', ');
  }
  if (typeof error === 'string' && error.length === 0) {
    error = 'Invalid!';
  }
  return error;

}
function cleanErrors(errors) {
  const newError = Object.keys(errors).reduce((err, key) => {
    const error = cleanError(errors[key]);
    const ekey = key.replace(/\.([A-Z])/, m => m.toLowerCase());
    if (error) err[ekey] = error;
    return err;
  }, {});
  return newError;
}

function addError(state, errors, clear = false) {
  errors = cleanErrors(errors);
  const newError = clean(clear === true ? errors : { ...state.errors, ...errors });

  return {
    ...state,
    errors: newError,
    valid: Object.keys(newError).length === 0,
    validated: true
  };
}
addError.actionType = 'ADD_ERROR';
addError.send = (payload, clear = false) => {
  return {
    type: addError.actionType,
    errors: payload,
    clear
  };
};

function addFieldError(state, path, error) {
  if (error === false && !state.errors[path]) {
    return state;
  }
  error = cleanError(error);
  const newState = clone(state);
  newState.errors[path] = error;
  createValue(path, true, newState.touched);
  newState.errors = clean(newState.errors);
  newState.valid = Object.keys(newState.errors).length === 0;
  newState.pristine = state.pristine && newState.valid;

  return newState;
}
addFieldError.actionType = 'ADD_FIELD_ERROR';
addFieldError.send = ({ path, error }) => {
  if (Array.isArray(error)) {
    error = error.join(', ');
  }
  return {
    type: addFieldError.actionType,
    path,
    error
  };
};

function setPathValue(state: FormState<any>, path: string, value: any, replace: boolean) {
  const newState = clone(state);
  createValue(path, true, newState.touched);
  if (replace || value === undefined) removeValue(newState.value, path);
  if (value !== undefined) createValue(path, value, newState.value);
  newState.pristine = newState.pristine && value === getValue(state.value, path);
  return newState;
}

setPathValue.actionType = 'SET_PATH_VALUE';
setPathValue.send = ({ path, value, replace = false }) => {
  return {
    type: setPathValue.actionType,
    path,
    value,
    replace
  };
};

function reset(state) {
  return state.reset();
}
reset.actionType = 'RESET';
reset.send = () => {
  return {
    type: reset.actionType
  };
};

function setSubmitting(state, value) {
  const newState = {
    ...state,
    submitCount: value ? state.submitCount + 1 : state.submitCount,
    submitting: value === true,
    validated: true,
    pristine: value === false,
  };
  return newState;
}
setSubmitting.actionType = 'SET_SUBMITTING';
setSubmitting.send = (value: boolean) => {
  return {
    type: setSubmitting.actionType,
    value
  };
};

function setValues(state, {value, touched = []}) {
  const newState =  {
    ...state,
    value: clone(value),
    pristine: touched.length === 0
    // validated: false,
  };
  touched.forEach(p => createValue(p, true, newState.touched));
  return newState;
}
setValues.actionType = 'SET_VALUES';
setValues.send = (value: boolean, touched: string[] = []) => {
  return {
    type: setValues.actionType,
    value,
    touched
  };
};

function mergeValues(state, value) {
  return {
    ...state,
    value: {...state.value, ...value},
    pristine: false,
  };
}
mergeValues.actionType = 'MERGE_VALUES';
mergeValues.send = (value: boolean) => {
  return {
    type: mergeValues.actionType,
    value
  };
};

function clear(state, path, touched) {
  const newState = {...state};
  touched === false ? removeValue(newState.value, path) : createValue(path, touched, newState.value);
  //removeValue(newState.value, path);
  removeValue(newState.errors, path);
  removeValue(newState.touched, path);
  if (touched !== false) {
    newState.pristine = false;
  }
  return newState;
}
clear.actionType = 'CLEAR';
clear.send = (path: string, touched: any = false) => {
  return {
    type: clear.actionType,
    path,
    touched
  };
};

function setPristine(state, pristine) {
  if (state.pristine === pristine) return state;
  return {
    ...state,
    pristine: pristine,
  };
}
setPristine.actionType = 'SET_PRISTINE';
setPristine.send = (pristine: boolean) => {
  return {
    type: setPristine.actionType,
    pristine
  };
};


function clean(obj) {
  if (Array.isArray(obj)) {
    if (obj.length > 0 && typeof obj[0] === 'string') return obj.join(', ');
    return obj.map(clean).filter(e => !isEquivalent(e, {}));
  }
  return Object.keys(obj).reduce((newObj: any, key: string) => {
    if (obj[key] !== false) {
      newObj[key] = Array.isArray(obj[key]) ? clean(obj[key]) : obj[key];
    }

    if (newObj[key] === '' || (Array.isArray(newObj[key]) && newObj[key].length === 0)) {
      delete newObj[key];
    }
    return newObj;
  }, {});
}

export {
  formReducer,
  addError,
  reset,
  setPathValue,
  setSubmitting,
  clear,
  setValues,
  addFieldError,
  mergeValues,
  setPristine,
};
