import React, { useRef } from 'react';
import { Layout } from '../components/layouts/Layout';
import { Heading } from '../components/typography';
import { useUserState } from 'hooks/useUserState';
import { Redirect } from 'react-router';
import { HmUrl } from 'lib.app/routes';
import config from 'config';
import { Container, Button, Row, Col } from 'react-bootstrap';
import { acceptTerms } from 'api/v1/auth/terms';
import { IconPrint } from 'components/icons';

export const Terms = (props) => {


  return (
    <Layout name="hm" pageTitle="Terms of Service" className="splash-page terms-of-service">
      <Container className="welcome-box" fluid>
        <Row>
          <Col>
            <Heading variant="splash" className="text-primary">Terms of Service</Heading>
          </Col>
          <Col sm="auto">
            <Button variant="primary" onClick={printTerms}><IconPrint /> Print</Button>
          </Col>

        </Row>

        <iframe id="termsFrame" src="/terms.html" />

        <AcceptTerms />

      </Container>
    </Layout>
  );
};

const printTerms = () => {
  // TODO: analytics,
  const terms = document.getElementById('termsFrame') as HTMLIFrameElement;
  terms!.contentWindow!.print();
}

const AcceptTerms = () => {
  const [user, dispatch] = useUserState();
  const originalAction = useRef(user.action);

  if (user.action !== 'terms') {
    if (originalAction.current !== user.action) {
      return <Redirect to={HmUrl.chooseAccount} />
    }
    return null;
  }

  return (
    <>
      <hr className="mt-4 mb-4" />

      <Button variant="primary" size="lg" onClick={async e => {
        const result = await acceptTerms(user.userId);
        dispatch({...result, hasAcceptedTerms: true});
      }}>
        Accept Terms
      </Button>
    </>
  )
}
