import { Resource, withResource } from '../..';

export class ResidentResource extends Resource {
  public static baseUrl = '/residents';

  constructor(data) {
    super(data);
  }

  public save(wildcard ?: string) {
    const body = this.body;
    return this.hasUrl && body.isNew !== true
      ? this.http(this.url).put(body)
      : this.http(`${this.resourceUrl}/${wildcard}`).post(body);
  }
}

export const residentResourceHttp = withResource(ResidentResource).url(ResidentResource.baseUrl);

export default residentResourceHttp;
