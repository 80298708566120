import React, { FunctionComponent, Fragment } from 'react';
import { ui } from '../../lib.core';

export type Variant =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'danger'
  | 'warning'
  | 'info'
  | 'light'
  | 'dark'
  | 'white'
  | 'muted'
  | 'brand'
  | 'brand-alt';

type KeyOfT<T> = Extract<keyof T, string>;
type PropsOfT<T> = {[P in KeyOfT<T>]?: any};


export type TextProps<As extends React.ElementType<any> = any> = PropsOfT<As> & {
  variant?: Variant;
  bold?: boolean;
  strikeThrough ?: boolean;
  inline ?: boolean;
  container ?: boolean;
  small ?: boolean;
  help ?: boolean;
  as?: As;
  children: any;
  className?: any;

  /**
   * I18N message to use (not implemented), fallback to children
   */
  msgId?: string;
}

export const MutedText = props => {
  return (
    <Text variant="muted" inline {...props}>
      {props.children}
    </Text>
  );
};

export const Text: FunctionComponent<TextProps> = ({
  variant,
  inline = false,
  container = false,
  bold = false,
  small = false,
  help = false,
  strikeThrough = false,
  as: TextAs = (inline ? 'span' : (container ? 'div' : 'p')),
  className = '',
  children,
  ...props
}) => {
  const classNames = {
    'bg-dark': variant === 'light' || variant === 'white',
    tmyk: help,
    'text-strikethrough': strikeThrough,
    small
  };
  classNames[`text-${variant}`] = !!variant;
  const textProps = TextAs === Fragment ? {} : {className: ui.classNames(className, classNames) || undefined, ...props};
  return <TextAs {...textProps}>{bold ? <strong>{children}</strong> : children}</TextAs>;
};

export const TextCallout: FunctionComponent<TextProps> = ({variant, className, ...props}) => {
  const classList = ['callout', `callout-${variant}`];
  if (className) {
    classList.push(className);
  }

  return (
    <Text as="span" className={classList} {...props} />
  );
};
