import { withMock, authHttp, HmInstanceApiRequest, InstanceDetailRequest, handleNullResponse } from 'api';

const workordersHttp = authHttp.url('/workorders');

export const getWorkOrderSettings: HmInstanceApiRequest = ({wildcard}, options = {}) =>
  withMock(workordersHttp, options, () => import(/* webpackChunkName: "mockApi" */ './settings.mock.js'))
    .url(`/${wildcard}/settings`)
    .get()

export const saveWorkOrderSettings: HmInstanceApiRequest<InstanceDetailRequest<any>> = ({wildcard, values}, options = {}) =>
  withMock(workordersHttp, options, () => import(/* webpackChunkName: "mockApi" */ './settings.mock.js'))
    .url(`/${wildcard}/settings`)
    .put(values)
    .catch(handleNullResponse);
