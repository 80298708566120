
import LZString from 'lz-string';

export function isNullOrWhiteSpace(value) {
  return value === null || value === undefined || `${value}`.trim().length === 0;
}

export function mergeLocation(location, addLocation) {
  return {
    ...parseUriLocation(location),
    ...parseUriLocation(addLocation)
  }
}

export function parseUriLocation(uri) {
  if (typeof uri !== 'string') return uri;
  const [pathname, query = ''] = uri.split('?', 2);
  const [search, hash = ''] = query.split('#', 2);
  return {
    pathname,
    search,
    hash
  }
}

export function compress(value: string) {
  return LZString.compressToEncodedURIComponent(value);
}

export function decompress(value: string) {
  return LZString.decompressFromEncodedURIComponent(value);
}