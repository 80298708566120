import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { Redirect } from 'react-router';
import { useGuard, useGuardByProps } from 'hooks/useGuard';
import { HmGuardProps, HmAccessKey } from 'lib.security';
import matchers from 'lib.core/matching/matchers';
import { FormAccessContext } from 'components/forms/FormContext';
import { useQueryString } from 'hooks/useQueryString';
import { mergeLocation } from 'lib.core/utils';

export type HmAccessProps = {
  access: HmAccessKey | boolean;
};

type HmGuardComponentProps = HmGuardProps & Partial<HmAccessProps>;

export const HmGuard: FunctionComponent<HmGuardComponentProps> = ({
  children,
  access,
  ...props
}): ReactElement | null => {
  const [guarded, { component = null, to }] = useGuardByProps(props, access);
  const {returnUrl} = useQueryString({returnUrl: window.location.pathname})

  if (guarded) {
    if (to !== undefined) {
      return <Redirect to={mergeLocation(to, {search: `returnUrl=${returnUrl}`, state: {guarded, access}})}  />
    }
    return component as any;
  }

  if (!children) {
    return null;
  }

  return children as any;
};

export const HmGuardRedirect: FunctionComponent<HmAccessProps & { to: string }> = ({
  children,
  access,
  to
}) => {
  return (
    <HmGuard to={to} access={access}>
      {children}
    </HmGuard>
  );
};

export const HmElementGuard: FunctionComponent<HmAccessProps & { [x: string]: any }> = ({
  children,
  access,
  ...props
}) => {
  const guarded = useGuard(access);
  if (guarded === true)
    return React.Children.map(children, (c:any) => {
      // work around issue with react-select using isDisabled
      if (c && c.props && matchers.isDefined(c.props.isDisabled) && matchers.isDefined(props.disabled)) {
        props.isDisabled = props.disabled;
      }
      return React.cloneElement(c, { ...props });
    }) as any;

  return children;
};

export const HmAccessGuard: FunctionComponent<any> = ({ hide = false, as: As = hide ? HmGuard : HmElementGuard, children, ...props}) => {
  const { access } = useContext(FormAccessContext);
  return (
    <As access={access} {...props}>
      {children}
    </As>
  )
}

export const TrialCustomerOnly: FunctionComponent<any> = ({ children }) => {
  const guarded = useGuard('trialCustomer');
  if (guarded) return null;
  return children;
};
