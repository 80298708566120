import { get, set, unset } from 'lodash-es';
import { clone } from '../../lib.core';

export function removeValue(obj, path) {
  unset(obj, path);
}

export function createValue(path, value, obj = {}) {
  return set(obj, path, value);
}

export function getValue(obj, path, defaultValue: any = null) {
  return get(obj, path, defaultValue);
}

export function cloneAndMerge(src, target) {
  return clone(src, target);
}
