export enum Roles {
  CompanyAdmin = 'CompanyAdmin',
  CompanyUser = 'CompanyUser',
  CompanyUserReadonly = 'CompanyUserReadonly',
  SuperAdmin = 'SuperAdmin',
  LindseyEmployee = 'LindseyEmployee',
  AdminSales = 'AdminSales',
  AdminSupport = 'AdminSupport',
  Administrator = 'Administrator',
  Accounting = 'Accounting',
  IssueCredits = 'IssueCredits',
  CreatePayment = 'CreatePayment',
  ActivateGracePeriod = 'ActivateGracePeriod',
  Security = 'Security',
  ReportViewer = 'ReportViewer',
  Packages = 'Packages',
  Developer = 'Developer'
}
