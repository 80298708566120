import React, { useRef, FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';

export function useReturnTo(defaultTo, returnTo = false) {
  const location = useLocation();
  const returnUrl = useRef<string>('');
  let to = defaultTo;

  if (returnTo === true) {
    return typeof to === 'string' ? {
      pathname: to.split('?')[0],
      state: {
        returnUrl: location
      }
    } : {
      ...to,
      state: {
        ...(to.state || {}),
        returnUrl: location
      }
    };
  }

  const stateReturnUrl = location.state ? (location.state as any).returnUrl : null;
  if (!returnUrl.current && stateReturnUrl) {
    returnUrl.current = stateReturnUrl;
  }
  if (returnUrl.current) {
    to = returnUrl.current;
  }

  return to;
}

export const ReturnLink: FunctionComponent<any> = ({ to, children, returnTo = false, ...props }) => {
  to = useReturnTo(to, returnTo);


  return (
    <Link to={to} {...props}>
      {children}
    </Link>
  );
};
