import React, { useRef, useImperativeHandle, forwardRef, MutableRefObject, useEffect } from 'react';
import Recaptcha from 'react-google-recaptcha';
import config from '../../config';
import { useFieldApi } from 'hooks/useFormFieldState';
import { useFormState } from 'hooks/useFormState';

type HmRecaptchaProps = {
  size?: 'invisible' | 'normal';
  name: string;
  showAfter?: number;
  className?: any;
};

export const HmRecaptcha = forwardRef<Recaptcha, HmRecaptchaProps>(({ size, showAfter, name, ...props}, ref) => {
  const api = useFieldApi(name);
  const form = useFormState();
  const recaptchaRef = useRef() as MutableRefObject<any>;

  useImperativeHandle(ref, () => ({
    execute: () => recaptchaRef!.current!.execute(),
    getValue: () => recaptchaRef!.current!.getValue(),
    getWidgetId: () => recaptchaRef!.current!.getWidgetId(),
    reset: () => recaptchaRef!.current!.reset(),
  }));

  useEffect(() => {
    if (!recaptchaRef.current) return;
    const value = recaptchaRef.current.getValue();
    if (size === 'invisible' && !api.value) recaptchaRef.current.execute();
    else if (!value) recaptchaRef.current.reset();
  }, [size, api.value]);

  if (showAfter && showAfter > form.api.state.submitCount) {
    return null;
  }

  return (
    <Recaptcha
      ref={recaptchaRef}
      sitekey={config.recaptcha.siteKey}
      size={size}
      onChange={api.setValue}
      {...props}
    />
  );
});
