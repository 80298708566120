export const AnchorHrefNoOp: any = 'javascript: void(0);';

const createEventHandler = (callback, options: any = {}) => {
  const { preventDefault = false, stopPropagation = false } = options;

  return event => {
    if (preventDefault) event.preventDefault();
    if (stopPropagation) event.stopPropagation();
    callback(event);
  };
};

export const preventsAll = callback =>
  createEventHandler(callback, {
    preventDefault: true,
    stopPropagation: true,
  });

export const preventsDefault = callback =>
  createEventHandler(callback, { preventDefault: true });

export const noPropagation = callback =>
  createEventHandler(callback, { stopPropagation: true });

export const normal = callback => createEventHandler(callback);
