import React, { lazy, FunctionComponent, useEffect, useState } from 'react';
import { useAsync } from 'react-async';
import { useFieldApi } from 'hooks/useFormFieldState';
import { Text} from 'components';
import { ProgressBar } from 'react-bootstrap';
import { HmTooltip } from './InfoLabel';
import { useFormState } from 'hooks/useFormState';

const zxcvbn = () => import(/* webpackChunkName: "zxcvbn" */'zxcvbn').then(r => r.default);
type PrgoressVariants = 'danger' | 'warning' | 'success' | 'info';

type PasswordStrengthProps = {
  name: string;
  minLength?: number;
  scoreWords?: string[];
  scoreVariants ?: PrgoressVariants[];
  userInputs ?: string[];
}

enum PasswordScore {
  Risky = 0, // too guessable
  ThrottledOnline = 1, //very guessable: protection from throttled online attacks. (guesses < 10^6)
  UnThrottledOnline = 2, //somewhat guessable: protection from unthrottled online attacks. (guesses < 10^8)
  Offline = 3, // safely unguessable: moderate protection from offline slow-hash scenario. (guesses < 10^10)
  Safe = 4, // very unguessable: strong protection from offline slow-hash scenario. (guesses >= 10^10)
}

type PasswordStrengthResult = {
  score: PasswordScore,
  feedback: { warning: string, suggestions: string[] },
  sequence?: any,
  cacl_time?: any,
  isInitial ?: boolean;
}

const defaultScore: PasswordStrengthResult = { score: PasswordScore.Risky, feedback: { warning: 'too short', suggestions: ['Add more characters']}}

const usePasswordStrength = ({path, minLength, userInputs = [] as any[]}) => {
  const { data: passwordStrength , isLoading } = useAsync(zxcvbn);
  const field = useFieldApi(path);
  const {api} = useFormState();
  const [state, setState] = useState<PasswordStrengthResult>({...defaultScore, isInitial: true});

  useEffect(() => {
    if (!field.touched || isLoading) return;

    if ( field.value.length < minLength) {
      setState(defaultScore)
    } else {
      const res = passwordStrength(field.value, userInputs.map(n => api.getValue(n, n)));
      setState(res);
    }

  }, [passwordStrength, field.value, minLength]);

  return state;
}


const  variants: PrgoressVariants[] = ['danger', 'danger', 'warning', 'success', 'success'];
const  words = ['Poor:', 'Weak:', 'Okay:', 'Good.', 'Excellent!'];

export const PasswordStrengthAddOn: FunctionComponent<PasswordStrengthProps> = ({
  name,
  minLength = 8,
  scoreVariants = variants,
  scoreWords = words,
  userInputs = [],
}) => {
  const strength = usePasswordStrength({path: name, minLength, userInputs})
  return (
    <HmTooltip placement="auto" tooltip={<Text container><Text container bold>Password Strength:</Text> {scoreWords[strength.score]} {strength.feedback.warning} {strength.feedback.suggestions.join(' ')}</Text>}>
      <ProgressBar variant={scoreVariants[strength.score]} className="password-strength" now={strength.isInitial ? 0 : strength.score + 1} max={PasswordScore.Safe + 1} label={strength.isInitial ? '' : strength.feedback.warning } srOnly />
    </HmTooltip>
  )
}

export const PasswordSecurityTips: FunctionComponent<any> = ({className}) => {
  return (
    <Text container help className={className}>
      In order to provide a secure password, we recommend having a password that has the following
      criteria:
      <ul>
        <li>A length between 8 and 50 characters</li>
        <li>A lower case character</li>
        <li>An upper case character</li>
        <li>A special character</li>
        <li>A number</li>
      </ul>
    </Text>
  );
};