import React, { useEffect, useRef } from 'react';
import { Link, Redirect } from 'react-router-dom';
import getToken from 'api/oauth/token';
import { addPageError, HmAlert } from 'components/HmAlert';
import Layout from 'components/layouts';
import { useUserState } from 'hooks/useUserState';
import { HmUrl } from 'lib.app/routes';
import { HmForm, FormField, HmSubmit, HmRecaptcha, Checkbox, HmButton } from 'components/forms';
import { Heading, Text } from 'components';
import { logoutMyUser } from 'api/v1/auth/logout';
import { FormApi } from 'hooks/useFormState';
import { useQueryString } from 'hooks/useQueryString';
import { useLocation } from 'react-router';
import { HmPageLoading } from 'components/HmLoading';

export const Login = () => {
  const [user, setUser] = useUserState();
  const { returnUrl } = useQueryString({ returnUrl: undefined });
  const { state: {guarded = false} = {} }:any = useLocation();
  const loginSuccessfull = useRef(false);

  const loginSubmit = async ({values, state: {submitCount}, setValue}:FormApi) => {
    try {
      const result: any = await getToken(values);
      loginSuccessfull.current = true;
      result.returnUrl = returnUrl;
      setUser(result, true);
    } catch (e) {
      loginSuccessfull.current = false;
      setValue('password', '');
      addPageError((e && e.error_description) || 'Unable to login!', {
        showFor: 3000
      });
      if (e.error === 'invalid_captcha' || submitCount >= 2) {

        if (!user.requireRecaptcha) {
          user.username = values.username;
          user.requireRecaptcha = true;
          setUser(user, false);
        }
      }
    }
  };

  if (loginSuccessfull.current === true  || (user.isLoggedIn && user.action !== 'unauthorized' && guarded !== true)) {
    return <Redirect to={HmUrl.chooseAccount} />;
  }

  return (
    <Layout name="splash" pageTitle="Login">
      <Heading variant="splash">Log in to My.HousingManager.com</Heading>
      <HmAlert variant="danger" show={user.isLoggedIn === true && guarded}><Text>You are not authorized to view this content.</Text></HmAlert>

      <HmForm
        value={{
          username: user.username,
          rememberMe: !!user.username
        }}
        meta={{
          fields: {
            password: {
              validateOnBlur: false
            }
          }
        }}
        access
        onSubmit={loginSubmit}
      >

        <FormField name="username" label="Username" required data-value-missing="Username is required!" labelSrOnly />
        <FormField name="password" label="Password" required data-value-missing="Password is required!" fieldType="password" labelSrOnly componentProps={{useInput: true}} />

        {user.requireRecaptcha && <HmRecaptcha
          className="form-group"
          name="g-recaptcha-response"
          size="normal"
        />}

        <HmSubmit extrasClassName=""  button={<HmButton type="submit" block watch={['username', 'password']} size="lg">Log In</HmButton>}>
          <Checkbox name="rememberMe" className="remember-user" label="Remember My Username?" />
          <div className="forgot-password">
            <Link to={HmUrl.forgotPassword}>Forgot your password?</Link>
          </div>
        </HmSubmit>
      </HmForm>
    </Layout>
  );
};

export const Logout = () => {
  const [user, setUser] = useUserState();
  useEffect(() => {
    logoutMyUser().then(() => setUser(user, false, 'logout'));
  });

  if (user.isLoggedIn) return <HmPageLoading />

  return <Redirect to={HmUrl.login} />
}