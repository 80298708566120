import React, {
  FunctionComponent,
  useContext,
} from 'react';
import { HmForm } from '.';
import { HmDetailContext } from '../HmDetail';

export const HmDetailForm: FunctionComponent<any> = ({
  validator,
  children,
  transform= _ => _,
  ...props
}) => {
  const { state, save } = useContext(HmDetailContext);
  const myState = transform(state);
  return (
    <HmForm value={myState} onSubmit={save} validator={validator} {...props}>
      {children}
    </HmForm>
  );
};
