import React, { Fragment } from 'react';
import { useUserState } from 'hooks/useUserState';
import { Redirect, generatePath } from 'react-router';
import { HmUrl } from 'lib.app/routes';
import { Layout, Heading, HmGuard } from 'components';
import { HmAccess } from 'lib.security';
import { Container, CardDeck, Card } from 'react-bootstrap';
import { InstancePicker } from 'components/clients/InstancePicker';
import { useCurrentWildcard } from 'hooks/useInstanceState';
import { Link } from 'react-router-dom';
import { IconApplications, IconResidents, IconWorkOrders, IconSettings } from 'components/icons';
import { ResidentsLabel } from 'components/clients/ResidentsLabel';


export const Home = () => {
  const [user] = useUserState();

  if (user && user.userId && !user.hasAcceptedTerms) {
    return <Redirect to={HmUrl.acceptTerms} />;
  }

  return (
    <Layout name="hm" guard={HmAccess.authenticated}>
      <Container fluid className="welcome-header text-center text-primary main-content">
        <Heading variant="main">
          Welcome to My.HousingManager.com
          <InstancePicker active />
        </Heading>



        <CardDeck className="justify-content-center pr-3">
          {todo.map((t,idx) => (
            <Fragment key={idx}>
              <WelcomeCard {...t} />
            </Fragment>
          ))}
        </CardDeck>
      </Container>
    </Layout>
  );
};

const WelcomeCard = ({ guard, title, description, links }) => {
  return (
    <HmGuard {...guard} component={null}>
      <Card className="welcome-card shadow">
        <Card.Header as="h4">{title}</Card.Header>
        <Card.Body>
          <Card.Text as="div">{description}</Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Text as="ul" className="list-unstyled">
            {links.map((link, idx) => (
              <WelcomeLink key={idx} {...link} />
            ))}
          </Card.Text>
        </Card.Body>
        <Card.Footer></Card.Footer>
      </Card>
      <div className="break" />
    </HmGuard>
  );
};

const WelcomeLink = ({ title, to = 'test', guard = true }) => {
  const wildcard = useCurrentWildcard();
  return (
    <HmGuard access={guard} component={null}>
      <li>
        <Link to={generatePath(to, { wildcard })}>{title}</Link>
      </li>
    </HmGuard>
  );
};

const todo = [
  {
    title: (
      <>
        <IconApplications size="2x" /> Applications
      </>
    ),
    description: 'Manage applications, setup properties and waiting lists.',
    guard: HmAccess.applicationsAnyInstance,
    links: [
      {
        title: 'Properties',
        to: HmUrl.properties
      },
      {
        title: 'Applicants',
        to: HmUrl.applicants
      }
    ]
  },
  {
    title: (
      <>
        <IconResidents size="2x" /> <ResidentsLabel />
      </>
    ),
    description: <ResidentsLabel append="Manage" />,
    guard: HmAccess.residents,
    links: [
      {
        title: <ResidentsLabel />,
        to: HmUrl.residents(':wildcard')
      },
      {
        title: 'Units',
        to: HmUrl.units(':wildcard')
      },
      {
        title: 'Payments',
        to: HmUrl.resident(':wildcard', 'payments')
      },
      {
        title: 'Batches',
        to: HmUrl.resident(':wildcard', 'batchesreport'),
        guard: 'companyUserAdmin'
      },
      {
        title: 'Message Board',
        to: HmUrl.resident(':wildcard', 'messageboard'),
        guard: 'companyUserAdmin'
      },
      {
        title: 'Email and Text',
        to: HmUrl.resident(':wildcard', 'emailandtext'),
        guard: 'companyUserAdmin'
      }
    ]
  },
  {
    title: (
      <>
        <IconWorkOrders size="2x" /> Work Orders
      </>
    ),
    description: 'Manage online work orders and maintenance requests',
    guard: HmAccess.workOrders,
    links: [
      {
        title: 'Work Orders',
        to: HmUrl.workOrders(':wildcard')
      },
      {
        title: 'Units',
        to: HmUrl.units(':wildcard')
      },
      {
        title: 'Categories',
        to: HmUrl.workTypes(':wildcard')
      },
      {
        title: 'Work Codes',
        to: HmUrl.workCodes(':wildcard')
      },
      {
        title: 'Workers',
        to: HmUrl.workers(':wildcard')
      },
      {
        title: 'Vendors',
        to: HmUrl.vendors(':wildcard')
      },
      {
        title: 'Inventory',
        to: HmUrl.inventory(':wildcard')
      }
    ]
  },
  {
    title: (
      <>
        <IconSettings size="2x" /> Settings
      </>
    ),
    description:
      'Manage users, company settings, billing, application settings, resident settings.',
    guard: HmAccess.companyUserAdmin,
    links: [
      {
        title: 'Company Settings',
        to: HmUrl.instanceSettings(':wildcard')
      },
      {
        title: 'Billing',
        to: HmUrl.billingSettings(':wildcard')
      },
      {
        title: 'Companies',
        to: HmUrl.instanceSettings(':wildcard', 'companies')
      },
      {
        title: 'Divisions Configuration',
        to: HmUrl.instanceSettings(':wildcard', 'divisions')
      },
      {
        title: 'Applications',
        guard: 'applications',
        to: HmUrl.instanceSettings(':wildcard', 'applications')
      },
      {
        title: <ResidentsLabel />,
        guard: 'residents',
        to: HmUrl.instanceSettings(':wildcard', 'residents')
      },
      {
        title: 'Users',
        to: HmUrl.users
      },
      {
        title: 'Integrations',
        guard: 'residents',
        to: HmUrl.instanceSettings(':wildcard', 'integrations')
      }
    ]
  }
];
