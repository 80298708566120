import http, { withMock, setAccessToken, HmRequestOptions } from '../http';
import { getHttpConfig } from '..';
import { HmUser } from '../../HmContext';

const tokenHttp = http.url(getHttpConfig('endpoint').replace('api/v1', 'oauth/token'), true);

export const getToken = (
  details,
  type = 'password',
  options: HmRequestOptions = {}
): Promise<HmUser> =>
  withMock(tokenHttp, options, () =>
    import(/* webpackChunkName: "mockApi" */ './token.mock')
  )
    .formUrl({
      grant_type: type,
      ...details
    })
    .post()
    .then(res => {
      setAccessToken(res.access_token, res.expires_in);
      return Object.keys(res).reduce(
        (result, k) => {
          if (res[k] === 'True' || res[k] === 'False') {
            result[k] = res[k] === 'True';
          } else if (k === 'userId' || k === 'clientId') result[k] = +res[k];
          else if (k === 'userName') result.username = res[k];
          else result[k] = res[k];
          return result;
        },
        {} as any
      );
    });

export default getToken;
