import React, {  } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { HmBug } from '../HmBug';
import { ClientStatus, ClientDropDown } from '../clients';
import { hmAdmin, HmUrl } from '../../lib.app/routes';
import { HmNavLink, NavSearch, UserSettings, Help } from '.';
import { useFirstInstanceWithFeature } from 'hooks/useFirstInstanceWithFeature';
import { HmFeatures } from 'lib.app/enums';
import { HmGuard } from 'components';
import { ResidentsLabel } from 'components/clients/ResidentsLabel';

export const HmNav = ({search = undefined}) => {

  return (
    <Navbar id="hmNav-cont" bg="dark" variant="dark" expand="md" className="w-100">
      <Navbar.Brand as={Link} to="/">
        <HmBug />
        <ClientStatus className="client-status" />
        <ClientDropDown className="client-picker" />
      </Navbar.Brand>
      <HmGuard access="authenticated">
        <Navbar.Toggle aria-controls="hmNav" />
        <Navbar.Collapse id="hmNav">
          <NavSearch component={search} className="hm-search" />
          <Nav className="hm-admin">
            {hmAdmin.map(item => (
              <HmNavLink key={item.title} to={item.path} guard={item.guard}>
                {item.title}
              </HmNavLink>
            ))}
          </Nav>
          <Nav className="hm-client">
            <InstanceFeatureLink to={HmUrl.applications} feature={HmFeatures.Applications}>Applications</InstanceFeatureLink>
            <InstanceFeatureLink to={HmUrl.units} feature={HmFeatures.Units}>Units</InstanceFeatureLink>
            <InstanceFeatureLink to={HmUrl.residents} feature={HmFeatures.Residents}><ResidentsLabel /></InstanceFeatureLink>
            <InstanceFeatureLink to={HmUrl.workOrders} feature={HmFeatures.WordOrders}>Work Orders</InstanceFeatureLink>
          </Nav>

          <Nav className="hm-settings">
            <UserSettings />
            <Help />
          </Nav>
        </Navbar.Collapse>
      </HmGuard>
    </Navbar>
  );
};

const InstanceFeatureLink = ({feature, to, children}) => {
  const instance = useFirstInstanceWithFeature(feature);
  if (!instance || instance.accountStatusId === 0) return null;

  return (
    <HmNavLink to={resolvePath(instance.wildcard, to)}>
      {children}
    </HmNavLink>
  )
}

function resolvePath(wildcard, path) {
  if (typeof path === 'string') return path;
  return path(wildcard);
}
