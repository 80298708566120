import { useState, useEffect } from 'react';
import { clone } from 'lib.core/merge';
import { isEquivalent } from 'lib.core';

type InitializerFn<T = any> = (state: SharedState<T>) => T;
type CleanupFn = () => any;
type UpdaterFn<T> = (value: T) => void;

export type Updater<T> = (value: T | UpdaterFn<T>, replace?: boolean) => void;
export type Initializer<T> = T | InitializerFn<T>;

export interface SharedState<T = any> {
  name: string;
  setValue: Updater<T>;
  updaters: Set<any>;
  value?: T;
}

type SharedStateMap<T> = {
  [key: string]: SharedState<T>;
};

const stateMap: SharedStateMap<any> = {};

export function loadSessionStorageInitializer<T>(defaultValue: T) {
  let iniFn: InitializerFn<T> = state => {
    let sessionValue = sessionStorage.getItem(state.name);
    if (!sessionValue) return defaultValue;
    sessionStorage.removeItem(state.name);
    return JSON.parse(sessionValue) as T;
  };
  return iniFn;
}


export function createSharedState<T>(name: string, initialValue?: Initializer<T>): SharedState<T> {
  const state: SharedState<T> = getSharedState(name) || {
    name,
    setValue,
    updaters: new Set(),
    value: undefined
  };

  if (!state.value && initialValue) {

    const init = initialValue as any; //typescript to the fail!~
    state.value = typeof init === 'function' ? init(state) : init;
    // notify anything that signed up before, value was set
    state.updaters.forEach(fn => fn(state.value));
  }

  function setValue(pvalue, replace?: boolean) {
    let value = pvalue;
    if (typeof pvalue === 'function') {
      value = clone(state.value);
      pvalue(value);
    }
    if (replace === true || !isEquivalent(value, state.value)) {
      state.value = replace === true ? value : clone(state.value, value);
      state.updaters.forEach(fn => fn(state.value));
    }
  }

  return (stateMap[name] = state);
}

export function removeState(name: string) {
  delete stateMap[name];
}

export function getSharedState<T>(name: string): SharedState<T> | undefined {
  return stateMap[name];
}

export function useSharedState<T>(name: string, initialValue?: Initializer<T>, cleanupFn?: CleanupFn) : [T, Updater<T>] {
  // Get a function that can be called later
  // to re-render the calling component.
  const [, setState] = useState<T>();
  const [state] = useState<SharedState<T>>(() => {
    let state = getSharedState<T>(name);

    if (!state || !state.value) {
      state = createSharedState<T>(name, initialValue);
    }
    return state;
  });

  // remove on unmounting
  useEffect(() => {
    state.updaters.add(setState);
    return () => {
      state.updaters.delete(setState);
      if (state.updaters.size === 0 && cleanupFn) state.value = cleanupFn();
    };
  }, []);

  return [(state.value ? clone(state.value) : state.value) as T, state.setValue];
}
