import React, { FunctionComponent, useRef, useEffect } from 'react';
import { useFormState } from '../../hooks/useFormState';
import { HmAlert } from '..';
import { Text } from '../typography';
import { Button } from 'react-bootstrap';

export const ValidationSummary: FunctionComponent<any> = ({focusAlert = true, ...props}) => {
  const ref = useRef<HTMLUListElement>(null);
  const form = useFormState();
  const errors = form.api.errorSummary();
  useEffect(() => {
    console.log(ref.current, errors, errors.length);
    if (!focusAlert || errors.length === 0) return;
    let iv = setInterval(() => {
      if (ref.current === null) return;
      clearInterval(iv);
      ref.current.classList.add('scroll-target');
      ref.current.scrollIntoView({block: 'start', behavior: 'smooth'});
      ref.current.classList.remove('scroll-target');
    },50);
    //ref.current?.style.scrollMarginTop = '200px';
  },  [focusAlert, errors, errors.length]);

  return (
    <HmAlert variant="danger" reversed show={errors.length > 0} iconSize="2x"
      heading="Please fix the following problems:">
      <Text container>
        <ul ref={ref}>
          {errors.map((e, idx) => <li key={idx}>{e}</li>)}
        </ul>
        <Button size="sm" variant="danger" onClick={e => {e.preventDefault(); form.api.setError({}, true); }} >Clear Errors</Button>
      </Text>
    </HmAlert>
  );
};
