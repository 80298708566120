import React from 'react';
import { HmGuard, HmAlert, Text } from 'components';
import { IconLock } from 'components/icons';

export const ReadonlyDataAlert = () => {
  return (
    <HmGuard access="lindseyCustomers">
      <HmAlert icon={<IconLock />} variant="warning">
        <Text inline>Data is not managed by HousingManager.com</Text>
      </HmAlert>
    </HmGuard>
  );
};
