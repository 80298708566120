import arrays from 'lib.core/coreUtils/arrays';
import { eachPropertyPair } from 'lib.core/coreUtils/objects';

const combinations = {
  and(other) {
    let first = this;
    return extend((...args) => first.apply(first, args) && other.apply(other, args));
  },

  or(other) {
    let first = this;
    return extend((...args) => first.apply(first, args) || other.apply(other, args));
  }
};

const extend = (matcher) => {
  eachPropertyPair(combinations, (name, value) => {
    matcher[name] = value;
  });
  return matcher;
};

const condition = criteria => extend((...args) => criteria.apply(criteria, args));

let matchers = {
  addMatchers: (criteria) => {
    let self = this;
    eachPropertyPair(criteria, (name, value) => {
      self[name] = condition(value);
    });
  },

  condition: condition,

  equalTo: condition(value => matchers.equalToAnyValues(value)),

  isNull: condition(value => value === null),

  neverMatches: condition(() => false),

  isString: condition(value => {
    let valueDescription = typeof value;
    let result =
      valueDescription !== 'number' &&
      valueDescription !== 'object' &&
      valueDescription === 'string';

    return result;
  }),

  regex: pattern => {
    return matchers.condition((value) => {
      return pattern.test(value);
    });
  },

  isBoolean: condition(value => {
    let valueDescription = typeof value;
    let result =
      valueDescription !== 'number' &&
      valueDescription !== 'object' &&
      valueDescription !== 'string' &&
      valueDescription === 'boolean';

    return result;
  }),

  isDefined: condition(value => typeof value !== 'undefined'),

  isUndefined: condition(value => !matchers.isDefined(value)),

  anything: condition(() => true),

  equalToAnyValues: condition((...values) => {
    return matchers.condition(value => {
      return values.indexOf(value) > -1;
    });
  }),

  greaterThan: condition(value => {
    return matchers.condition(attributeValue => {
      return attributeValue > value;
    });
  }),

  isEmpty: condition(value => value.trim() === ''),

  lessThan: condition(value => {
    return matchers.condition(attributeValue => {
      return attributeValue < value;
    });
  }),

  anyElementMatches: condition(elementCriteria => {
    return matchers.condition(arrayToCheck => {
      return arrays.any(arrayToCheck, elementCriteria);
    });
  }),

  allElementsMatch: condition(elementCriteria => {
    return matchers.condition(arrayToCheck => {
      return arrays.trueForAll(arrayToCheck, elementCriteria);
    });
  }),

  between: condition((start, end) => {
    return matchers.condition(attributeValue => {
      return attributeValue >= start && attributeValue <= end;
    });
  }),

  eachDefinedMatcher(visitor) {
    eachPropertyPair(this, (name, value) => {
      if (matchers.isFunction(value) && name !== 'eachDefinedMatcher') visitor(name, value);
    });
  }
};

let x = matchers;

x.not = other => {
  return extend((...args) => {
    return !other.apply(null, args);
  });
};

x.isNullOrUndefined = x.isNull.or(x.isUndefined);

function createTypeMatcher(expectedType) {
  return condition(value => {
    return typeof value === expectedType;
  }).and(x.not(x.isNullOrUndefined));
}

x.isNumeric = createTypeMatcher('number');

x.isFunction = createTypeMatcher('function');

x.isObject = createTypeMatcher('object');

x.isInteger = x.isNumeric.and(value => value % 1 === 0);

x.isNullOrEmpty = x.isNull.or(x.isEmpty);

x.isTrue = x.equalTo(true);

x.isFalse = x.equalTo(false);

x.lessThanOrEqualTo = value => x.lessThan(value).or(x.equalTo(value));

x.greaterThanOrEqualTo = value => x.greaterThan(value).or(x.equalTo(value));

x.isArray = x.isObject.and(x.condition(value => Array.isArray(value)));


export default matchers;