
import Filter, { FilterOptions } from '../lib.core/filters';
import { useSharedState, Updater } from './useSharedState';
import { useContext } from 'react';
import { FilterContext, FilterContextState } from 'components/HmFilter';

export function useFilter<T = any>(name: string, initialValue ?: T, options ?: FilterOptions): [Filter<T>, Updater<Filter<T>> ] {
  const [filter, setFilter] = useSharedState<Filter<T>>(name, () => {
    const f = new Filter(initialValue as T, 0, options && options.top);
    if (options) {
      if (options.sort) {
        f.sort = options.sort;
      }
      if (options.persist) {
        const objStr = localStorage.getItem(`filter:${name}`);
        if (objStr) f.setPersistedValue(objStr);
      }
      if (options.query) {
        f.parseQsValue(options.query);
      }
    }
    return f;
  });

  return [filter as Filter<T>, setFilter ];
}

export function useFilterContext<T = any>() {
  return useContext<FilterContextState<T>>(FilterContext);
}
