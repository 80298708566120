import { useEffect, useRef, useCallback } from 'react';


export function useIfMounted(cb) {
  const mounted = useRef(true);
  useEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );
  return useCallback((...params) => mounted.current && cb(...params), [cb]);
}
