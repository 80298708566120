import React, { Dispatch, FunctionComponent, useReducer, useContext } from 'react';
import { FormApi } from '../../hooks/useFormState';
import { formReducer } from './reducers';
import { HmAccessKey, guard } from 'lib.security';
import { useGuard } from 'hooks/useGuard';
import { useTime } from 'hooks/useTime';
import { merge } from 'lib.core';

export interface FormState<T> {
  value: T;
  errors: any;
  touched: any;
  pristine: boolean;
  valid: boolean;
  meta: any;
  submitting: boolean;
  submitCount: number;
  validated: boolean;
  access: HmAccessKey | boolean;
  reset: () => FormState<T>;
}

export interface IFormContext<T = any> {
  state: FormState<T>;
  dispatch: Dispatch<any>;
  validator: (state: T) => any;
}

export interface IFormAccessContext {
  access: HmAccessKey  | boolean;
}

const formState: FormState<any> = {
  value: {},
  errors: {},
  touched: {},
  pristine: true,
  valid: true,
  meta: {},
  submitting: false,
  validated: false,
  submitCount: 0,
  access: 'companyUserOrGreater',
  reset: () => formState
};

export function initFormState<T>({value, meta}: {value: T, meta: any}): FormState<T> {

  return {
    ...formState,
    value,
    meta,
    reset: () => initFormState({value, meta}),
  } as FormState<T>;
}

export const FormContext = React.createContext({} as IFormContext<any>);
export const FormAccessContext = React.createContext({} as IFormAccessContext);

export interface FormContextProviderProps<T> {
  value: T;
  access?: HmAccessKey | boolean;
  onSubmit ?: (api: FormApi<T>) => Promise<void>;
  validator ?: (state: T, form?: FormState<T>) => any;
  meta ?: any;
}

export const FormGuard: FunctionComponent<IFormAccessContext> = ({
  access,
  children
}) => (<FormAccessContext.Provider value={{ access }}>{children}</FormAccessContext.Provider>)

export const ExtendFormGuard: FunctionComponent<IFormAccessContext> = ({access, children}) => {
  const { access: parentAccess } = useContext(FormAccessContext);
  const parentGuarded = useGuard(parentAccess);
  const guarded = useGuard(access);

  return <FormGuard access={!parentGuarded && !guarded}>{children}</FormGuard>

}

export const FormContextProvider: FunctionComponent<FormContextProviderProps<any>> = ({
  value: initialValue,
  meta = {},
  access = 'companyUserOrGreater',
  validator = _ => true,
  children,
}) => {
  const time = useTime();
  const [formValue, dispatch] = useReducer(
    formReducer,
    { value: initialValue, meta: merge(meta, { time }), access },
    initFormState
  );

  const value = {
    state: formValue,
    dispatch,
    validator
  };

  return (
    <FormContext.Provider value={value}><FormGuard access={access}>{children}</FormGuard></FormContext.Provider>
  );
};
