import React, { FunctionComponent } from 'react';
import { Heading, Text } from 'components/typography';
import Layout from 'components/layouts';
import { HmAlert } from 'components';
import { useUserState } from 'hooks/useUserState';
import { HmForm, FormField, HmSubmit } from 'components/forms';
import { HmPopover } from 'components/forms/InfoLabel';
import { HmUrl } from 'lib.app/routes';
import { Link, Redirect } from 'react-router-dom';
import { verifyDevice } from 'api/v1/auth/verify';

export const VerifyDevice : FunctionComponent<any> = (props) => {
  const [{userId, clientId, ipAddress, email, action}, dispatch] = useUserState();

  if (action !== 'verify') {
    return <Redirect to={HmUrl.chooseAccount} />
  }

  return (
    <Layout name="splash" pageTitle="Activation Required">

      <HmAlert variant="warning" reversed heading="Activatation Required" />

      <Text bold>A verification code has been sent to {email}</Text>
      <Text>
        Check your email and enter the code below to activate this device and finish logging in.{' '}
        <HmPopover title="Verification Help" trigger="click" popover={(
          <Text container>
            <Text>
              <Heading variant="inline">Can't find your code?</Heading>
              Check your email inbox and spam folder for an email from no-reply@HousingManager.com.
            </Text>
            <Text>
              <Heading variant="inline">Need a new code?</Heading>
              Try to <Link to={HmUrl.login}>log in</Link> again and we'll send you a new code.
            </Text>
          </Text>
        )}>
          <a href="javascript: void 0">Can't find your code?</a>
        </HmPopover>
      </Text>

      <hr />

      <HmForm
        access={true}
        value={{verificationCode: '', userId, clientId }}
        onSubmit={async ({values}) => {
          const verifiedUser = await verifyDevice(values);
          dispatch({...verifiedUser, hasVerifiedDevice: true}, true, 'init');
        }}>
        <FormField name="verificationCode" label="Enter your Verification Code" labelSrOnly fieldType="number" fieldSize="lg" />

        <HmSubmit btnText="Verify Code and Log In" />
      </HmForm>

      <hr />

      <Text variant="muted" className="small">
        <Heading variant="inline">Your current IP address: {ipAddress}</Heading>
        Want to skip this step in the future? Ask your administrator to add your IP address to their list of trusted devices.
      </Text>

    </Layout>
  );
};
