import React, { useState } from 'react';
import { PasswordStrengthAddOn, PasswordSecurityTips } from 'components/forms/PasswordStrengthAddOn';
import { useDeferedAsync } from 'hooks/useDeferedAsync';
import { useUserState } from 'hooks/useUserState';
import { FormSubmit } from 'hooks/useFormState';
import { addPageError } from 'components/HmAlert';
import { Redirect } from 'react-router';
import { HmUrl } from 'lib.app/routes';
import { Popover, OverlayTrigger } from 'react-bootstrap';
import { HmForm, FormField, HmSubmit } from 'components/forms';
import { IconQuestion } from 'components/icons';
import { Layout, Heading } from 'components';
import { HmAccess } from 'lib.security';
import { expiredPassword } from 'api/v1/auth/expired';
import { resetPassword } from 'api/v1/auth/reset';

const validateConfirmPassword = (values) => {
  return values.password !== values.passwordConfirm
    ? { passwordConfirm: 'Passwords do not match' }
    : (values.token === values.password ? {password:  'Password already used! Please enter a new password.' } : true);
};

export const ChangePassword = ({promiseFn, username, labelTxt, type= 'password', buttonTxt, token = '', labelSrOnly= false, login = false}) => {
  const { isLoading, run } = useDeferedAsync(promiseFn);
  const [ success, setSuccess ] = useState(false);
  const [user, dispatch] = useUserState();

  const handleSubmit: FormSubmit = async ({values, setError}) => {
    try {
      const result = await run(values) as any;
      if (result.username) {
        dispatch({...result, hasExpiredPassword: false, action: null});
      }
      else {
        setSuccess(true);
      }
    } catch (e) {
      if (e.token && e.token.length === 1 && e.token.join('').indexOf('link') >= 0) {
        addPageError(e.token, { heading: 'Link Expired'});
      } else if (e.token || e.password || e.passwordConfirm) {
        const flatErrs = Object.keys(e).reduce((err: any[], key) => err.concat(e[key]), []);
        addPageError(flatErrs);
        setError(e);
      } else {
        addPageError('Something went wrong!');
      }
    }
  };

  if (success || (user.isLoggedIn && user.action !== 'expired')) {
    return <Redirect to={HmUrl.home} />;
  }
  const autoFocusToken = !token && type !== 'hidden';

  const popover = (
    <Popover id="password-help" title="Password Requirements">
      Use at least 8 characters. Don't use a password from another site, or something too obvious like your pet's name.
    </Popover>
  );

  return (
    <>
      <PasswordSecurityTips className="mb-4" />
      <HmForm
        onSubmit={handleSubmit}
        validator={validateConfirmPassword}
        value={{
          username,
          token,
          password: '',
          passwordConfirm: '',
          login
        }}
        access={true}>
        <OverlayTrigger placement="right" overlay={popover}>
          <IconQuestion className="text-primary float-right" />
        </OverlayTrigger>
        <FormField
          autoFocus={autoFocusToken}
          name="token"
          label={labelTxt}
          type={type}
          labelSrOnly={labelSrOnly}
          required
        />
        <FormField
          autoFocus={!autoFocusToken}
          name="password"
          label="Password"
          type="password"
          required
          pattern=".{8,50}"
          title="Enter a password between 8 and 50 chararcters long"
        >
          <PasswordStrengthAddOn name="password" userInputs={['username', 'token']} />
        </FormField>
        <FormField
          name="passwordConfirm"
          label="Confirm Password"
          type="password"
          required
        />
        <HmSubmit btnText={buttonTxt} isWorking={isLoading} />
      </HmForm>
    </>
  );
};

export const ExpiredPassword = (props) => {
  const [user, ] = useUserState();

  if (user.action !== 'expired') {
    return <Redirect to={HmUrl.chooseAccount} />;
  }

  return (
    <Layout name="splash" pageTitle="Password Expired" guard={HmAccess.authenticated}>
      <Heading variant="splash">Password Expired</Heading>
      <ChangePassword
        promiseFn={expiredPassword}
        buttonTxt="Change Password"
        labelTxt="Old Password"
        username={user.username}
      />
    </Layout>
  );
};

export const ResetPassword = ({ match, ...props }) => {
  return (
    <Layout name="splash" pageTitle="Reset Your Password">
      <Heading variant="splash">Reset Your Password</Heading>
      <ChangePassword
        promiseFn={resetPassword}
        buttonTxt="Change Password"
        labelTxt="Reset Token"
        labelSrOnly
        type="hidden"
        token={match.params.token}
        username={match.params.username}
      />
    </Layout>
  );
};

export const NewPassword = ({ match, ...props }) => {
  return (
    <Layout name="splash" pageTitle="Set Your Password">
      <Heading variant="splash">Set Your Password</Heading>
      <ChangePassword
        promiseFn={resetPassword}
        buttonTxt="Submit"
        labelTxt="Reset Token"
        labelSrOnly
        type="hidden"
        login
        token={match.params.token}
        username={decodeURIComponent(match.params.username)}
      />
    </Layout>
  );
};
