import http, { withMock, HmApiRequest } from 'api';

const url = http.url('/applications/view');

type Request = { guid: string }
type Result = { applicationId: number; instanceId: number; }

export const viewApplication: HmApiRequest<Request, Result> = ({ guid }, options = {}) =>
  withMock(url, options, () => import(/* webpackChunkName: "mockApi" */ './get.mock.json'))
    .url(`/${guid}`)
    .get()
    .catch(err => false);
