import React, { FunctionComponent, ReactNode, Fragment } from 'react';
import { formats, DateFormats, formatDate } from '../lib.core/formatters';
import { TextProps, Text } from './typography/Text';
import { parseISO, parse } from 'date-fns/esm';
import { isValid } from 'date-fns';

type HmDateProps = {
  date?: string | number | Date;
  format ?: DateFormats;
  compareDate ?: Date;
  fallback ?: ReactNode;
  /** prepend children, instead of appending them */
  prepend ?: boolean;

  /**
   * Indicates the date is previously localized to the current client
   */
  localized?: boolean;
};

export const HmDate: FunctionComponent<Partial<TextProps> & HmDateProps> = ({date = new Date(), variant, prepend = false, compareDate, format= formats.ShortDate, fallback = null, localized= false, children, ...props}) => {
  const displayDate = date && (typeof date === 'string' && date.includes('-') ? parseISO(date) : new Date(date));
  const As = variant ? 'span' : Fragment;

  // todo localize to clients timezone.
  if (!displayDate || !isValid(displayDate)) {
    return fallback as any
  }


  return (
    <Text variant={variant} as={As} {...props}>
      {prepend && children}
      {formatDate(displayDate, compareDate || formats[format] || format)}
      {prepend ? null : children}
    </Text>
  );
};
