import React, { useEffect, Children, ReactNode } from 'react';
import { useFieldApi } from '../../hooks/useFormFieldState';
import { useFormState } from '../../hooks/useFormState';

export type ConditionalFieldProps = {
  path: string;
  condition ?: (api: any) => boolean;
  watch ?: string[];
  children: ReactNode;
  mode ?: 'show' | 'disable'
};

export const ConditionalField  = ({path, condition, watch = [], mode = 'show', children}: ConditionalFieldProps): any => {
  const api = useFieldApi(path);
  const form = useFormState();
  const show = condition ? condition({value: api.value, valid: api.valid, errors: api.errors, api: form.api}) : !!api.value;

  useEffect(() => {
    if (!show && watch && watch.length > 0) {
      watch.forEach(path => form.api.clearValue(path));
    }
  }, [show, api.value]);

  if (!show && mode === 'show') {
    return null;
  }

  return Children.only(React.cloneElement(children as any, {disabled: !show, watch: [show]}));
};
