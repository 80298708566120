import { default as env } from './env';
import { generatePath } from 'react-router';

const config = {
  api: {
    endpoint: `${process.env.REACT_APP_API_ENDPOINT}`.replace(/\/$/, ''),
    options: {
      credentials: 'include',
      mode: 'cors'
    },
    mockResponses: env.bool('REACT_APP_API_MOCK_RESPONSES'),
    devToken: `${process.env.REACT_APP_API_DEVELOPER_TOKEN}`,
    getUrl: (path, baseUrl = false) => `${baseUrl ? config.api.endpoint.replace('api/v1', '') : config.api.endpoint}/${path}`,
  },
  app: {
    timeout: env.number('REACT_APP_SESSION_TIMEOUT', 1200), // check for activity every 20 min, (api session timeout is currently 240min)
    versionTimeout: env.number('REACT_APP_VERSION_TIMEOUT', 600), // check for new version every 10 min
    myUrl: (`${process.env.REACT_APP_MY_URL}` || `${process.env.REACT_APP_API_ENDPOINT}`).replace(/\/$/, '').replace('api/v1', ''),
    publicUrl: (`${process.env.REACT_APP_PUBLIC_URL}` || 'https://:wildcard.housingmanager.com').replace(/\/$/, ''),
    isDev: process.env.NODE_ENV === 'development',
    mode: `${process.env.REACT_APP_MODE || process.env.NODE_ENV}`,
    initialized: false,
    address: {
      addressLine1: '500 President Clinton Ave., Suite 401',
      city: 'Little Rock',
      state: 'AR',
      zip: '72201'
    },
    getUrl: path => `${config.app.myUrl}/${path}`,
    getPublicUrl: (wildcard, path = '') => `${generatePath(config.app.publicUrl, {wildcard})}/${path}`,
  },
  recaptcha: {
    siteKey: `${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`
  },
  sentry: {
    dsn: `${process.env.REACT_APP_SENTRY_DSN}`,
    enabled: !!process.env.REACT_APP_SENTRY_DSN
  },
  analytics: {
    segmentio: `${process.env.REACT_APP_SEGMENT_IO}`
  }
};

export default config;
