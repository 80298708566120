import { withMock, resourceHttp } from '../..';
import { HmRequestOptions } from '../../http';

export const searchClientsRequest = resourceHttp.url('/admin/searchclients');

export const searchClients = (
  searchText: string,
  options: HmRequestOptions = {}
) =>
  withMock(searchClientsRequest, options, () =>
    import(/* webpackChunkName: "mockApi" */ './searchClients.mock.js')
  )
    .query({ searchText })
    .get();
