import { useLayoutEffect, useEffect } from 'react';
import { activityDetected } from './useActivityTimeout';

export const pagePropDefaults = {
  pageTitle: 'Housing Manager',
  className: ''
};

let locked: boolean = false;

export const usePageTitle = pageTitle => {

  useEffect(() => {
    if (!pageTitle || locked === true) return;
    locked = true;

    analytics.page(pageTitle);
    activityDetected();

    const originalTitle = document.title;
    document.title = pageTitle;

    return () => {
      locked = false;
      document.title = originalTitle;
    };
  }, [pageTitle]);
};

export const useBodyClassName = classNames => {
  useLayoutEffect(() => {
    if (classNames) {
      document.body.classList.add(...classNames.split(' '));
      return () => {
        document.body.classList.remove(...classNames.split(' '));
      };
    }
  },[classNames]);
};
