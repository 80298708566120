import { authHttp, HmApiRequestGet as HmApiRequestEmpty, withMock, noContentMock, HmApiRequest, handleNullResponse } from "api";

const tourHttp = authHttp.url('/tour');

export type TourState = {
  cards: TourCard[];
  hasClientFinishedTour: boolean;
  currentStep: TourStep;
  nextStep: TourStep;
  lastCompletedStep: TourStep;
  daysRemainingInTrial: number;
}

export type TourCard = {
  name: string;
  steps: TourStep[];
  isLocked: boolean;
  isActive: boolean;
  isCompleted: boolean;
  duration: string;
  percentageComplete: number;
  nextStep: TourStep;
}

export type TourStep = {
  duration: string;
  name: string;
  isCompleted: boolean;
  tourStepId: number;
  isFinalStepOfCard: boolean;
  href: string;
}

export const getTour: HmApiRequestEmpty<TourState> = (options = {}) =>
  withMock(tourHttp, options, () => import(/* webpackChunkName: "mockApi" */ './tour.mock.js'))
    .get()
    .then(t => ({...t.tourState, daysRemainingInTrial: t.daysRemainingInTrial}))

export const completeTourStep: HmApiRequest<TourStep | number> = (step, options = noContentMock) =>
  {
    if (typeof step === 'number') {
      step = { tourStepId: step } as any;
    }
    return withMock(tourHttp, options, () => import(/* webpackChunkName: "mockApi" */ './tour.mock.js'))
      .url('/completestep').post(step)
      .catch(handleNullResponse);
  };

export const finishTour: HmApiRequestEmpty = (options = {}) =>
  withMock(tourHttp.url('/finish'), options, () => import(/* webpackChunkName: "mockApi" */ './tour.mock.js'))
    .post()
    .catch(handleNullResponse);

export default {
  state: getTour,
  completeStep: completeTourStep,
  finish: finishTour,
}