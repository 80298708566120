import Filter from './Filter';

export interface FilterOptions {
  top ?: number;
  skip ?: number;
  sort ?: string;
  query ?: string;
  persist ?: boolean;
}

export default Filter;
