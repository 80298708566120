import React, { FunctionComponent, Fragment } from 'react';
import { useFormFieldState, initialize } from '../../hooks/useFormFieldState';
import { Form, Container } from 'react-bootstrap';
import { ConditionalField, ConditionalFieldProps } from './ConditionalField';
import { useFormState } from 'hooks/useFormState';
import { HmElementGuard } from 'components/security/HmGuard';

export const Checkbox = ({ name, id = name, disabled = false, ...props }) => {
  const {api: {state}} = useFormState();
  const { props: field, api } = useFormFieldState(name, initialize.checkbox, props);
  return (
    <Form.Check id={id} custom {...props}>
      <HmElementGuard access={state.access} disabled><Form.Check.Input type={props.type} disabled={disabled} isInvalid={!api.valid} {...field} /></HmElementGuard>
      <Form.Check.Label>{props.label || props.children}</Form.Check.Label>
      <Form.Control.Feedback type="invalid">{api.errors}</Form.Control.Feedback>
    </Form.Check>
  );
};

export const CheckboxList: FunctionComponent<any> = ({ name, value, id = name + value, children, disabled, ...props }) => {
  const { props: field, api } = useFormFieldState(name, initialize.checkboxList, {value, ...props});
  const {api: {state}} = useFormState();

  return (
    <Form.Check id={id} {...props}>
      <HmElementGuard access={state.access} disabled><Form.Check.Input type={props.type} isInvalid={!api.valid} disabled={disabled} {...field} /></HmElementGuard>
      <Form.Check.Label>{children}</Form.Check.Label>
      <Form.Control.Feedback type="invalid">{api.errors}</Form.Control.Feedback>
    </Form.Check>
  );
};

type CheckboxToggleFieldProps = ConditionalFieldProps & {
  label: any;
  as?: any;
  [x: string]: any;
};

export const CheckboxToggleField: FunctionComponent<CheckboxToggleFieldProps> = ({
  as: Container = Fragment,
  path,
  label,
  condition: trigger,
  watch,
  children,
  mode,
  onChange,
  isChecked,
  ...props
}) => {
  const dependentFieldProps = { path, trigger, watch, mode, children };
  return (
    <Container {...props}>
      <Checkbox name={path} label={label} onChange={onChange} isChecked={isChecked} />
      <ConditionalField {...dependentFieldProps} />
    </Container>
  );
};
