import { useEffect } from 'react';
import config from 'config';

const versionInfo = { files: new Set<string>() };

const getVersionedFiles = () => {
  return fetch(config.app.getUrl('asset-manifest.json?t='+ new Date().getTime()))
    .then(r => r.json())
    .then(({files = {}})=> Object.keys(files).map(k => files[k]))
    .catch(() => {
      // fail silently
      return [];
    })
}

export function useManifestChanged(onManifestChanged, interval) {
  useEffect(() => {
      getVersionedFiles()
        .then(files => {
          versionInfo.files = new Set(files);
          return versionInfo;
        })
  },[]);

  useEffect(() => {
    const iv = setInterval(() => {
      getVersionedFiles()
        .then(files => {
          if (files.length !== versionInfo.files.size || files.filter(f => !versionInfo.files.has(f)).length > 0) {
            // the app has updated since we last reloaded, we need to refresh
            // maybe in the future prompt the user, but for now just do it!
            onManifestChanged();
          }
        })
    }, interval * 1000);
    return () => clearInterval(iv);
  }, [interval]);
}
