import React, { useState, useEffect } from 'react';
import { setHttpConfig, getHttpConfig } from '../../api';
import { IconCaretDown, IconCaretUp } from '../icons';
import config from '../../config';
import { useClientContext } from 'hooks/useClientContext';
import { useCurrentWildcard } from 'hooks/useInstanceState';

const commands = {
  setHttpConfig
};

export const CypressBridge = () => {
  const {client} = useClientContext();
  const wildcard = useCurrentWildcard();
  const [httpConfig, setState] = useState(() => getHttpConfig());
  const isDev = config.app.isDev;

  useEffect(() => { return; }, [config]);

  const handleChange = e => {
    const signal = JSON.parse(e.target.value);
    signal.forEach(c => {
      const com = commands[c.name];
      com(c.args[0], c.args[1]);
    });

    setState(getHttpConfig());
  };

  if (!isDev) {
    return null;
  }

  return (
    <div style={{ position: 'fixed', bottom: '0', background: 'rgba(255,255,255,.9)', padding: '5px' }}>
      <span><strong>Mocks {httpConfig.mocksEnabled ? 'Enabled' : 'Disabled'}:</strong> {httpConfig.mocksEnabled ? <IconCaretUp className="text-success" /> : <IconCaretDown className="text-danger" />} </span>
      <span><strong>Status: </strong> {httpConfig.mockDefault.status} </span>
      <span><strong>Text: </strong> {httpConfig.mockDefault.statusText} </span>
      [<span><strong>Client: </strong> {client.clientName} / {client.clientId} </span>
      <span><strong>Wildcard: </strong> {wildcard} </span>
      <span><strong>Mode: </strong> {config.app.mode} </span>
      ]

      <form style={{display: 'none'}}><input type="button" id="cypressBridge" onClick={handleChange} /></form>
    </div>
  );
};
