export const generateRange = (size, startAt = 0) => {
  return [...Array(size).keys()].map(i => i + startAt);
};

const eachUntil = (target, visitor) => {
  const items = [...target];

  for (let index = 0; index < items.length; index++) {
    const continueIteration = visitor(items[index], index, items);

    if (typeof continueIteration !== 'undefined' && continueIteration !== null) {
      if (continueIteration === false) {
        return;
      }
    }
  }
};

const eachInReverseUntil = (target, visitor) => {
  const items = [...target];

  for (let index = items.length - 1; index > 0; index--) {
    const continueIteration = visitor(items[index], index, items);

    if (typeof continueIteration !== 'undefined' && continueIteration !== null) {
      if (continueIteration === false) {
        return;
      }
    }
  }
};

const each = (target, visitor) =>
  eachUntil(target, (...args) => {
    visitor(...args);
  });

const trueForAll = (target, condition) => {
  let result = true;

  each(target, (value, index) => {
    result = result && condition(value, index);
  });

  return result;
};

const findFirstInDirection = (target, direction, condition) => {
  let result = null;

  direction.call(null, target, (...rest) => {
    const args = Array.prototype.map.call(rest, 0);

    const match = condition.apply(condition, args);

    if (match) result = args[0];

    return !match;
  });

  return result;
};

const first = (target, condition = undefined) => {
  if (target == null || target.length === 0) return null;

  if (!condition) return target[0];

  return findFirstInDirection(target, eachUntil, condition);
};

const last = (target, condition = undefined) => {
  if (target == null || target.length === 0) return null;

  if (!condition) return target[target.length - 1];

  return findFirstInDirection(target, eachInReverseUntil, condition);
};

const none = (target, condition) => !first(target, condition);

const any = (target, condition) => !none(target, condition);

export default {
  generateRange,
  eachUntil,
  eachInReverseUntil,
  each,
  trueForAll,
  first,
  last,
  findFirstInDirection,
  none,
  any
};
