import React from 'react';
import { Navbar } from 'react-bootstrap';
import { HmNavLink } from '.';
import { generatePath } from 'react-router';
import matchers from 'lib.core/matching/matchers';

export const SubNav = ({ root, items, sort = false, prepend, children, ...props }: any) => {
  const navitems = sort ? [...items].sort((a, b) => a.navOrder - b.navOrder) : items;

  return (
    <Navbar bg="brand-alt" variant="light" expand="md">
      {prepend}
      {navitems.map(({ title, component, visible = true, path, absolute = false, ...item }:any) => {
        const show = matchers.isFunction(visible) ? visible() : visible;

        return show === false ? null : (
          <HmNavLink to={resolvePath({ root, path, absolute, props })} key={path} {...item}>
            {title}
          </HmNavLink>
        );
      })}
      {children}
    </Navbar>
  );
};

function resolvePath({ root, path, absolute, props }) {
  const p = generatePath(path, { wildcard: '', ...props });
  return absolute ? p : root + p;
}

export default SubNav;
