import React, { FunctionComponent, forwardRef } from 'react';
import { InputGroup } from 'react-bootstrap';
import { default as Datepicker } from 'react-datepicker';
import { IconCalendar } from '../icons';
import { ui } from '../../lib.core';
import { FormField } from '.';
import { FormFieldProps } from './FormField';
import { IconDateTime, IconClock } from '../icons/fontawesome';
import { Placement } from 'react-bootstrap/Overlay';
import { DateFormats, getLocalDate, getZonedDate, applyJSONFormat } from 'lib.core/formatters';
import { hasError, FieldStateInitializer } from 'hooks/formUtils';

// https://github.com/Hacker0x01/react-datepicker/blob/master/docs/datepicker.md
// https://reactdatepicker.com/

interface DatePickerProps {
  [x: string]: any; // add more if you find more useful stiff
  autoComplete?: string;
  dateFormat?: string;
  disabledKeyboardNavigation?: boolean;
  dropdownMode?: 'scroll' | 'select';
  endDate?: Date | string | number;
  inputOnly?: boolean;
  isClearable?: boolean;

  /**
   * By default, date picker will send back a UTC date, many of our APIs, expect local dates,
   * specify an output format to override the default UTC date  ISO string.
   * ... someday it would be nice for the API to expect UTC.
   */
  outputFormat ?: DateFormats;

  maxDate?: Date;
  minDate?: Date;
  placeholderText?: string;
  popperPlacement?: Placement;
  scrollableYearDropdown?: boolean;
  selected?: Date | string | number; // date like
  selectsEnd?: boolean;
  selectsStart?: boolean;
  shouldCloseOnSelect?: boolean;
  showMonthDropdown?: boolean;
  showTimeInput?: any;
  showTimeSelect?: boolean;
  showTimeSelectOnly?: boolean;
  showYearDropdown?: boolean;
  startDate?: Date | string | number;
  timeCaption?: string;
  timeFormat?: string;
  timeInputLabel?: string;
  timeIntervals?: number;

  /**
   * Most dates will be converted to Central Time by the API, occassionally (Application Form Start/End Date...)
   * we need to specify the timeZone the date being input should be in so when it sends to the server, the date will
   * be ready for conversion to Central Time... someday it would be nice for the API to expect UTC.
   */
  timeZoneId?: number;
  todayButton?: any; // probably a string,
}

export type DatePickerComponentProps = FormFieldProps & DatePickerProps;

export const HmDatePicker = forwardRef(
  (
    {
      isInvalid,
      className,
      showTimeSelect,
      showTimeSelectOnly,
      showTimeInput,
      appendControl,
      appendIcon = false,
      inputOnly,
      placeholder: placeholderText,
      ...props
    }: any, ref) => {
    let icon = <IconCalendar />;
    if (showTimeSelectOnly) icon = <IconClock />;
    else if (showTimeSelect || showTimeInput) icon = <IconDateTime />;

    if (inputOnly === true) {
      return (
        <Datepicker
          ref={ref}
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeInput={showTimeInput}
          className="form-control"
          placeholderText={placeholderText}
          {...props}
        />
      );
    }

    return (
      <InputGroup
        as="label"
        className={ui.classNames(className, {
          'is-invalid': isInvalid,
          'input-group-joined': appendControl,
          showTimeInput
        })}>
        {(appendControl || appendIcon) && (
          <InputGroup.Prepend>
            <InputGroup.Text>{icon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <Datepicker
          showTimeSelect={showTimeSelect}
          showTimeSelectOnly={showTimeSelectOnly}
          showTimeInput={showTimeInput}
          className="form-control"
          placeholderText={placeholderText}
          {...props}
        />
        {!(appendControl || appendIcon) && (
          <InputGroup.Append>
            <InputGroup.Text>{icon}</InputGroup.Text>
          </InputGroup.Append>
        )}
        <InputGroup.Append>{appendControl}</InputGroup.Append>
      </InputGroup>
    );
  }
);

const defaults: DatePickerProps = {
  shouldCloseOnSelect: true,
  showYearDropdown: true
};

export const timeDefaults: DatePickerProps = {
  showTimeSelect: true,
  showTimeSelectOnly: true,
  timeIntervals: 5,
  dateFormat: 'h:mm aa'
};

export const dateTimeDefaults: DatePickerProps = {
  showTimeSelect: true,
  dateFormat: 'MM/dd/yyyy h:mm aa',
  timeFormat: 'h:mm aa',
  timeIntervals: 15
};

export const dateTimeInputDefaults: DatePickerProps = {
  showTimeInput: true,
  dateFormat: 'MM/dd/yyyy h:mm:ss aa',
  timeFormat: 'hh:mm:ss aa',
  timeInputLabel: 'Time:'
};

export function initializeDatePicker({outputFormat, ...options}: DatePickerProps = {}): FieldStateInitializer<Date> {
  const fieldOptions = {
    ...defaults,
    ...options
  };

  return ({ value, setter, setError, values, meta, props: {validator, timeZoneId, ...props} }) => {
    const {timeZoneId: formTimeZoneId, format } = meta.time;
    timeZoneId = timeZoneId === false ? timeZoneId : (timeZoneId ||  formTimeZoneId);
    if (outputFormat === undefined && !!format) {
      outputFormat = format;
    }
    return ({
      ...fieldOptions,
      ...props,
      selected: value ? getZonedDate(value, timeZoneId) : undefined,
      onChange: dateValue => {
        if (validator) {
          const errors = validator(dateValue, values);
          setError(errors);
          if (errors !== false) return;
        }
        if (value && dateValue && value.hasOwnProperty('toJSON') ) {
          dateValue.toJSON = value.toJSON;
        }
        if (timeZoneId) {
          dateValue = getLocalDate(dateValue, timeZoneId);
        }
        return setter(applyJSONFormat(dateValue, outputFormat));
      },
      onBlur: e => {
        if (!meta.validateOnBlur) return;

        if (e.target.willValidate) {
          e.target.checkValidity();
          setError(hasError(e.target));
        }
      }
    });
  };
}

export const DatePicker: FunctionComponent<DatePickerComponentProps> = ({
  autoComplete = 'off',
  selected = new Date(),
  ...props
}) => {
  return (
    <FormField
      fieldType={{
        initializer: initializeDatePicker({
          autoComplete,
          selected
        }),
        component: HmDatePicker
      }}
      {...props}
    />
  );
};

export const DateTimePicker: FunctionComponent<any> = ({
  name,
  label,
  timeName = name,
  timeLabel = label,
  labelSrOnly = false,
  timeIntervals = 5,
  placeholder,
  ...props
}: any) => {
  return (
    <div className="field-group field-group-connect">
      <FormField
        name={name}
        label={label}
        fieldType="utcdate-picker"
        labelSrOnly={labelSrOnly}
        placeholder={placeholder}
        {...props}
        componentProps={{
          appendIcon: true
        }}
        />

      <FormField
        name={timeName}
        label={timeLabel}
        fieldType="time-picker"
        labelSrOnly={labelSrOnly}
        {...props}
        componentProps={{ isClearable: true }}
        />

    </div>
  );
};
