import React, { createContext, FunctionComponent } from 'react';
import { DateFormats, applyJSONFormat } from 'lib.core/formatters';
import { useCurrentSettings } from 'hooks/useInstanceState';
import { HmTimeZoneId } from 'lib.app/miscData/timeZones';

type DateGenerator = (d?: Date) => Date

type FormatDateGenerator = (format: false | DateFormats, gen: DateGenerator) => DateGenerator;

type TimeContextProps = {
  timeZoneId: HmTimeZoneId;
  format: DateFormats | false;
  date: DateGenerator;
}

type TimeContextProviderProps = Partial<TimeContextProps> & {
  useInstanceTime?: boolean;
}

const dateFn: FormatDateGenerator = (format, dateGen) => format === false ? dateGen : date => applyJSONFormat(dateGen(date), format)

const timeContextDefault: TimeContextProps = {
  format: 'ISOLocal',
  timeZoneId: false,
  date: d => d ? new Date(d) : new Date()
};

export const TimeContext = createContext(timeContextDefault);

export const TimeContextProvider: FunctionComponent<TimeContextProviderProps> = ({ format = 'ISOLocal', timeZoneId = false, useInstanceTime = false, date: dateGen = timeContextDefault.date, ...props  }) => {
  const {timeZoneId: clientTimeZoneId} = useCurrentSettings();
  if (useInstanceTime) {
    timeZoneId = clientTimeZoneId;
  }
  const date = dateFn(format, dateGen);

  return (
    <TimeContext.Provider value={{
      format,
      timeZoneId,
      date
    }}>
      {props.children}
    </TimeContext.Provider>
  );
};
