import React, { FunctionComponent, useEffect } from "react";
import { useClientContext } from "hooks/useClientContext";
import { useUserState } from "hooks/useUserState";
import { Roles } from "lib.app/enums";
import { Redirect } from "react-router";
import { HmPageLoading } from "components/HmLoading";
import { Layout, Heading } from "components";
import { ClientPicker } from "components/clients";
import { HmUrl } from "lib.app/routes";

export const ChooseAccount: FunctionComponent<any> = ({history}) => {
  const {client, changeClient} = useClientContext();
  const [user] = useUserState();
  const userIsLindseyEmployee = user.roles && user.roles.includes(Roles.LindseyEmployee);
  const canChooseClient = user.clientId === 814 || userIsLindseyEmployee;

 useEffect(() => {
    if (!canChooseClient) {
      changeClient(user.clientId);
    }
  }, [user.clientId, canChooseClient]);

  const step = nextStep(user);
  if (step !== null && client.clientId) {
    return <Redirect to={step} />
  }

  if (!canChooseClient) return <HmPageLoading />

  return (
    <Layout name="splash" pageTitle="Choose Account">
      <Heading variant="splash">Choose an Account</Heading>
      <ClientPicker />
    </Layout>
  );
};

function nextStep(user) {
  if (user.action === 'terms') {
    return HmUrl.acceptTerms;
  }

  if (user.action === 'verify') {
    return HmUrl.verifyDevice
  }

  if (user.action === 'expired') {
    return HmUrl.expiredPassword
  }

  if (user.action === 'login' || user.action === 'init') {
    return user.returnUrl && user.returnUrl !== '/login' ? user.returnUrl : HmUrl.home;
  }
  return null;
}
