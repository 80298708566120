import {
  format as f,
  differenceInCalendarDays,
  formatDistance,
  parseISO,
  isBefore,
  isSameDay,
  isDate,
  startOfDay,
  startOfMonth,
  differenceInCalendarMonths,
  differenceInDays,
  differenceInMilliseconds,
  subYears,
  addDays,
  addYears,
  startOfYear,
  isLeapYear,
  addWeeks,
  startOfWeek,
  addMonths,
  addQuarters,
  startOfQuarter
} from 'date-fns';

const DATE_FORMAT = 'yyyy-MM-dd';
const STRING_DATE_FORMAT = 'MM/dd/yyyy';
const UNICODE_TIME_FORMAT = 'yyyy-MM-ddTHH:mm:ss[Z]';
const UNICODE_DATE_FORMAT = 'yyyy-MM-dd';
const UNICODE_TIME_ONLY_FORMAT = 'HH:mm:ss';
const DATE_TIME_FORMAT = 'MM/dd/yyyy h:mm:ss aaa';
const LONG_DATE_TIME_FORMAT = 'MMM dd, yyyy h:mm:ss aaa';

const dateAsKey = date => formatDate(date, DATE_FORMAT);

const formatDate = (date: string, format: string) => f(parseISO(date), format);

const dateDiff = (
  date2: Date | string | number,
  date1: Date | string | number = new Date(),
  measure?: any
) =>
  measure === 'days'
    ? dayDiff(new Date(date2), new Date(date1))
    : formatDistance(new Date(date2), new Date(date1), { addSuffix: true });

const dayDiff = (date2: Date, date1: Date = new Date()) => differenceInCalendarDays(date1, date2);

export const format = {
  isDate: value => isDate(value),
  date: formatDate,
  dateAsKey,
  unicode: value => formatDate(value, UNICODE_TIME_FORMAT),
  unicodeTimeOnly: value => formatDate(value, UNICODE_TIME_ONLY_FORMAT),
  unicodeDate: value => formatDate(value, UNICODE_DATE_FORMAT),

  mmddyyyy: value => formatDate(value, STRING_DATE_FORMAT),
  dateTime: value => formatDate(value, DATE_TIME_FORMAT),
  dateTimeLong: value => formatDate(value, LONG_DATE_TIME_FORMAT)
};

export function possibleDays(month, includeLeapDay = false) {
  const date = new Date();
  date.setMonth(month, 0);
  const days = date.getDate();
  return includeLeapDay && month === 2 ? Math.min(28, days) : days;
}

export function getOrdinalNum(n) {
  return n + (n > 0 ? ['th', 'st', 'nd', 'rd'][(n > 3 && n < 21) || n % 10 > 3 ? 0 : n % 10] : '');
}

export const dateTimeUtils = {
  dayDiff,
  dateDiff,
  monthDiff: (date1, date2 = new Date()) =>
    differenceInCalendarMonths(new Date(date1), new Date(date2)),
  isBefore: (date1, date2) => isBefore(date1, date2),
  isSameDay: (date1, date2) => isSameDay(date1, date2),
  startOfMonth: (date: Date = new Date()) => startOfMonth(date),
  startOfDay: (date: Date = new Date()) => startOfDay(date),
  addDays: (days: number, date: Date = new Date()) => addDays(date, days),
  addYears: (years: number, date: Date = new Date()) => addYears(date, years),
  subYears: (years: number, date: Date = new Date()) => subYears(date , years),
  differenceInDays: (date1: Date, date2: Date = new Date()) => differenceInDays(date1, date2),
  differenceInMilliseconds: (date1: Date, date2: Date) => differenceInMilliseconds(date1, date2),
  next: (offset, interval, pivotdate: Date = new Date()) => {
    switch(interval) {
    case 'yr':
      pivotdate = startOfYear(addYears(pivotdate, 1));
      if (offset > 58 && isLeapYear(pivotdate)) {
        offset++;
      }
      break;

    case 'wk':
      pivotdate = startOfWeek(addWeeks(pivotdate, 1));
      break;

    case 'mo':
      pivotdate = startOfMonth(addMonths(pivotdate, 1));
      break;

    case 'qtr':
      pivotdate = startOfQuarter(addQuarters(pivotdate, 1));
      break;

    default:
      offset++;
      break;
    }
    return addDays(pivotdate, offset);
  }
};
