import MaskedInput from 'react-text-mask';

export const masks = {
  ssn: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  alienId: [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/],
  zip: [/[0-9]/, /\d/, /\d/, /\d/, /\d/],
  phone: ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
  date: [/[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/, '/', /[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/],
  name: (length: number) => Array(length).fill(/[A-Za-z .'-]/),
  numeric: (length: number) => Array(length).fill(/\d/),
  alpha: (length: number) => Array(length).fill(/[A-Za-z]/),
  alphaNumeric: (length: number) => Array(length).fill(/[A-Za-z0-9]/)
};

interface MaskOptions {
  guide?: boolean;
  showMask?: boolean;
  placeholderChar?: 'string';
  keepCharPositions?: boolean;
  pipe?: (val?: any) => any;
}

export function textMask(
  mask: keyof typeof masks | any[] | {},
  options: MaskOptions = { showMask: true }
) {
  let m: any = Array.isArray(mask) ? mask : typeof mask === 'string' ? masks[mask] : mask;
  if (Array.isArray(m)) m = { mask: m };

  return {
    as: MaskedInput,
    ...options,
    ...m
  };
}

export default masks;
