import { withMock, resourceHttp, Resource } from '../..';
import { HmRequestOptions } from '../../http';

const request = resourceHttp.url('/admin/recentClients');

export const recentClients = (options: HmRequestOptions = {}) =>
  withMock(request, options, () =>
    import(/* webpackChunkName: "mockApi" */ './getRecentClients.mock.js')
  )
    .get()
    .then(result => result.clients);
