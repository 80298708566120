import { resourceHttp, withMock } from 'api';
import { HmRequestOptions } from 'api/http';
import { HmInstance } from 'HmContext';

export const http = resourceHttp.url('/billing/features');

export const getBillingFeatures = (options: HmRequestOptions = {}): Promise<HmInstance[]> =>
  withMock(http, options, () => import(/* webpackChunkName: "mockApi" */ './get.mock.json'))
  .get()
  .then(r => r.instancefeaturesresources);
