import React from 'react';
import { HmLogo } from '../HmLogo';
import { PageAlert } from '../HmAlert';
import { usePageTitle, useBodyClassName } from '../../hooks/useHmLayout';
import HmNav from 'components/nav';
import Portal from './Portal';
import { HmEmail, HmPhone, Text } from 'components';
import { HmUrl } from 'lib.app/routes';
import { Link } from 'react-router-dom';
import { HmDate } from 'components/HmDate';

export const Splash = ({ pageTitle, className, hideFooter = false, ...props }) => {
  usePageTitle(pageTitle);
  useBodyClassName(`splash-page ${className}`.trim());

  return (
    <div className="splash-body justify-content-md-center align-items-md-center">
      <Portal id="hmHeader">
        <HmNav />
      </Portal>
      {props.showLogo === true ? (
        <div className="splash-branding">
          <HmLogo />
        </div>
      ) : null}
      <div className="splash-content">
        <PageAlert name="page" />
        <div className="card">
          <div className="card-body">{props.children}</div>
        </div>
      </div>
      {!hideFooter && (
      <Portal id="hmFooter">
        <div>
          <HmEmail link email="support@housingmanager.com">Email Us</HmEmail>
          <HmPhone link phone="855-979-0770">Contact HousingManager.com</HmPhone>
        </div>
        <div>
          <Link to={HmUrl.acceptTerms}>Terms of Service</Link>
          <a href="https://housingmanager.com/privacy-policy/" target="_blank">Privacy Policy</a>
          <Text inline className="copy">
            ©<HmDate format="Year"> HousingManager.com</HmDate>
          </Text>
        </div>
      </Portal>
      )}
    </div>
  );
};

Splash.defaultProps = {
  showLogo: true
};

export default Splash;
