export function hasError(field: HTMLInputElement) {
  const { validity } = field;
  // Don't validate submits, buttons, file and reset inputs, and disabled fields
  if (field.disabled ||
    field.type === 'file' ||
    field.type === 'reset' ||
    field.type === 'submit' ||
    field.type === 'button' ||
    (field.form && field.form.classList.contains('no-validate'))) {
    return false;
  }
  if (validity.valid) {
    return false;
  }
  // If field is required and empty
  if (validity.valueMissing) {
    return field.getAttribute('data-value-missing') || 'Please fill out this field.';
  }
  // If not the right type
  // If not the right type
  if (validity.typeMismatch) {
    // Email
    if (field.type === 'email') {
      return field.getAttribute('data-type-mismatch') || 'Please enter an email address.';
    }
    // URL
    if (field.type === 'url') {
      return field.getAttribute('data-type-mismatch') || 'Please enter a URL.';
    }
  }
  // If too short
  if (validity.tooShort) {
    return (field.getAttribute('data-too-short') ||
      'Please lengthen this text to ' +
      field.getAttribute('minLength') +
      ' characters or more. You are currently using ' +
      field.value.length +
      ' characters.');
  }
  // If too long
  if (validity.tooLong) {
    return (field.getAttribute('data-too-long') ||
      'Please shorten this text to no more than ' +
      field.getAttribute('maxLength') +
      ' characters. You are currently using ' +
      field.value.length +
      ' characters.');
  }
  // If number input isn't a number
  if (validity.badInput)
    return field.getAttribute('data-bad-input') || 'Please enter a number.';
  // If a number value doesn't match the step interval
  if (validity.stepMismatch)
    return field.getAttribute('data-step-mismatch') || 'Please select a valid value.';
  // If a number field is over the max
  if (validity.rangeOverflow) {
    return (field.getAttribute('data-range-overflow') ||
      'Please select a value that is no more than ' + field.getAttribute('max') + '.');
  }
  // If a number field is below the min
  if (validity.rangeUnderflow) {
    return (field.getAttribute('data-range-underflow') ||
      'Please select a value that is no less than ' + field.getAttribute('min') + '.');
  }
  // If pattern doesn't match
  if (validity.patternMismatch) {
    // If pattern info is included, return custom error
    if (field.hasAttribute('title'))
      return field.getAttribute('title');
    // Otherwise, generic error
    return field.getAttribute('data-pattern-mismatch') || 'Please match the requested format.';
  }
  // If all else fails, return a generic catchall error
  return 'The value you entered for this field is invalid.';
}
