import React, { useState, useImperativeHandle, forwardRef, FunctionComponent, useEffect, useRef } from 'react';
import { useClientContext } from '../../hooks/useClientContext';
import { Card, Popover, Overlay } from 'react-bootstrap';
import { ClientSelect } from './ClientSelect';
import { RecentClients } from './RecentClients';
import { IconChevronDown } from '../icons';
import { ui } from '../../lib.core';
import { useUserState } from '../../hooks/useUserState';
import { Roles } from 'lib.app/enums';
import { Heading } from 'components';
import { useIfMounted } from 'hooks/useIfMounted';

export const ClientPicker: FunctionComponent<any> =  ({ show = true, onChange }) => {
  const {changeClient: chooseClient} = useClientContext();

  const handleChange = c => {
    if (onChange) onChange(c);
  };

  const onClientSelect = c =>
    chooseClient(c.clientId).then(r => handleChange(r));

  const onRecentClient = (e, c) =>
    chooseClient(c.clientId).then(r => handleChange(r));

  if (!show) {
    return null;
  }

  return (
    <Card className="border-0">
      <Card.Body className="p-0">
        <Card.Title>Accounts</Card.Title>
        <ClientSelect onChange={onClientSelect} />
        <RecentClients className="mt-4" onSelect={onRecentClient} />
      </Card.Body>
    </Card>
  );
};

export const ClientDropDown = ({ className, ...props }) => {
  const [user] = useUserState();

  const [state, _setState] = useState<any>({ target: undefined, show: false });
  const setState = useIfMounted(_setState);

  const onClick = e => {
    e.preventDefault();
    setState(s => ({ target: e.target, show: !s.show }));
  };
  const onChange = () => {
    setState(s => ({ ...s, show: false }));
  };
  if (!user.isLoggedIn || !user.roles.includes(Roles.LindseyEmployee)) {
    return null;
  }

  return (
    <>
      <span
        className={ui.classNames(className, { active: state.show })}
        onClick={onClick}
        {...props}>
        <IconChevronDown />
      </span>
      <Overlay show={state.show} target={state.target} onHide={() => onChange()} placement="bottom" rootClose>
        <Popover id="pickClient" onClick={e => e.stopPropagation()}>
          <Heading variant="modal">Property Listing</Heading>
          <ClientPicker onChange={onChange} show />
        </Popover>
      </Overlay>
    </>
  );
};
