import { authHttp, withMock, InstanceParams, InstanceDetailRequest, HmInstanceApiRequest } from '../..';
import { HmRequestOptions } from '../../http';
import { Company } from '../companies/companies';

const request = authHttp.url('/divisions');

export const getDivisions = ({wildcard}, options = {}) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './divisions.mock.js'))
    .url(`/${wildcard}`)
    .get();

export const getDivision: DivisionGetRequest = ({wildcard, divisionId}, options = {}) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './divisions.mock.js'))
    .url(`/${wildcard}/${divisionId}`)
    .get();

export const createDivision: DivisionSaveRequest = ({wildcard, values}, options = {}) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './divisions.mock.js'))
    .url(`/${wildcard}`)
    // yes this is a put for a create, why...I'll never know
    .put(values).then(r => {values.divisionId = r; return values; });

export const saveDivision: DivisionSaveRequest = ({wildcard, values}, options = {}) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './divisions.mock.js'))
    .url(`/${wildcard}`)
    // yes this is a post for a save, why...I'll never know
    .post(values).then(r => {values.divisionId = r; return values; });

export type Division = {
  instanceId: number;
  divisionId: number;
  divisionCode: string;
  divisionName: string;
  companyCode: string;
  companyId: number;
  company: Company;
  autoCreatePestControlWorkOrders: boolean;
  pestControlDefaultContractorGuid?: any;
  autoCreateHousekeepingWorkOrders: boolean;
  autoFillScheduledDate: boolean;
  useFlatRate: boolean;
  scheduledTimeStart?: any;
  scheduledTimeEnd?: any;
  defaultScheduledTimeToday?: any;
  defaultScheduledTimeNextDay?: any;
  pestControlNotificationAddresses: any[];
  housekeepingNotificationAddresses: any[];
};

type DivisionGet = InstanceParams & {  divisionId ?: string | number };

type DivisionParams = InstanceDetailRequest<Division>;

type DivisionSaveRequest = (params: DivisionParams, options?: HmRequestOptions) => Promise<Division>;
type DivisionGetRequest = HmInstanceApiRequest<DivisionGet, Division>;
