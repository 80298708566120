import { hasError } from "../hasError";

export function checkInitializer({ value, setter, setError, props: { value: checkValue, onChange, isChecked, defaultValue, fireOnChange } = { value: undefined, onChange: undefined as any, defaultValue: undefined as any, isChecked: undefined as any, fireOnChange: 'both' } }) {
	isChecked = isChecked || (v => (checkValue === v) || (checkValue === undefined && (v === true || v === 1 || v === 'true' || v === 'True')));
	return {
		value: checkValue,
		checked: isChecked(value),
		onChange: e => {
			const t = checkValue === undefined ? true : checkValue;
			const f = checkValue === undefined ? false : (defaultValue || '');
			onChange && onChange(e);
			if (!onChange || fireOnChange !== 'only')
				setter(e.target.checked ? t : f);
		},
		onBlur: e => {
			if (e.target.willValidate)
				e.target.checkValidity();
			setError(hasError(e.target));
		}
	};
}
