import { authHttp, withMock, HmRequestOptions } from '../../http';
import { ChangePasswordRequest } from './expired';
import { handleNullResponse } from '../../utils';

const http = authHttp.url('/auth/reset');
export const resetPassword = (
  request: ChangePasswordRequest,
  options: HmRequestOptions = {}
) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  ).post(request)
  .catch(handleNullResponse);
