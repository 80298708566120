import { authHttp, withMock, HmRequestOptions } from '../../http';

const http = authHttp.url('/auth/verify');

export interface VerifyDeviceRequest {
  clientId: number;
  userId: number;
  code: number;
}

export const verifyDevice = (
  request: VerifyDeviceRequest,
  options: HmRequestOptions = {}
) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  )
  .post(request);
