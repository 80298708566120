import { useContext, useMemo } from 'react';
import { HmContext } from '../HmContext';
import { guard, HmAccessKey, HmAccess, HmGuardParams, HmGuardProps, featureGuards } from 'lib.security';
import { useClientContext } from './useClientContext';

export function useGuardContext() {
  const context = useContext(HmContext);
  const {features = [], instances = []} = useClientContext();
  return {...context, instances: features, clients: instances };
}

export function useGuard(access: HmAccessKey | HmGuardParams | boolean) {
  const guardContext = useGuardContext();

  return useMemo(() => {
    const guardProps = typeof access === 'string' ? resolveProps(access) : access;
    return guard(guardProps, guardContext as any);
  }, [access, guardContext]);
}

export function useGuardByProps(props: HmGuardProps, access ?: HmAccessKey | boolean): [boolean, HmGuardProps] {
  const guardContext = useGuardContext();

  return useMemo(() => {
    if (typeof access === 'boolean') return [guard(access, guardContext as any), props];

    const guardProps = {...resolveProps(access), ...props };
    return [guard(guardProps, guardContext as any), guardProps];
  }, [access, props]);
}

function resolveProps(access): HmGuardParams {
  if (!access) {
    return {};
  }
  return HmAccess[access];
}
