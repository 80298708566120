import React, { useLayoutEffect } from 'react';
import { Container } from 'react-bootstrap';
import { ui } from 'lib.core';
import { useWindowDimensions } from 'hooks/useWindowDimensions';

const positionSticky = () => {
  const el = document.querySelector('.sticky-actions') as any;
  if (!el) return;
  const offset = parseInt(el.getAttribute('data-offset') || '0', 10) + el.parentElement!.offsetTop;
  el.style.top = `${offset}px`;
}

export const HmActions = ({children, justify = 'end', sticky = false, ...props}) => {
  useWindowDimensions(sticky ? positionSticky : undefined);

  return (
    <Container fluid className={ui.classNames("hm-actions", {'sticky-actions': sticky})} {...props}>
      {React.Children.map(children, (child) => React.cloneElement(child, {
          className: `justify-content-${justify}`
        })
      )}
    </Container>
  );
};
