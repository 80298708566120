import React, { useEffect, useState } from 'react';
import spinner from '@lindsey-software/housing-manager-ux/content/img/spinner.gif';

export const HmLoading = ({...props}) => {
  return <img src={spinner} alt="loading" className="loading" {...props} />;
};

export const HmPageLoading = ({startDelay = 250}) => {
  const [render, setRender] = useState(false);
  useEffect(() => {
    let delay: any = setTimeout(() => {
      setRender(true);
      delay = 0;
    }, startDelay);
    return () => {
      delay && clearTimeout(delay)
    }
  }, [startDelay]);

  // mount the component and wait a smidge before rendering
  if (!render) return <></>;

  return (
    <div className="loading-panel">
      <HmLoading />
    </div>
  );
};
