import React, { FunctionComponent, useLayoutEffect, useRef } from 'react';
import { ui } from '../lib.core';

interface HmDrawerProps {
  show?: boolean;
  id?: string;
  className?: any;
}

export const HmDrawer: FunctionComponent<HmDrawerProps> = ({ show = false, className, ...props }) => {
  const ref = useRef(null) as any;

  useLayoutEffect(() => {
    if (!show) return;
    const element = ref.current as HTMLElement;
    if (!element) return;
    element.parentElement!.style.paddingBottom = element.offsetHeight + 30 + 'px';
    return () => {
      element.parentElement!.style.paddingBottom = '';
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show]);

  return (
    <footer
      ref={ref}
      {...props}
      className={ui.classNames('drawer', 'drawer-btm', 'shadow-lg', className, { show })}>
      {props.children}
    </footer>
  );
};
