import { useContext } from 'react';
import { HmDetailContext, HmDetailContextState } from 'components/HmDetail';

type DetailParams = Pick<HmDetailContextState<any, any>, 'save' | 'refresh'>;

export function useDetail<T = any, A = any, P = any>(): [T, A, DetailParams & P] {
  const {state, actions, ...context} = useContext(HmDetailContext);

  return [state as T, actions as A, context as DetailParams & P];
}

export function useDetailState<T = any>(): T {
  const {state} = useContext(HmDetailContext);
  return state as T;
}

export function useDetailActions<A = any>(): A {
  const {actions} = useContext(HmDetailContext);
  return actions as A;
}

export function useDetailParams<P = any>(): DetailParams & P {
  const {state, actions, ...context} = useContext(HmDetailContext);
  return context as DetailParams & P;
}
