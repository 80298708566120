import React, { Component, useCallback, FunctionComponent, useMemo } from 'react';
import {withScope, captureException, configureScope} from '@sentry/browser';
import { ExceptionMessage } from 'components/HmAlert';
import config from 'config';
import { useUserState } from 'hooks/useUserState';
import { useCurrentWildcard } from 'hooks/useInstanceState';

type Bag = {
  [x: string]: any;
}

interface SentryProps {
  extras?: Bag;
  container?: any;
}

interface SentryState {
  hasError: boolean;
  error?: any;
  eventId?: any;
}

class _SentryErrorBoundary extends Component<SentryProps, SentryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: false };
  }

  componentDidCatch(error, errorInfo) {
    let eventId;
    if (config.sentry.enabled) {
      withScope(scope => {
        if (this.props.extras) {
          scope.setExtras(this.props.extras)
        }
        scope.setExtra('componentStack', errorInfo.componentStack);
        eventId = captureException(error);
      });
    }
    this.setState({ hasError: true, error, eventId })
  }

  render() {
    const Container = this.props.container || React.Fragment;
    if (this.state.hasError) {
      return (
        <Container>
          <ExceptionMessage error={this.state.error} eventId={this.state.eventId} />
        </Container>
      )
    }
    return this.props.children;
  }
}

export function useSetSentryUser() {
  const [{userId: id, username, email, roles, clientId }] = useUserState();
  const wildcard = useCurrentWildcard();

  return useMemo(() =>
    configureScope(scope => {
      scope.setUser({id, username, email, roles, clientId, wildcard } as any);
    }), [id, wildcard]
  );
}

export function useSentry(additionalScope) {
  useSetSentryUser();

  return useCallback(error => {
    withScope(scope => {
      additionalScope && additionalScope(scope);
      return captureException(error);
    });
  }, [additionalScope]);
}

const SentryErrorBoundary: FunctionComponent<SentryProps> = (props) => {
  useSetSentryUser();

  return (
    <_SentryErrorBoundary {...props}>

    </_SentryErrorBoundary>
  )
}

export default SentryErrorBoundary;
