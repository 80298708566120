import { parse, formatDistance, isValid } from 'date-fns';
import { format, utcToZonedTime , zonedTimeToUtc } from 'date-fns-tz';
import { getTimeZone,  } from 'lib.app/miscData/timeZones';
export { formatPercent, formatNumber } from './numbers';

// ref: https://date-fns.org/v2.0.0-alpha.23/docs/format
export const formats = {
  Short: 'M/d/yyyy h:mm a',
  ShortDate: 'M/d/yyyy',
  ShortDateTime: 'M/d/yyyy hh:mm aaa',
  LongDateTime: 'M/d/yyyy h:ii:ss a',
  MonthDay: 'MM/dd',
  MonthDayYear: 'MMMM d, yyyy',
  MonthDayYearAndTime: 'MMMM d, yyyy hh:mm aaa',
  ShortTime: 'h:mm a',
  Long: 'P p',
  LongDate: 'P',
  LongTime: 'pp',
  Medium: 'PPp',
  MediumDate: 'PP',
  MediumTime: 'p',
  Url: 'yyyy-M-d',
  ISODate: 'yyyy-MM-dd',
  ISOTime: '\'T\'HH:mm:ss', //mileage may vary
  ISOLocal: 'yyyy-MM-dd\'T\'HH:mm:ss',
  Year: 'yyyy',
  ago: new Date()
};

export type DateFormats = keyof typeof formats;

export type MoneyOptions = {
  decimalCount?: 2;
  decimal?: string;
  thousands?: string;
  fallback?: string;
  append ?: string;
  prepend ?: string;
  preserveCents?: boolean;
};

export function formatMoney(amount, options: MoneyOptions = {}) {
  const {decimalCount = 2, decimal = '.', thousands = ',', fallback = '-', append = '', prepend = '', preserveCents = true} = options;

  if (!amount || isNaN(amount)) return fallback;

  amount = parseFloat(amount);

  let formatted = Math.abs(amount).toFixed(Math.max(0, decimalCount));
  if (decimal !== '.') formatted = formatted.replace('.', decimal);
  formatted = '$' + formatted.replace(/\d(?=(\d{3})+\.)/g, '$&' + thousands);
  if (!preserveCents) formatted = formatted.replace(`${decimal}00`, '');
  if (amount < 0) formatted = `(${formatted})`;

  return `${prepend}${formatted}${append}`;
}

export function formatDate(date: Date | string = new Date(), dateFormat: Date | string = formats.ShortDate): string {
  const newDate = new Date(date);
  if (!isValid(newDate)) return date as any;
  if (dateFormat === 'ago' || dateFormat instanceof Date) {
    return formatDistance(newDate, dateFormat === 'ago' ? new Date() : dateFormat);
  }
  return format(newDate, dateFormat);
}

export function applyJSONFormat(date: Date, requestFormat?: DateFormats): Date {
  if (requestFormat === undefined || !date) return date;
  date.toJSON = function () { return formatDate(this, formats[requestFormat]) } ; // no lambda, so `this` resolves correctly
  return date;
}

export function getZonedDate(zonedDate: Date | string | number, timeZoneId) {
  if (!timeZoneId) return new Date(zonedDate);
  const { ianaName } = getTimeZone(timeZoneId);
  return utcToZonedTime(zonedDate, ianaName);
}

export function getLocalDate(date: Date, timeZoneId) {
  const { ianaName } = getTimeZone(timeZoneId);
  return zonedTimeToUtc(new Date(date.toISOString()), ianaName);
}

export function parseDate(date, dateFormat = formats.ShortDate) {
  return parse(date, dateFormat, new Date());
}

export function formatPhone(tel, forUrl = false) {
  if (!tel) return '';

  const value = ('' + tel).replace(/\D/g, '');

  // tslint:disable-next-line: one-variable-per-declaration
  let country, city, number;

  switch (value.length) {
    case 10: // +1PPP####### -> C (PPP) ###-####
      country = 1;
      city = value.slice(0, 3);
      number = value.slice(3);
      break;

    case 11: // +CPPP####### -> CCC (PP) ###-####
      country = value[0];
      city = value.slice(1, 4);
      number = value.slice(4);
      break;

    case 12: // +CCCPP####### -> CCC (PP) ###-####
      country = value.slice(0, 3);
      city = value.slice(3, 5);
      number = value.slice(5);
      break;

    default:
      return tel;
  }

  if (forUrl) {
    return `+${country}${city}${number}`;
  }

  if (country === 1) {
    country = '';
  }

  number = number.slice(0, 3) + '-' + number.slice(3);

  return (country + ' (' + city + ') ' + number).trim();
}
