import React, { FunctionComponent, Fragment } from 'react';
import { Text } from '../components/typography';
import { MoneyOptions, formatMoney } from '../lib.core/formatters';
import { Variant } from './typography/Text';

export const HmMoney: FunctionComponent<
  MoneyOptions & {
    value: number;
    variant?: Variant;
    className?: any;
  }
> = ({ value, variant, className,  children, ...options }) => {
  const As = (variant || className) ? 'span' : Fragment;
  return (
    <Text variant={variant} as={As} className={className}>
      {formatMoney(value, options)}
      {children}
    </Text>
  );
};
