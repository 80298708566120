import { hasError } from '../hasError';

export function inputInitializer({ value, setter, setError, meta, props: { ref, useInput, ...fieldProps } = {} as any }) {
  const updater = e => { setter(e.target.value); };
  if (useInput === true) {
    fieldProps.onInput = updater;
  } else {
    fieldProps.onChange = updater;
  }

  return {
    ...fieldProps,
    value: value || '',
    onBlur: e => {
      if (!meta.validateOnBlur) {
        return;
      }
      if (e.target.willValidate) e.target.checkValidity();
      setError(hasError(e.target));
    }
  };
}
