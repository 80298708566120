import http, { HmApiRequest, withMock } from 'api';

type SearchResidentPortalRequest = {
  firstName: string;
  lastName: string;
  amount: number;
  accountNumber?: string;
}

export type PortalInfo = {
  clientId: number;
  instanceId: number;
  wildcard: string;
  companyName: string;
  residentId: number;
  email: string;
  phone: string;
  username: string;
  hasLogin: boolean;
  firstName: string;
  lastName: string;
  portalAvailable: boolean;
  portalTitle: string
}

const registrationHttp = http.url('/registration');

export const lookupResidentPortal: HmApiRequest<SearchResidentPortalRequest, PortalInfo[]> = (request, options = {}) =>
  withMock(registrationHttp, options, () => import(/* webpackChunkName: "mockApi" */ './residentPortal.mock'))
  .url('/residentPortal')
  .post(request);