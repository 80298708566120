import React, { useLayoutEffect, useEffect, FunctionComponent } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PageAlert } from 'components/HmAlert';

let handler;
const handleResize = () => {
  if (handler) clearTimeout(handler);
  handler = setTimeout(() => {
    positionSidebar();
  }, 200);
}

const positionSidebar = () => {
  const sidebar = document.getElementById('sidebar');
  if (!sidebar) return;
  sidebar.style.top = `${sidebar.parentElement!.offsetTop}px`;
  sidebar.style.height = `calc(100vh - ${sidebar.parentElement!.offsetTop}px)`;
}

export const ContentSidebar: FunctionComponent<any> = ({sidebar, append = null, ...props}) => {
  useLayoutEffect(() => {
    if (!window) return;
    setTimeout(positionSidebar, 0);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const {main = {}, side = {}, component = append} = (append||{});

  return (
    <Container fluid>
      <Row>
        <Col as="aside" id="sidebar" lg={3} md={4} sm={12} {...side}>{sidebar}</Col>
        <Col as="section" className="main-content" lg={9} md={8} sm={12} {...main}>
          <PageAlert name="page" />
          {props.children}
        </Col>
        {component}
      </Row>
    </Container>
  );
};
