import { GuardContext, AccountStatus } from "./";
import { HmFeatures, Roles } from "lib.app/enums";
import { HmInstance } from "HmContext";
import config from "config";

const instanceFeatureChecks = new Map<HmFeatures, (instance: HmInstance) => boolean>();
instanceFeatureChecks.set(HmFeatures.Residents, i => i.residents && i.accountStatusId > 0);
instanceFeatureChecks.set(HmFeatures.Units, i => (i.onlineWorkOrders || i.residents || i.onlineMaintenanceRequests) && i.accountStatusId > 0);
instanceFeatureChecks.set(HmFeatures.Applications, i => i.applications && i.accountStatusId > 0);
instanceFeatureChecks.set(HmFeatures.WordOrders, i => (i.onlineWorkOrders) && i.accountStatusId > 0);

export function checkInstanceFeature(feature) {
  if (feature === true) return () => true;
  const check = instanceFeatureChecks.get(feature);
  if (!check) return () => false;
  return check;
}

export const featureGuards = new Map<HmFeatures, (context: GuardContext) => boolean>();
featureGuards.set(HmFeatures.ApplicationAdvancedEligibilityRules, ({ instance }) => instance.advancedEligibilityRules);
featureGuards.set(HmFeatures.ApplicationAdvancedOpeningTypes, ({ instance }) => instance.advancedOpeningTypes);
featureGuards.set(HmFeatures.ApplicationAutoRenewDrop, ({ instance }) => instance.autoRenewDrop);
featureGuards.set(HmFeatures.ApplicationCustomQuestionsAndPreferences, ({ instance }) => instance.customQuestionsAndPreferences);
featureGuards.set(HmFeatures.ApplicationEdit, ({ instance }) => instance.applicantCanEditApplication);
featureGuards.set(HmFeatures.Applications, ({ instance }) => checkInstanceFeature(HmFeatures.Applications)(instance));
featureGuards.set(HmFeatures.ApplicationTwoFactorAuth, ({ instance }) => instance.enhancedApplicationSecurity);
featureGuards.set(HmFeatures.AssignApplicationToUser, ({ instance }) => instance.assignApplicationToUser);
featureGuards.set(HmFeatures.CustomerApplications, ({ instances }) => instances.some(checkInstanceFeature(HmFeatures.Applications)));
featureGuards.set(HmFeatures.CustomerResidents, ({ instances }) => instances.some(checkInstanceFeature(HmFeatures.Residents)));
featureGuards.set(HmFeatures.CustomerUnits, ({ instances }) => instances.some(checkInstanceFeature(HmFeatures.Units)));
featureGuards.set(HmFeatures.CustomerWordOrders, ({ instances }) => instances.some(checkInstanceFeature(HmFeatures.WordOrders)));
featureGuards.set(HmFeatures.DevEnvironment, () => config.app.isDev);
featureGuards.set(HmFeatures.InstanceBillingContact, ({ user, instance }) => user.roles.includes(Roles.LindseyEmployee) || user.instanceRoles.find(i => i && i.instanceId === instance.instanceId && i.name === 'BillingAdmin') !== undefined);
featureGuards.set(HmFeatures.Ivr, ({ instance }) => instance.ivr);
featureGuards.set(HmFeatures.Legacy, ({ instance }) => instance.isLegacyBilling);
featureGuards.set(HmFeatures.LindseySoftwareOnly, ({ settings }) => settings.isLindseyCustomer);
featureGuards.set(HmFeatures.MaintenanceRequests, ({ instance }) => instance.onlineMaintenanceRequests);
featureGuards.set(HmFeatures.MisconfiguredMaintenanceReqeusts, ({ instance }) => instance.onlineMaintenanceRequests);
featureGuards.set(HmFeatures.MisconfiguredOnlineRent, ({ instances }) => instances.some(i => i.onlineRentCollectionIsMisconfigured));
featureGuards.set(HmFeatures.OnlineCustomersOnly, ({ settings }) => settings.isLindseyCustomer === false);
featureGuards.set(HmFeatures.OnlineRentCollection, ({ instance }) => instance.onlineRentCollection);
featureGuards.set(HmFeatures.ResidentPortal, ({ instance }) => instance.residentPortal);
featureGuards.set(HmFeatures.Residents, ({ instance }) => checkInstanceFeature(HmFeatures.Residents)(instance));
featureGuards.set(HmFeatures.RoleManager, ({ user, client }) => user.roles.includes(Roles.Security) && client.clientId === 814 )
featureGuards.set(HmFeatures.SomeOnlineInstances, ({ clients }) => clients.some(s => !s.isLindseyCustomer && s.isActive));
featureGuards.set(HmFeatures.Trial, ({ instance }) => instance.accountStatusId === AccountStatus.Trial);
featureGuards.set(HmFeatures.WaitingListManagement, ({ instance, settings }) => instance.waitingListManagement || (settings.waitingListReportsEnabled));
featureGuards.set(HmFeatures.WordOrders, ({ instance }) => checkInstanceFeature(HmFeatures.WordOrders)(instance));
