import React, { useState, FunctionComponent } from 'react';
import { Collapse } from 'react-bootstrap';
import { IconChevronDown, IconChevronRight } from 'components/icons';
import { Heading } from 'components';
import { ui } from 'lib.core';

export const CollapsePanel: FunctionComponent<any> = ({ title, children, collapsed = false, headingClassName, ...props }) => {
  const [show, setShow] = useState(!collapsed);
  return (
    <>
      <Heading variant="filter" className={ui.classNames(headingClassName, {active: show})} onClick={() => setShow(s => !s)}>
        {show ? <IconChevronDown /> : <IconChevronRight />} {title}
      </Heading>
      <Collapse in={show} ><div {...props}>{children}</div></Collapse>
    </>
  );
};
