import { hasError } from '../hasError';
import { pipe } from 'lib.core';

const valueApplicator = (value, format) =>
  (value || value === 0) && !isNaN(value) ? format(Number(value)) : '';

export function numberInitializer({
  value,
  setter,
  setError,
  cursor,
  props: { ref, format = v => v, ...fieldProps } = { ref: undefined } as any
}) {
  const { min, max, step } = fieldProps;
  const constrain = (value, strict = true) => {
    if (typeof value === 'string' && strict === false) {
      if (value.endsWith('.')) return value;
      const [, frac] = value.split('.');
      if (frac && frac.length <= 2) return value;
    }
    if (isNaN(value)) value = 0;
    if (step) value = Math.floor(value / step) * step;
    if (min !== undefined && value < min) value = min;
    else if (max !== undefined && value > max) value = max;
    return Number(value);
  };
  return {
    ...fieldProps,
    value: valueApplicator(value, format),
    onChange: e => {
      cursor.current = e.target.selectionStart;
      if (e.target.value.endsWith('..')) {
        cursor.current++;
      }
      setter(valueApplicator(e.target.value.replace('..', '.'), v => constrain(v, false)));
    },
    onBlur: e => {
      e.target.value = valueApplicator(e.target.value, pipe(constrain, format));
      if (e.target.willValidate) e.target.checkValidity();
      setError(hasError(e.target));
    },
    helpers: {
      constrain: value => setter(constrain(value))
    }
  };
}
