import { useContext, Dispatch } from 'react';
import { HmContext, updateInstance, HmInstance, HmSettings, HmConnections, refreshConnections } from '../HmContext';

export function useInstanceState(): [HmInstance, Dispatch<HmInstance>] {
  const {dispatch, instance } = useContext(HmContext);
  const instanceDisptach = (instance: HmInstance) => dispatch({ type: updateInstance.action, payload: instance });

  return [instance, instanceDisptach];
}

export function useCurrentWildcard() {
  const [{wildcard}] = useInstanceState();
  return wildcard;
}

export function useCurrentSettings() : HmSettings {
  const { settings } = useContext(HmContext);
  return settings;
}

export function useCurrentConnections() : [HmConnections, () => any ] {
  const { connections = {}, dispatch } = useContext(HmContext);
  return [connections, () => dispatch(refreshConnections.send())];
}