import { HmAccess } from '../../lib.security';
import { HmUrl } from './hmUrl';

export const hmAdmin = [
  {
    title: 'Billing',
    path: HmUrl.billing('packages'),
    component: 'Billing',
    guard: HmAccess.administerAccount
  },
  {
    title: 'Customers',
    path: HmUrl.customers,
    component: 'Customers',
    guard: HmAccess.customers
  },
  {
    title: 'Auditing',
    path: HmUrl.auditing,
    component: 'Auditing',
    guard: HmAccess.auditing
  },
  {
    title: 'Enterprise Keys',
    path: HmUrl.enterpriseKeys,
    component: 'EnterpriseKeys',
    guard: HmAccess.enterpriseKeys
  },
  {
    title: 'Notifications',
    path: HmUrl.notifications,
    component: 'Notifications',
    guard: HmAccess.notifications
  }
];

export const hmMain = [
  {
    title: 'Applications',
    path: HmUrl.applications,
    component: 'Applications',
    guard: HmAccess.applicationsAnyInstance,
  },
  {
    title: 'Units',
    path: HmUrl.units(),
    component: 'Units',
    guard: HmAccess.unitsAnyInstance
  },
  {
    title: 'Residents',
    path: HmUrl.residents(),
    component: 'Residents',
    guard: HmAccess.residentsAnyInstance
  },
  {
    title: 'Work Orders',
    path: HmUrl.workOrders,
    component: 'WorkOrders',
    guard: HmAccess.workOrdersAnyInstance
  }
];
