export type HmTimeZoneId = boolean | 1 | 2 | 3 | 4 | 5 | 6;

export type HmTimeZone = {
  timeZoneId: HmTimeZoneId;
  zone: string;
  ianaName: string;
  code: string;
  hourDiff: number;
  utcOffset: string;
  description: string;
}

export const timeZones: HmTimeZone[] = [
  {
    timeZoneId: 1,
    zone: 'Eastern Time',
    ianaName: 'America/New_York',
    code: 'EST',
    hourDiff: 1,
    utcOffset: '-05:00',
    description: 'Time is in Eastern Time (US & Canada)'
  },
  {
    timeZoneId: 2,
    zone: 'Central Time',
    ianaName: 'America/Chicago',
    code: 'CST',
    hourDiff: 0,
    utcOffset: '-06:00',
    description: 'Time is in Central Time (US & Canada)'
  },
  {
    timeZoneId: 3,
    zone: 'Mountain Time',
    code: 'MST',
    ianaName: 'America/Denver',
    hourDiff: -1,
    utcOffset: '-07:00',
    description: 'Time is in Mountain Time (US & Canada)'
  },
  {
    timeZoneId: 4,
    zone: 'Pacific Time',
    ianaName: 'America/Los_Angeles',
    code: 'PST',
    hourDiff: -2,
    utcOffset: '-08:00',
    description: 'Time is in Pacific Time (US & Canada)'
  },
  {
    timeZoneId: 5,
    zone: 'Alaskan Time',
    code: 'AKST',
    ianaName: 'America/Anchorage',
    hourDiff: -3,
    utcOffset: '-09:00',
    description: 'Time is in Alaskan Time'
  },
  {
    timeZoneId: 6,
    zone: 'Hawaii-Aleutian Time',
    code: 'HST',
    ianaName: 'Pacific/Honolulu',
    hourDiff: -4,
    utcOffset: '-10:00',
    description: 'Time is in Hawaii-Aleutian Standard Time'
  }
];

export const getTimeZone = timeZoneId => {
  const timeZone = timeZones.find(t => t.timeZoneId === timeZoneId);
  return timeZone as HmTimeZone;
};
