import React from 'react';
// tslint:disable-next-line: no-submodule-imports
import logo from '@lindsey-software/housing-manager-ux/content/img/hm-nested2.png';
import { Link } from 'react-router-dom';
import { HmUrl } from '../lib.app/routes';

export const HmLogo = (props) => {
  const { alt, ...rest} = props;

  return (
    <Link to={HmUrl.home}>
      <img src={logo} alt={alt || 'My.HousingManager.com'} {...rest} />
    </Link>
  );
};
