import React, { useState, useEffect, ReactNode } from 'react';
import { Alert, Row, Col, Button } from 'react-bootstrap';
import { useSharedState, createSharedState, getSharedState, loadSessionStorageInitializer } from '../hooks/useSharedState';
import { IconDanger, IconVariant } from './icons';
import { Heading, Text } from './typography';
import { showReportDialog } from '@sentry/browser';
import { HmGuard } from './security/HmGuard';

export type AlertVariants =
  | 'danger'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'info'
  | 'dark'
  | 'light';

export interface IHmAlertOptions {
  variant?: AlertVariants;
  heading?: ReactNode;
  reversed?: boolean;
  dismissable?: boolean;
  className?: string;
  icon?: any;
  iconSize?:
    | 'xs'
    | 'lg'
    | 'sm'
    | '1x'
    | '2x'
    | '3x'
    | '4x'
    | '5x'
    | '6x'
    | '7x'
    | '8x'
    | '9x'
    | '10x';
  showFor?: number;
}

export interface IHmAlert extends IHmAlertOptions {
  alertId?: number;
  show?: boolean;
  children?: ReactNode;
  onClose?: () => void;
}



createSharedState<IHmAlert[]>('alert:toast', loadSessionStorageInitializer<IHmAlert[]>([]));
createSharedState<IHmAlert[]>('alert:summary', loadSessionStorageInitializer<IHmAlert[]>([]));
createSharedState<IHmAlert[]>('alert:page', loadSessionStorageInitializer<IHmAlert[]>([]));

export const ExceptionMessage = ({ error, eventId }: any) => {
  if (!error) return null;
  if (error.json) error = error.json;
  let { message } = error;

  if (!message) {
    if (error.json && error.json.message) {
      message = error.json.message;
    }
    if (error.error_description) {
      message = error.error_description;
    }
  }

  return (
    <>
      <Heading variant="alert">Something went wrong!</Heading>
      <Text>{message}</Text>
      <HmGuard access="devEnvironment">
        <pre>
          <code>{JSON.stringify(error, null, 2)}</code>
        </pre>
      </HmGuard>
      <HmGuard access="employeesOnly">
        {eventId && <Button onClick={() => showReportDialog({ eventId })}>Report Feedback</Button>}
      </HmGuard>
    </>
  );
};

let alertId = 0;
function createAlert(message, options: IHmAlertOptions = {}): IHmAlert {
  if (typeof message !== 'string' && !Array.isArray(message) && !React.isValidElement(message)) {
    message = <ExceptionMessage error={message} />;
  }
  return {
    alertId: alertId++,
    variant: 'danger',
    icon: undefined,
    iconSize: undefined,
    heading: null,
    children: message,
    showFor: -1,
    ...options
  };
}

type Alerter = (message: ReactNode, options?: IHmAlertOptions) => void;

function getAlerter(name, defaultOptions: any = {}): Alerter {
  const state = getSharedState<IHmAlert[]>(`alert:${name}`);
  return (message, options = {}) =>
    state!.setValue([...state!.value, createAlert(message, { ...defaultOptions, ...options })]);
}

export const toast = getAlerter('toast', { showFor: 2500 });
export const toastSuccess: Alerter = (message, options = {}) => toast(message, { variant: 'success', ...options });
export const addSummary = getAlerter('summary');
export const addPageMessage = getAlerter('page', { variant: 'info' });
export const addPageError: Alerter = (message, options = {}) => addPageMessage(message, { variant: 'danger', iconSize: '2x', ...options });

export const addReloadMessage = (name, message: string, options: IHmAlertOptions = {}) => {
  const key = `alert:${name}`;
  const value = JSON.parse(sessionStorage.getItem(key) || '[]');
  value.push(createAlert(message, options));
  sessionStorage.setItem(key, JSON.stringify(value));
};
export const toastReload = (message:string, options: AlertVariants | IHmAlertOptions = {} ) =>
  addReloadMessage('toast', message, typeof(options) === 'string' ? { variant: options, showFor: 2500 } : { variant: 'info', showFor: 2500, ...options});

function defaultIcon(variant, size) {
  switch (variant) {
    case 'danger':
      return <IconDanger size={size} />;
    default:
      return <IconVariant variant={variant} size={size} />;
  }
}

export const HmAlert = ({
  dismissable = false,
  variant = 'danger',
  children,
  heading,
  reversed = false,
  iconSize = '1x',
  icon = defaultIcon(variant, iconSize),
  show = true,
  onClose,
  showFor = -1,
  ...props
}: IHmAlert) => {
  const [showing, setShowing] = useState(show);

  useEffect(() => {
    show !== showing && setShowing(show);
  }, [show]);

  useEffect(() => {
    if (showFor > 0) {
      const handle = setTimeout(() => {
        setShowing(false);
      }, showFor);
      return () => clearTimeout(handle);
    }
  }, [showFor]);

  const Icon = icon ? (
    <Col sm="auto" className="icon">
      {icon}
    </Col>
  ) : (
    ''
  );
  const Heading = heading ? <Alert.Heading>{heading}</Alert.Heading> : '';

  function handleClose() {
    if (onClose) onClose();
    setShowing(false);
  }

  const count = React.Children.count(children);
  let v = variant as any;
  if (reversed) {
    v = `${variant}-reversed`;
  }

  return (
    <Alert dismissible={dismissable} show={showing} onClose={handleClose} variant={v} {...props}>
      {Heading}
      <Row>
        {Icon}
        <Col>
          {count > 1 ? (
            <ul className="alert-list">
              {React.Children.map(children, (c, idx) => (
                <li key={idx}>{c}</li>
              ))}
            </ul>
          ) : (
            <>{children}</>
          )}
        </Col>
      </Row>
    </Alert>
  );
};

export const PageAlert = ({ name }) => {
  const [alerts, setAlerts] = useSharedState<any[]>(`alert:${name}`);
  useEffect(() => {
    return () => {
      // clean alerts of we have handled.
      setAlerts(
        alerts.filter(a => a.showFor > 0),
        true
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  alerts.forEach(
    alert =>
      (alert.onClose = () => {
        setAlerts(
          alerts.filter(a => a.alertId !== alert.alertId),
          true
        );
      })
  );

  return (
    <>
      {alerts.map(alertProps => {
        const { children, alertId, onClose, ...props } = alertProps;
        return (
          <HmAlert key={alertId} dismissible onClose={onClose} {...props}>
            {children}
          </HmAlert>
        );
      })}
    </>
  );
};

export const Toaster = () => {
  return (
    <div
      style={{
        position: 'fixed',
        top: 0,
        right: 0,
        height: 0,
        padding: '20px',
        flex: 'none',
        zIndex: 10001
      }}>
      <PageAlert name="toast" />
    </div>
  );
};
