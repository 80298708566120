const mockMiddleware = next => (url, opts) => {
  //  mock requested, keep going
  if (!opts.mock || !opts.mock.promiseFn) return next(url, opts);

  return opts.mock.promiseFn().then(mock => {
    if (mock && mock.default) mock = mock.default;
    // mock wants to handle the things
    if (typeof mock === 'function') {
      mock = mock(url, opts);
    }

    // mock was kind and gave us a response
    if (mock instanceof Response) {
      mock.headers.append('x-mock', `Mocked request ${opts.method} @ ${url}`);
      return mock;
    }

    // build the response
    const init = {
      status: opts.mock.status,
      statusText: opts.mock.statusText,
      headers: { 'x-mock': `Mocked request ${opts.method} @ ${url}` }
    };

    const mockResult =
      mock[`${opts.mock.status}:${opts.mock.statusText}`] || mock[`${opts.mock.status}`] || '';
    // wretch wants a response promise, give it what it wants
    return new Response(
      mockResult === null || mockResult === '' ? mockResult : JSON.stringify(mockResult),
      init
    );
  });
};

export default mockMiddleware;
