import React, { FunctionComponent } from 'react';
import { MutedText } from 'components/typography';
import { HmGuard } from 'components';
import { HmAccessKey } from 'lib.security';
import { Card } from 'react-bootstrap';

export const HmAdminPanel : FunctionComponent<{access?: HmAccessKey}> = ({access = 'employeesOnly', children}) => {
  return (
    <HmGuard access={access} component={null}>
      <Card className="admin">
        <Card.Header>
          Admin-Only <MutedText>(Customer cannot see these settings)</MutedText>
        </Card.Header>
        <Card.Body>
          {children}
        </Card.Body>
      </Card>
    </HmGuard>
  );
};
