import React, { Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import {useAsync} from 'react-async';

import {
  Home,
  Errors,
  Login,
  ForgotPassword,
  ResetPassword,
  ExpiredPassword,
  Terms,
  ChooseAccount,
  ResidentPortal
} from './areas';


import { HmPageLoading } from './components/HmLoading';
import { HmUrl } from './lib.app/routes';
import { Logout } from 'areas/login/Login';
import { VerifyDevice } from 'areas/login';
import { TourDashboard } from 'areas/tour';
import { NewPassword } from 'areas/ResetPassword';
import { useParams } from 'react-router';
import { viewApplication } from 'api/v1/application/view/viewApplication';

const Residents = lazy(() => import(/* webpackChunkName: "res" */'./areas/residents'));
const Units = lazy(() => import(/* webpackChunkName: "res" */'./areas/units'));
const Applications = lazy(() => import(/* webpackChunkName: "apps" */ './areas/applications'));
const WorkOrders = lazy(() => import(/* webpackChunkName: "wo" */'./areas/workorders'));
const Settings = lazy(() => import(/* webpackChunkName: "settings" */'./areas/settings'));
const Admin = lazy(() => import(/* webpackChunkName: "admin" */'./areas/admin'));


export const Routes = () => {
  return (
    <Suspense fallback={<HmPageLoading />}>
      <Switch>
        <Route exact path={HmUrl.login} component={Login} />
        <Route exact path={HmUrl.chooseAccount} component={ChooseAccount} />
        <Route exact path={HmUrl.passwordReset('resetpassword')} component={ResetPassword} />
        <Route exact path={HmUrl.passwordReset('new')} component={NewPassword} />
        <Route exact path={HmUrl.forgotPassword} component={ForgotPassword} />
        <Route exact path={HmUrl.notFound} component={Errors.NotFound} />
        <Route exact path={HmUrl.expiredPassword} component={ExpiredPassword} />
        <Route exact path={HmUrl.acceptTerms} component={Terms} />
        <Route exact path={HmUrl.verifyDevice} component={VerifyDevice} />
        <Route exact path={HmUrl.tourDashboard} component={TourDashboard} />
        <Route exact path={HmUrl.portal} component={ResidentPortal} />
        <Route exact path={HmUrl.home} component={Home} />

        <Route exact path={'/application/view/:guid'} component={AppViewRedirect}  />

        <Route
          path={[HmUrl.applications, '/applicationforms']}
          render={props => {
            return <Applications {...props} />;
          }}
        />

        <Route
          path={trimSlash(HmUrl.residents())}
          render={props => {
            return <Residents {...props} />;
          }}
        />

        <Route
          path={trimSlash(HmUrl.workOrders())}
          render={props => {
            return <WorkOrders {...props} />;
          }}
        />

        <Route
          path={trimSlash(HmUrl.units())}
          render={props => {
            return <Units {...props} />;
          }}
        />

        <Route
          path={[HmUrl.settings, HmUrl.about, HmUrl.myProfile]}
          render={props => {
            return <Settings {...props} />;
          }}
        />

        <Route
          path={[ HmUrl.auditing, HmUrl.billing(null), HmUrl.customers, HmUrl.enterpriseKeys, HmUrl.notifications ]}
          render={props => {
            return <Admin {...props} />;
          }}
        />

        <Route path={HmUrl.logout} component={Logout} />

        <Redirect to={HmUrl.notFound} />
      </Switch>
    </Suspense>
  );
};

function trimSlash(path) {
  return path.replace(/\/$/, '');
}

function AppViewRedirect() {
  const {guid} = useParams();

  const {data} = useAsync(viewApplication, {guid});
  if(data && data.instanceId){
    return <Redirect to={HmUrl.application(data.instanceId, data.applicationId)}/>;
  }

  return data === undefined ? <HmPageLoading /> : <Errors.NotFound />;
};