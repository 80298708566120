import React from 'react';
import { Redirect, Link } from 'react-router-dom';
import { useUserState } from '../../hooks/useUserState';
import { HmUrl } from '../../lib.app/routes';
import HmNav from '../nav';
import { useHashObserver } from 'hooks/useHashObserver';
import { Text, HmGuard, HmAlert } from 'components';
import { useCurrentWildcard } from 'hooks/useInstanceState';
import { useAsync } from 'react-async';
import { getWorkOrderSettings } from 'api/v1/workorders/settings';
import Portal from './Portal';

export const HousingManager = ({ pageTitle, className, search, nav, ...props }) => {
  const [user] = useUserState();
  useHashObserver();

  // don't copy to Splash.. or else endless loop
  if (user) {
    if (user.action === 'expired') return <Redirect to={HmUrl.expiredPassword} />;
    if (user.action === 'verify') return <Redirect to={HmUrl.verifyDevice} />
    if (user.action === 'choose') return <Redirect to={HmUrl.chooseAccount} />;
  }

  return (
    <>
      <Portal id="hmHeader">
        <div className="site-alert">
          <OnlineRentMisconfiguredAlert />
          <HmGuard access="misconfiguredMaintenanceReqeusts">
            <MaintenanceRequestAlert />
          </HmGuard>
        </div>
        <HmNav search={search} />
        <header className="w-100 position-sticky">{nav}</header>
      </Portal>
      <main id="hmMain">{props.children}</main>
    </>
  );
};

export const MaintenanceRequestAlert = () => {
  const wildcard = useCurrentWildcard();
  const { data } = useAsync(getWorkOrderSettings, { wildcard });
  if (!data) return null;
  if (data.emergencyPhoneNumber) return null;

  return (
    <HmAlert variant="warning" iconSize="3x" className="mb-0">
      <Text>
        Maintenance Requests cannot be submitted until an emergency phone number is provided.
        <br />
        <Link className="alert-link" to={HmUrl.instanceSettings(wildcard, 'workorders')}>
          Click Here
        </Link>{' '}
        to enter your emergency phone number. Contact support for assistance.{' '}
      </Text>
    </HmAlert>
  );
};

export const OnlineRentMisconfiguredAlert = () => {
  return (
    <HmGuard access="misconfiguredOnlineRent">
      <HmAlert variant="warning" iconSize="3x" className="mb-0">
        <Text>
          Your site is configured to take payments online but no merchant account has been set.
          <br />
          Contact support for assistance.{' '}
        </Text>
      </HmAlert>
    </HmGuard>
  );
};
