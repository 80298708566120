import { hasError } from "../hasError";
import { FieldStateInitializer } from "./index";
import { formatDate } from "lib.core/formatters";
import { textMask } from "lib.app/masks";

export function formatDateInitializer(format = 'MM/dd/yyyy', mask = true): FieldStateInitializer {
  let internalValue = '';
	return ({ value, touched, setter, setError, props: { ref, ...fieldProps } = { ref: undefined } }) => ({
		...fieldProps,
		value: (value && (value.length >= format.length || !touched) ? formatDate(value, format) : value) ||
			'',
		onChange: e => {
      internalValue = e.target.value;
      if (internalValue === '__/__/____') {
        setter('');
      } else if (!internalValue.includes('_')) {
        setter(formatDate(internalValue, format));
      }
		},
		onBlur: e => {
			if (e.target.willValidate)
				e.target.checkValidity();
			setError(hasError(e.target));
		},
		...textMask('date')
	});
}
