import React from 'react';
import { useUserState } from '../../hooks/useUserState';
import { NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { IconEmail, IconPhone, IconUser } from '../icons';
import { HmUrl } from '../../lib.app/routes';
import { useCurrentWildcard } from '../../hooks/useInstanceState';
import { HmGuard } from 'components';

export const UserSettings = (props) => {
  const wildcard = useCurrentWildcard();
  const [user] = useUserState();

  const title = (
    <>
      <IconUser /> {' '}
      {user.displayName}
    </>
  );

  return (
    <HmGuard access="authenticated">
      <NavDropdown title={title} id="userSettings" alignRight>
        <NavDropdown.Item as={Link} to={HmUrl.myProfile}>My Profile</NavDropdown.Item>
        <HmGuard access="companyUserAdmin" component={null}>
          <NavDropdown.Item as={Link} to={HmUrl.users}>Users</NavDropdown.Item>
          <NavDropdown.Item as={Link} to={HmUrl.instanceSettings(wildcard)}>Settings</NavDropdown.Item>
          <NavDropdown.Item as={Link} to={HmUrl.security}>Security</NavDropdown.Item>
        </HmGuard>
        <NavDropdown.Item as={Link} to={HmUrl.about}>About Housing Manager</NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Header>Contact Us</NavDropdown.Header>
        <NavDropdown.Item href="mailto:support@housingmanager.com">
          <IconEmail /> Email Us
        </NavDropdown.Item>
        <NavDropdown.Item href="tel:517-558-6523">
          <IconPhone />
          HousingManager.com: 855-979-0770
        </NavDropdown.Item>
        <NavDropdown.Divider />
        <NavDropdown.Item href={HmUrl.logout}>Sign Out</NavDropdown.Item>
      </NavDropdown>
    </HmGuard>
  );

};
