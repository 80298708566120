import React, { FunctionComponent } from "react";
import { useWindowDimensions } from "hooks/useWindowDimensions";
import { HmGuard } from "components";
import { Dropdown, ButtonToolbar } from "react-bootstrap";

export const ActionToolbar: FunctionComponent<any> = ({breakpoint = false, as: Group = ButtonToolbar, drop, alignRight, title='Actions', children, ...props}) => {
  const dim = useWindowDimensions();

  return (
    <HmGuard access={!breakpoint || dim.has(breakpoint)} component={(
      <Dropdown drop={drop} alignRight={alignRight} {...props}>
        <Dropdown.Toggle id="actions-dd">{title}</Dropdown.Toggle>
        <Dropdown.Menu>
          {children}
        </Dropdown.Menu>
      </Dropdown>
    )}>
      <Group {...props}>
        {children}
      </Group>
    </HmGuard>
  )
}