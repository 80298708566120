import React from 'react';
import bug from '@lindsey-software/housing-manager-ux/content/img/hm-bug.png';
import bugw from '@lindsey-software/housing-manager-ux/content/img/hm-bug-w.png';
import { ui } from '../lib.core';
import { useUserState } from 'hooks/useUserState';

export const HmBug = props => {
  const [user] = useUserState();
  return (
    <span className={ui.classNames('hm', { live: user.isLiveClientData })}>
      <img
        alt=""
        src={user.isLiveClientData ? bugw : bug}
        width="20"
        height="20"
        {...props}
      />
    </span>
  );
};
