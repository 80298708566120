import React, { FunctionComponent } from 'react';
import { Text } from 'components';
import { IconEmail } from './icons';
import { ContactProps } from './HmAddress';

export const HmEmail: FunctionComponent<
  Pick<ContactProps, 'email'> & {
    link?: boolean;
  }
> = ({ email, link = false, children = email }) => {
  const textProps: any = {
    as: link ? 'a' : 'span'
  };
  if (link) {
    textProps.href = `mailto:${email}`;
  }
  return (
    <Text {...textProps}>
      <IconEmail /> {children}
    </Text>
  );
};
