import { authHttp, processBadApiRequest } from './http';
import { handleNullResponse } from './utils';

export type StaticThisResource<T> = new(data: any, options?: any) => T;

export type ResourceGenerator = {create: (data: any, options?: any) => Resource; };

export type ResourceFactory = {
  create<T extends Resource>(
    this: StaticThisResource<T>,
    data: any,
    options?: any
  ): T
} | Resource
  | ResourceGenerator;

export interface IApiResource {
  isNew: boolean;
  readonly [key: string]: any;
  readonly url: string;
  readonly hasUrl: boolean;
  readonly body: any;
}

type KeyOfT<T> = Extract<keyof T, string>;
type PropsOfT<T> = {[P in KeyOfT<T>] : any};

export type ApiResource<T> =  PropsOfT<T> & {
  readonly url: string;
  readonly hasUrl: boolean;
  readonly body: T;
  get: () => Promise<ApiResource<T>>;
  query: (params ?: any) => Promise<Array<ApiResource<T>>>;
  save: () => Promise<ApiResource<T>>;
  delete: () => Promise<void>;
};

function resolveResource(factory) {
  if (!factory) {
    return Resource;
  }
  return factory;
}
// todo: figure out mocking for resource objects, also not having clear out exsiting resolvers :(
const resourceHttp = authHttp.resolve(
  (resolve, w) =>
    resolve
      .badRequest((res, w) => {
        res.json = processBadApiRequest(res.json);
        throw res.json;
      })
      .json(body => {
        const factory = resolveResource(w._options.resource);
        return Array.isArray(body) ?
          body.map(_ => factory.create(_, w._options.resourceOptions)) :
          factory.create(body, w._options.resourceOptions);
      }),
  true
);

export const withResource = (factory: ResourceFactory, options?: any) => {
  return resourceHttp.options({ resource: factory, resourceOptions: options });
};

export class Resource implements IApiResource {
  [key: string]: any;

  public get url(): string {
    return this.getUrl();
  }

  public get resourceUrl(): string {
    return this.resolveResourceUrl();
  }

  public get hasUrl(): boolean {
    return !!this.url;
  }

  public get body(): any {
    // tslint:disable-next-line: no-this-assignment
    const { _http, _links, _embedded, ...body } = this;
    return body;
  }
  public static baseUrl: string = '';
  public static relatedFactory: {};

  public static create<T extends Resource>(this: StaticThisResource<T>, data: any): T {
    const that = new this(data);
    return that;
  }

  public isNew: boolean = false;
  public _links: any = {};

  protected _http;

  constructor(data: any, options ?: any) {
    const {_embedded = {}, ...otherData} = data;
    Object.assign(this, otherData);

    for (const key in _embedded) {
      if (_embedded.hasOwnProperty(key)) {
        this[key] = this.createRelated(key, _embedded[key]);
      }
    }

    this._http = (url) => withResource(this.getFactory(), options).url(url);
  }

  public resourceHttp() {
    return this.http(this.resourceUrl);
  }

  public get() {
    return this.http(this.url).get();
  }

  public query(params?: any) {
    return this.http(this.resourceUrl).get(params);
  }

  public save() {
    const body = this.body;
    return this.hasUrl && body.isNew !== true
      ? this.http(this.url).put(body)
      : this.http(this.resourceUrl).post(body);
  }

  public delete() {
    return this.http(this.url).delete().catch(handleNullResponse);
  }

  public createRelated(key: string, data: any) {
    const factory = this.resolveFactory(key);
    if (Array.isArray(data)) {
      return data.map(d => new factory(d));
    }
    return factory.create(data);
  }

  protected getUrl(): string {
    return this._links && this._links.self && this._links.self.href;
  }

  protected resolveResourceUrl(): string {
    return Object.getPrototypeOf(this).constructor.baseUrl;
  }

  protected getFactory(): ResourceFactory {
    return Object.getPrototypeOf(this).constructor;
  }

  protected http(url: string) {
    url = url.replace('api/v1/', '');
    return this._http(url);
  }

  private resolveFactory(key) {
    const factory: any = this.constructor;
    if (factory.relatedFactory && factory.relatedFactory[key]) {
      return factory.relatedFactory[key];
    }
    return factory;
  }
}

export default resourceHttp;
