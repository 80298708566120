import { withMock, resourceHttp } from '../..';
import { HmRequestOptions } from '../../http';
import { ApiResource } from '../../resourceHttp';
import { handleNullResponse } from '../../utils';

const request = resourceHttp.url('/clientsettings');

export const getClientSettings = (options: HmRequestOptions = {}) =>
  withMock(request, options, () =>
    import(/* webpackChunkName: "mockApi" */ './clientsettings.mock.js')
  )
    .get()
    .then(data => data.clientsettings as Array<ApiResource<ClientSettings>>);

export const getClientSettingsDetail = ({wildcard, admin}, options: HmRequestOptions = {}) =>
  withMock(request, options, () =>
    import(/* webpackChunkName: "mockApi" */ './clientsettings.mock.js')
  )
    .url(admin ? request._url.replace('/clientsettings', '/lindseyemployeeclientsettings') : '', admin)
    .query({wildcard})
    .get();

export const saveClientSettings = ({wildcard, admin, values}, options: HmRequestOptions = {}) =>
  withMock(request, options, () =>
    import(/* webpackChunkName: "mockApi" */ './clientsettings.mock.js')
  )
    .url(admin ? request._url.replace('/clientsettings', '/lindseyemployeeclientsettings') : '', admin)
    .query({wildcard})
    .post(values)
    .catch(handleNullResponse);

export default {
  query: getClientSettings,
  get: getClientSettingsDetail,
  save: saveClientSettings
};

export type ClientSettings = {
  siteTheme: number;
  logoGuid: string;
  id: number;
  clientId: number;
  name: string;
  timeZoneId: number;
  wildcard: string;
  enableApplicationFee: boolean;
  allowApplicationFee: boolean;
  applicationFee: number;
  isLindseyCustomer: boolean;
  isHousingAuthority: boolean;
  phone1: string;
  phone1Label: string;
  phone2: string;
  phone2Label: string;
  email: string;
  allowIvrSyncing: boolean;
  isActive: boolean;
  goLiveDate: string;
  portalTitle?: string;
  contract?: string;
  contractType?: string;
};

export type AdminClientSettings = ClientSettings & {
  allowFraming: boolean;
  applicationFeeMerchantAccountSettings: MerchantAccountSettings | null;
  softwareId: string;
  applicationTypeId;
  housingManagerCutoverDate: Date | null;
  housingManagerCutoverDateOriginal: Date | null;
  showIvrPosition: boolean;
  waitingListReportsEnabled: boolean;
  shouldChargeHmConvenienceFee: boolean;
  residentRentMerchantAccountSettings: MerchantAccountSettings | null;
  payNearMeMerchantAccount: PayNearMeMerchantAccount | null;
};

export type MerchantAccountSettings = {
  merchantId ?: string;
  merchantKey ?: string;
  echeckTerminalId ?: number;
  accepts: number[]
};

export type PayNearMeMerchantAccount = {
  identifier: string;
  secretKey: string;
};
