import { clone } from '..';
import qs from 'qs';
import merge from '../merge';
import { isEquivalent } from '../isEquivalent';
import { compress, decompress } from 'lib.core/utils';


type FilterObj = {
  top: number,
  skip: number,
  sort: string;
}

export type HmFilter = {
  $filter: any;
  $top: number;
  $skip: number;
  $sort: string;
};

export default class Filter<T> {

  public get sort(): string {
    return this._sort;
  }

  public set sort(value) {
    this._sort = value;
  }

  public get sortField(): string {
    const [field] = this._sort ? this._sort.split(' ') : [''];
    return field;
  }

  public get top(): any {
    return this._top;
  }

  public set top(value: any) {
    this._top =  parseInt(value, 10);
    this.skip = 0;
  }

  public get skip(): any {
    return this._skip;
  }

  public set skip(value: any) {
    this._skip = parseInt(value, 10);
  }

  public get pageSize(): any {
    return this.top;
  }

  public get page(): number {
    return !this.hasPaging ? 1 : Math.floor(this.skip / this.top) + 1;
  }

  public get hasPaging(): boolean {
    return this.totalCount > 0 && this.top > 0;
  }

  public get data(): any {
    return this._data;
  }

  public static emptyStringToNull = (name: string, val: any): any | undefined => {
    if (typeof val === 'string' && val.length === 0) {
      return null;
    }
    return val;
  }

  public static removeEmpty = (name: string, val: any): any | undefined => {
    if (val === undefined || val === null || val === '' || (Array.isArray(val) && val.length === 0)) {
      return undefined;
    }
    return val;
  }

  public static removePaging = (name: string, val: any): any | undefined => {
    if (name === '$top' || name === '$skip') return undefined;
    return Filter.removeEmpty(name, val);
  }

  public value: T;
  public initialValue: T;
  public totalCount: number = 0;

  private _data: any;

  private _top: number;
  private _skip: number;
  private _sort: string;

  constructor(value: T, skip: number = 0, top: number = 25) {
    this.initialValue = this.value = clone(value);
    this._skip = skip;
    this._top = top;
    this._sort = '';
  }

  public setData(data: any, count: any) {
    this._data = data;
    this.totalCount = typeof count === 'function' ? count(data) : count;
  }

  public getSortDir(field = this.sortField)  {
    return this._sort ? this._sort.replace(field, '').trim().toLowerCase() : null;
  }

  public toggleSort(field = this.sortField) {
    const dir = this.getSortDir(field);
    this.sort = dir !== 'asc' ? `${field} asc` : `${field} desc`;
  }

  public setPageProperties(page: number, pageSize?: number) {
    this.top = pageSize || this.top;
    this.skip = (page - 1) * this.top;
  }

  public getObject(): T & FilterObj {
    return merge<any>({}, this.value, { top: this.top, skip: this.skip, sort: this.sort });
  }

  public getQsValue(): string {
    return `$filter=${compress(this.getPersistedValue())}`;
  }

  public parseQsValue(qsValue: string) {
    const { $filter } : any = qs.parse(qsValue, { ignoreQueryPrefix: true});
    this.setPersistedValue(decompress($filter));
  }

  public getPersistedValue(): string {
    return JSON.stringify(this.getObject());
  }

  public setPersistedValue(persistedValue: string) {
    const { top, skip, sort, ...value } = JSON.parse(persistedValue);
    this.setValue(value);
    this.top = top;
    this.skip = skip;
    this.sort = sort;
  }

  public reset() {
    this.setValue(this.initialValue, true);
    this.skip = 0;
  }

  public setValue(value: T, replace = true) {
    this.value = replace === true ? clone(value) : clone(this.initialValue, this.value, value);
  }

  public asHmFilter(replacer ?: (name: string, val: any) => any | undefined, force: boolean = false) {
    const filter = {} as HmFilter;
    if (force || !isEquivalent(this.value, this.initialValue)) {
      filter.$filter = JSON.stringify(this.value, replacer);
    }
    if (this.top !== 25) {
      filter.$top = this.top;
    }
    if (this.skip !== 0) {
      filter.$skip = this.skip;
    }
    if (this.sort) {
      filter.$sort = this.sort;
    }
    return filter;
  }

  public asHmFilterOnly() {
    return this.asHmFilter(Filter.removePaging, true);
  }
}
