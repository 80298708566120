import { useContext } from 'react';
import { ClientContext } from '../components/clients/ClientContext';

export function useClientContext() {
  const context = useContext(ClientContext);
  if (!context) {
    throw new Error('Client Context not found.');
  }
  return context;
}
