import React, { FunctionComponent } from 'react';
import { Text, Heading } from 'components';

export const HmMetric: FunctionComponent<any> = ({ children, metric, measure = false, ...props }) => {
  return (
    <Text container className="metric">
      <Text variant="muted" className="mb-1">{children}</Text>
      <Heading variant="list">
        {metric}{' '}
        {measure && <Text as="small" variant="muted" className="text-uppercase" inline>
          {measure}
        </Text>}
      </Heading>
    </Text>
  );
};
