import { authHttp, withMock, HmRequestOptions } from '../../http';

export interface ChangePasswordRequest {
  token: string;
  password: string;
  passwordConfirm: string;
}

const http = authHttp.url('/auth/expired');
export const expiredPassword = (
  request: ChangePasswordRequest,
  options: HmRequestOptions = {}
) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  ).post(request);
