import { authHttp, withMock, HmRequestOptions } from '../../http';

const http = authHttp.url('/auth/terms');

export const acceptTerms = (userId: number, options: HmRequestOptions = {}) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  )
  .query({userId})
  .post();
