import React, { useReducer, useEffect, useMemo } from 'react';
import { clone, merge } from './lib.core';
import { Roles } from 'lib.app/enums';
import { HmTimeZoneId } from 'lib.app/miscData/timeZones';
import { getInstanceConnections } from 'api/v1/connect/config';

export type UserAction = 'unauthorized' | 'login' | 'verify' | 'init' | 'terms' | 'choose' | 'expired' | 'logout';

export declare type HmUser = {
  username: string;
  displayName: string;
  email: string;
  userId: number;
  clientId: number;
  roles: Roles[];
  instanceRoles: any;
  isActive: boolean;
  hasExpiredPassword: boolean;
  hasAcceptedTerms: boolean;
  hasVerifiedDevice: boolean;
  isLoggedIn: boolean;
  hasClient: boolean;
  isLiveClientData: boolean;
  ipAddress: string;
  isTrialUser: boolean;
  action: UserAction;
  returnUrl?: string;
  requireRecaptcha: boolean;
};

export declare type HmClient = {
  clientId: number;
  clientName: string;
  city: string;
  state: string;
  hasInstance: boolean;
  accountStatus: string;
};

export declare type HmInstance = {
  instanceId: number;
  accountStatusId: number;
  accountStatus: string;
  wildcard: string;
  daysRemainingOnTrial: number;
  applications: boolean;
  applicantFacingWebSite: boolean;
  advancedEligibilityRules: boolean;
  advancedOpeningTypes: boolean;
  applicantCanEditApplication: boolean;
  multiLingual: boolean;
  autoRenewDrop: boolean;
  checkList: boolean;
  customQuestionsAndPreferences: boolean;
  electronicFees: boolean;
  waitingListManagement: boolean;
  multipleLotteryDraws: boolean;
  isLegacyBilling: boolean;
  ivr: boolean;
  assignApplicationToUser: boolean;
  residents: boolean;
  enhancedApplicationSecurity: boolean;
  onlineRentCollection: boolean;
  onlineRentCollectionIsMisconfigured: boolean;
  residentPortal: boolean;
  freeAppsWithFee: boolean;
  applicationMerchantAccountIsSet: boolean;
  applicationFeeIsMisconfiguredForFreeApps: boolean;
  onlineMaintenanceRequests: boolean;
  onlineWorkOrders: boolean;
  meterReader: boolean;
  fixedAssets: boolean;
  mobileWorkOrders: boolean;
  inventory: boolean;
};

export declare type HmSettings = {
  allowFraming: boolean;
  applicationFeeMerchantAccountSettings: null;
  softwareId: string | null;
  applicationTypeId: number;
  isHousingAuthority: boolean;
  housingManagerCutoverDate: null;
  housingManagerCutoverDateOriginal: null;
  showIvrPosition: boolean;
  waitingListReportsEnabled: boolean;
  shouldChargeHmConvenienceFee: boolean;
  residentRentMerchantAccountSettings: null;
  payNearMeMerchantAccount: {
    identifier: string;
  };
  siteTheme: number;
  logoGuid: string;
  id: number;
  clientId: null;
  name: string;
  timeZoneId: HmTimeZoneId;
  wildcard: string;
  enableApplicationFee: boolean;
  allowApplicationFee: boolean;
  applicationFee: number;
  isLindseyCustomer: boolean;
  phone1: string;
  phone1Label: string;
  phone2: string;
  phone2Label: string;
  email: string;
  allowIvrSyncing: boolean;
  isActive: boolean;
  goLiveDate: Date;
  portalTitle?: string;
  contract?: string;
  contractType?: string;
};

export interface HmApplicationState {
  user: HmUser;
  instance: HmInstance;
  client: HmClient;
  settings: HmSettings;
  connections: HmConnections;
  isLoading: boolean;
  reload: () => Promise<any>;
  dispatch: (value: any) => any;
}

export interface HmConnectionConfig<T = any> {
  connectionId: string;
  config: T;
}

export type HmConnections = {
  [key: string]: HmConnectionConfig;
}

export const initialState: HmApplicationState = {
  user: { roles: [] as any, instanceRoles: [] as any} as HmUser,
  instance: {} as HmInstance,
  client: {} as HmClient,
  settings: {} as HmSettings,
  connections: {} as HmConnections,
  isLoading: false,
  reload: () => Promise.resolve<any>({}),
  dispatch: value => value
};

export const reducer = (state: any, action: any) => {
  switch (action.type) {
  case updateUser.action: {
    return updateUser(state, action.payload);
  }
  case updateClient.action: {
    return updateClient(state, action.payload);
  }
  case updateInstance.action: {
    return updateInstance(state, action.payload);
  }
  case updateSettings.action: {
    return updateSettings(state, action.payload);
  }
  case initializeAction.action: {
    return initializeAction(state, action.payload);
  }
  default:
    return typeof(action.type === 'function') ? action.type(state, action.value) :  state;
  }
};

export function initializeAction(state, data) {
  let initState = clone(initialState, state);
  if (!data) return initState;
  const { client, settings, connections, ...user } = data;
  initState = updateUser(initState, { ...user, isLoggedIn: user.userId > 0, action: 'init' });
  initState = updateClient(initState, client);
  initState = updateSettings(initState, settings);
  initState.connections = connections;
  return initState;
}
initializeAction.action = 'INIT';

export function updateUser(state, value: HmUser) {
  const {user} = state;
  if (!value || !value.username) return state;
  const newUser = clone(user);
  newUser.roles = [];
  newUser.instanceRoles = [];
  value = merge(newUser, value);
  value.action = getNextActionFromUser(value, value.action);
  analytics.identify(''+value.userId, {
    name: value.displayName,
    email: value.email,
    clientId: value.clientId,
    isTrialAccount: value.isTrialUser
  });
  return {...state, user: value };
}
updateUser.action = 'UPDATE_USER';

const getNextActionFromUser = (user: HmUser, action ?: UserAction): UserAction => {
  if (user.hasAcceptedTerms === false) {
    return 'terms';
  }
  if (user.hasExpiredPassword === true) {
    return 'expired';
  }
  if (user.hasVerifiedDevice === false) {
    return 'verify';
  }
  if (user.hasClient === false) {
    return 'choose';
  }

  return action || 'login';
}

export function updateClient(state, value) {
  if (!value || !value.clientId) return state;
  const { firstInstanceFeatures, _links, _http, _embedded, ...client } = value;
  const newClient: any = {
    client,
    user: { hasClient: client.clientId > 0 }
  };
  if (firstInstanceFeatures) {
    newClient.instance = firstInstanceFeatures;
    if (!firstInstanceFeatures.wildcard) {
      newClient.instance.wildcard = 'none';
    }
    newClient.client.accountStatus = firstInstanceFeatures.accountStatus;
  }
  if (client.clientId) {
    analytics.group('' + client.clientId, { name: client.name });
  }

  return clone(state, newClient);
}
updateClient.action = 'UPDATE_CLIENT';

export function updateInstance(state, value) {
  if (!value || !value.instanceId) return state;
  return clone(state, { instance: value });
}
updateInstance.action = 'UPDATE_INSTANCE';

export function updateSettings(state, value) {
  if (!value || !value.id) return state;
  const { _http, _embedded, ...settings } = value;
  return clone(state, { settings });
}
updateSettings.action = 'UPDATE_SETTINGS';

export function refreshConnections(state: HmApplicationState, value) {
  const { dispatch} = state;

  getInstanceConnections({wildcard: state.instance.wildcard}).then(c => {
    dispatch({ type: replaceState, value: { at:'connections', ...c } });
  });

  // by returning state, we should avoid any updates ... until we call dispatch in the promise.
  return state;
}
refreshConnections.send = () => ({ type: refreshConnections, value: true});



function replaceState(state: HmApplicationState, {at, ...value}: any) {
  // just replace the entire state graph (careful with this)
  if (!at) {
    return value;
  }

  var replacement = { [at]: value };

  return {...state, ...replacement };
}

export const HmContext = React.createContext(initialState);

export const HmContextProvider = ({ value, reload, children }) => {
  const [state, dispatch] = useReducer(reducer, clone(initialState, value));

  state.dispatch = dispatch;
  useEffect(() => dispatch({type: replaceState, value: state}), []);


  return useMemo(() => (
    <HmContext.Provider value={{...state, reload, dispatch}}>{children}</HmContext.Provider>
  ), [state, reload]);
};
