export { RecipientType } from './RecipientType';
export { NoteType } from './NoteType';
export { PhoneType } from './PhoneType';
export { Housekeeping } from './Housekeeping';
export { ApplicationTwoFactorAuth } from './ApplicationTwoFactorAuth';
export { HmFeatures } from './HmFeatures';
export { Roles } from './Roles';


export function getEnumOptions<T extends object>(enumLike: T, valueKey = 'key') {
  return Object.keys(enumLike).filter(k => typeof enumLike[k] === 'number').map(k => ({[valueKey]: enumLike[k], label: k}));
}
