import { ReactElement, ReactNode } from 'react';
import { clone } from 'lib.core';
import { HmApplicationState, HmInstance, HmSettings } from 'HmContext';
import { featureGuards } from './';
import { Roles, HmFeatures } from 'lib.app/enums';

export type GuardContext = HmApplicationState & { instances: HmInstance[], clients: HmSettings[] }

type CheckType = 'any' | 'all' | 'not';
export interface HmGuardParams {
  roles?: Roles | Array<Roles | Pick<HmGuardParams, 'roles' | 'check'>>;
  check?: CheckType;
  features?: HmFeatures | HmFeatures[];
  user?: boolean | string;
}

export interface HmGuardProps extends HmGuardParams {
  to?: string;
  component?: ReactNode | null;
}

export interface HmAccessHelper {
  withNoContent: (key: HmAccessKey) => HmGuardProps;
  withRedirect: (key: HmAccessKey, to: string) => HmGuardProps;
  withFallback: (key: HmAccessKey, component: ReactElement) => HmGuardProps;
}
export interface HmAccessMap extends HmAccessHelper {
  accounting: HmGuardProps;
  accountingReports: HmGuardProps;
  activateGracePeriod: HmGuardProps;
  addCharge: HmGuardProps;
  addPayment: HmGuardProps;
  administerAccount: HmGuardProps;
  advancedEligibilityRules: HmGuardProps;
  advancedOpeningTypes: HmGuardProps;
  applications: HmGuardProps;
  applicationsAnyInstance: HmGuardProps;
  assignApplicationToUser: HmGuardProps;
  auditing: HmGuardProps;
  authenticated: HmGuardProps;
  autoRenewDrop: HmGuardProps;
  billing: HmGuardProps;
  billingPackages: HmGuardProps;
  clientOnly: HmGuardProps;
  companyBilling: HmGuardProps;
  companyConfig: HmGuardProps;
  companyUserAdmin: HmGuardProps;
  companyUserOrGreater: HmGuardProps;
  customers: HmGuardProps;
  customQuestionsAndPreferences: HmGuardProps;
  discountPackages: HmGuardProps;
  devEnvironment: HmGuardProps;
  developerTool: HmGuardProps;
  editApplications: HmGuardProps;
  employeesOnly: HmGuardProps;
  enhancedApplicationSecurity: HmGuardProps;
  enterpriseKeys: HmGuardProps;
  issueCredits: HmGuardProps;
  lindseyCustomers: HmGuardProps;
  maintenanceSettings: HmGuardProps;
  manageAccounting: HmGuardProps;
  managePackages: HmGuardProps;
  managePackagesEdit: HmGuardProps;
  misconfiguredMaintenanceReqeusts: HmGuardProps;
  misconfiguredOnlineRent: HmGuardProps;
  nonLindseyInstances: HmGuardProps;
  notifications: HmGuardProps;
  onlineCustomerAdmin: HmGuardProps;
  onlineCustomerCompanyUserOrGreater: HmGuardProps;
  onlineCustomersOnly: HmGuardProps;
  onlineMaintenace: HmGuardProps;
  onlineRentCollection: HmGuardProps;
  readOnly: HmGuardProps;
  remittance: HmGuardProps;
  residentPortal: HmGuardProps;
  residents: HmGuardProps;
  residentsAnyInstance: HmGuardProps;
  roleManager: HmGuardProps;
  terminatePackage: HmGuardProps;
  trialCustomer: HmGuardProps;
  unitsAnyInstance: HmGuardProps;
  volumeUnlimited: HmGuardProps;
  waitingListManagement: HmGuardProps;
  workOrders: HmGuardProps;
  workOrdersAnyInstance: HmGuardProps;
}

export type HmAccessKey = keyof Pick<HmAccessMap, Exclude<keyof HmAccessMap, keyof HmAccessHelper>>;

export const HmAccess: HmAccessMap = {
  accounting: {
    roles: [Roles.Accounting],
    component: null,
  },
  accountingReports: {
    roles: [Roles.Accounting, Roles.ReportViewer],
    check: 'all',
    component: null,
  },
  activateGracePeriod: {
    roles: [Roles.ActivateGracePeriod],
    component: null
  },
  addPayment: {
    roles: [Roles.CreatePayment],
    component: null,
  },
  addCharge: {
    roles: [Roles.Accounting, Roles.AdminSales],
    component: null
  },
  administerAccount: {
    roles: [Roles.Accounting, Roles.AdminSales, Roles.IssueCredits, Roles.CreatePayment],
    component: null
  },
  authenticated: {
    user: true
  },
  autoRenewDrop: {
    features: HmFeatures.ApplicationAutoRenewDrop,
    component: null
  },
  applications: {
    features: HmFeatures.Applications,
    user: true
  },
  applicationsAnyInstance: {
    features: HmFeatures.CustomerApplications,
    component: null,
  },
  assignApplicationToUser: {
    features: HmFeatures.AssignApplicationToUser,
    roles: [Roles.CompanyUser, Roles.CompanyAdmin, Roles.LindseyEmployee],
    component: null
  },
  auditing: {
    roles: Roles.LindseyEmployee
  },
  billing: {
    roles: [Roles.Accounting, Roles.Packages],
  },
  billingPackages: {
    roles: [Roles.Packages],
    component: null
  },
  clientOnly: {
    roles: Roles.LindseyEmployee,
    check: 'not',
    component: null,
  },
  companyBilling: {
    features: HmFeatures.InstanceBillingContact,
    component: null,
  },
  companyConfig: {
    features: [HmFeatures.Residents, HmFeatures.WordOrders],
    component: null,
    check: 'any'
  },
  companyUserAdmin: {
    user: true,
    roles: [Roles.CompanyAdmin, Roles.LindseyEmployee],
  },
  companyUserOrGreater: {
    user: true,
    roles: [Roles.CompanyUser, Roles.CompanyAdmin, Roles.LindseyEmployee],
  },
  customQuestionsAndPreferences: {
    features: HmFeatures.ApplicationCustomQuestionsAndPreferences,
    component: null
  },
  customers: {
    roles: Roles.LindseyEmployee,
  },
  discountPackages: {
    roles: Roles.LindseyEmployee,
    component: null
  },
  devEnvironment: {
    features: HmFeatures.DevEnvironment,
  },
  developerTool: {
    roles: Roles.Developer
  },
  editApplications: {
    features: HmFeatures.ApplicationEdit,
    component: null
  },
  advancedEligibilityRules: {
    features: HmFeatures.ApplicationAdvancedEligibilityRules,
    component: null
  },
  advancedOpeningTypes: {
    features: HmFeatures.ApplicationAdvancedOpeningTypes,
    component: null
  },
  employeesOnly: {
    user: true,
    roles: Roles.LindseyEmployee,
    component: null,
  },
  enhancedApplicationSecurity: {
    features: HmFeatures.ApplicationTwoFactorAuth,
    component: null,
  },
  enterpriseKeys: {
    roles: Roles.LindseyEmployee
  },
  issueCredits: {
    roles: Roles.IssueCredits,
    component: null,
  },
  lindseyCustomers: {
    features: HmFeatures.LindseySoftwareOnly,
    component: null
  },
  maintenanceSettings: {
    features: [HmFeatures.MaintenanceRequests, HmFeatures.WordOrders],
    check: 'any',
    component: null
  },
  manageAccounting: {
    roles: [Roles.Accounting, Roles.Administrator],
    component: null,
  },
  managePackages: {
    roles: [Roles.Packages, Roles.Administrator], // * le sigh *, super admin required by api
    component: null,
  },
  managePackagesEdit: {
    roles: [Roles.SuperAdmin], // * le sigh *, super admin required by api
    component: null,
  },
  misconfiguredMaintenanceReqeusts: {
    features: HmFeatures.MisconfiguredMaintenanceReqeusts,
    component: null,
  },
  misconfiguredOnlineRent: {
    features: HmFeatures.MisconfiguredOnlineRent,
    component: null,
  },
  nonLindseyInstances: {
    features: HmFeatures.SomeOnlineInstances,
    component: null,
  },
  onlineMaintenace: {
    features: [HmFeatures.MaintenanceRequests],
    component: null
  },
  onlineCustomersOnly: {
    features: HmFeatures.OnlineCustomersOnly,
    component: null
  },
  onlineCustomerAdmin: {
    features: HmFeatures.OnlineCustomersOnly,
    roles: [Roles.LindseyEmployee, Roles.CompanyAdmin],
    component: null,
  },
  onlineCustomerCompanyUserOrGreater: {
    features: HmFeatures.OnlineCustomersOnly,
    roles: [Roles.CompanyUser, Roles.CompanyAdmin, Roles.LindseyEmployee],
    component: null,
  },
  onlineRentCollection: {
    features: HmFeatures.OnlineRentCollection
  },
  notifications: {
    roles: Roles.LindseyEmployee
  },
  readOnly: {
    features: HmFeatures.ReadOnly,
  },
  remittance: {
    roles: Roles.Accounting
  },
  residents: {
    features: HmFeatures.Residents,
    user: true
  },
  residentsAnyInstance: {
    features: HmFeatures.CustomerResidents,
    user: true
  },
  residentPortal: {
    features: HmFeatures.Residents,
    component: null,
  },
  roleManager: {
    features: HmFeatures.RoleManager,
    component: null,
  },
  terminatePackage: {
    roles: Roles.LindseyEmployee,
    component: null
  },
  trialCustomer: {
    features: HmFeatures.Trial,
    component: null
  },
  workOrders: {
    features: HmFeatures.WordOrders,
    component: null,
    user: true
  },
  workOrdersAnyInstance: {
    features: HmFeatures.CustomerWordOrders,
    component: null,
    user: true
  },
  unitsAnyInstance: {
    features: HmFeatures.CustomerUnits,
    component: null,
    user: true
  },
  volumeUnlimited: {
    roles: Roles.LindseyEmployee,
    component: null,
  },
  waitingListManagement: {
    features: HmFeatures.WaitingListManagement,
  },
  withNoContent: (key: HmAccessKey): HmGuardProps => {
    return clone(HmAccess[key], { component: null }) as HmGuardProps;
  },
  withRedirect: (key: HmAccessKey, to: string): HmGuardProps => {
    const {component, ...guard} = clone(HmAccess[key], { to, component: false }) as HmGuardProps;
    return guard;
  },
  withFallback: (key: HmAccessKey, component: ReactElement): HmGuardProps => {
    return clone(HmAccess[key], { component }) as HmGuardProps;
  }
};

export function guard(params: HmGuardParams | boolean, context: GuardContext): boolean {
  if (params === true || params === false) return !params;
  if (params === undefined) return false;
  const failedUser = guardUser(params, context);
  const failedFeatures = guardFeatures(params, context);
  const failedRoles = guardRoles(params, context);

  return failedUser || failedFeatures || failedRoles;
}

type GuardFn = (params: HmGuardParams, context: GuardContext) => boolean;

const guardUser: GuardFn = ({user: authUser = false}, {user}) => {
  const guard = authUser === true || typeof authUser === 'string';
  const failed = guard && (!user || !user.isLoggedIn);
  return typeof authUser === 'string' ? !failed && user.username !== authUser : failed;
};

const guardFeatures: GuardFn = ({features = [], check = 'all'}, context) => {
  features = Array.isArray(features) ? features : [features];
  const guarded = features.map(f => {
    const fg = featureGuards.get(f) as any;
    // tslint:disable-next-line: no-console
    if (!fg) { console.warn(`${HmFeatures[f]} is not defined`); return false; }
    return fg(context);
  });
  return test(guarded, check);
};

const guardRoles: GuardFn = ({roles = [], check = 'any'}, {user, ...context}) => {
  roles = Array.isArray(roles) ? roles : [roles];
  const guarded = roles.map(r => {
    if (!user.roles) return false;
    if (typeof r === 'object') return guardRoles(r, {user, ...context});
    return user.roles.includes(r);
  });
  return test(guarded, check);
};

function test(values: boolean[], check: CheckType) {
  if (values.length === 0) return false;
  const failed  = check === 'all' ? values.some(v => !v) : values.every(v => !v);
  return check === 'not' ? !failed : failed;
}

export enum AccountStatus {
  ClosedWithBalance = -101,
  Closed = -100,
  Active = 100,
  Trial = 200,
  LSS = 300,
}
