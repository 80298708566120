import React from 'react';
import { Layout, Heading, Text } from 'components';

export const NotFound = () => {
  return (
    <Layout name="error" pageTitle="Not Found">
      <Heading variant="error">Not Found!</Heading>
      <Text>The page you were looking for does not exist or has been moved.</Text>
    </Layout>
  );
};

export const ServerFault = ({children}) => {
  return (
    <Layout name="error" pageTitle="Server Error">
      {children || <Heading variant="error">Something went wrong!</Heading>}
    </Layout>
  );
};
