import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { PageAlert } from 'components/HmAlert';
import { Heading } from 'components';

export const ContentSimple = props => {
  return (
    <Container fluid className="main-content">
      <PageAlert name="page" />

      <Row>
        <Col>{props.children}</Col>
      </Row>
    </Container>
  );
};

export const ContentChild = ({heading, pageTitle, ...props}) => (
  <>
    {heading && <Heading variant="main">{heading}</Heading>}
    {props.children}
  </>
);
