import { authHttp, withMock, HmRequestOptions } from '../../http';
import { Resource } from '../..';

const http = authHttp.url('/auth/my');

export const myStatus = (options: HmRequestOptions = {}) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  ).get();

const myStatusMapper = data => {

  const client = data.client ? new Resource(data.client) : null;
  const settings = data.settings ? new Resource(data.settings) : null;
  return {
    ...data,
    client,
    settings
  };
};

export const myStatusInit = (options: HmRequestOptions = { mock: { statusText: 'INIT' }}) =>
  withMock(http.url('/init'), options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  )
  .get()
  .then(myStatusMapper);

export const myStatusClient = (clientId: number, instanceId: number | null  = null, options: HmRequestOptions = { mock: { statusText: 'CLIENT' } }) =>
  withMock(http.url('/client'), options, () =>
    import(/* webpackChunkName: "mockApi" */ './my.mock.json')
  )
  .post({ clientId, instanceId })
  .then(myStatusMapper);
