import { authHttp, withMock, HmRequestOptions } from '../../http';

export interface ForgotPasswordReqeust {
  username: string;
}

const http = authHttp.url('/auth/forgot');
export const forgotPassword = (
  request: ForgotPasswordReqeust,
  options: HmRequestOptions = {}
) =>
  withMock(http, options, () =>
    import(/* webpackChunkName: "mockApi" */ './forgot.mock.json')
  )
  .post(request);
