import http, { authHttp, withMock, getHttpConfig, setHttpConfig, HmRequestOptions } from './http';
import resourceHttp, { Resource, withResource } from './resourceHttp';
import { Ok200, noContentMock, handleNullResponse, OkFalse, OkTrue } from './utils';

export type InstanceParams = {
  wildcard: string;
}

export type DetailRequest<T = any> = {
  values: T;
}

export type InstanceDetailRequest<T = any> = DetailRequest<T> & InstanceParams;


export type HmApiRequestGet<R = any> = (options?: HmRequestOptions) => Promise<R>;
export type HmApiRequest<T = any, R = any> = (params: T, options?: HmRequestOptions) => Promise<R>;
export type HmInstanceApiRequest<T extends InstanceParams = InstanceParams, R = any> = (params: T, options?: HmRequestOptions) => Promise<R>;

export {
  authHttp,
  http,
  resourceHttp,
  Resource,
  withMock,
  withResource,
  getHttpConfig,
  setHttpConfig,
  Ok200,
  OkTrue,
  OkFalse,
  noContentMock,
  handleNullResponse,
};

export default authHttp