import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import { HmUrl } from 'lib.app/routes';
import { IconQuestion, IconInfoCircle } from '../icons';
import { useTour } from 'areas/tour/hooks/useTour';
import { useGuard } from 'hooks/useGuard';
import { HmGuard } from 'components/security/HmGuard';

export const Help = props => {
  const guarded = useGuard('companyUserAdmin');
  const { isActive } = useTour();
  const title = <>{isActive ? <IconInfoCircle className="text-info" /> : <IconQuestion />} Help</>;
  return (
    <HmGuard access="authenticated">
      <NavDropdown title={title} id="basic-nav-dropdown" alignRight>
        {!guarded && (
          <>
            <NavDropdown.Item as={Link} to={HmUrl.tourDashboard}>
              Getting Started Guide
            </NavDropdown.Item>
            <NavDropdown.Divider />
          </>
        )}
        <NavDropdown.Item href="https://my.housingmanager.com/Content/other/HousingManager.com%20Manual.pdf">
          Download User Manaul
        </NavDropdown.Item>
      </NavDropdown>
    </HmGuard>
  );
};
