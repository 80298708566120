import React, { FunctionComponent, useMemo, forwardRef, useEffect } from 'react';
import { default as ReactSelect } from 'react-select';
import Async from 'react-select/async';
import { useFieldApi } from '../../hooks/useFormFieldState';
import { ui, isEquivalent } from '../../lib.core';
import { FormField } from '.';
import { FormFieldProps } from './FormField';
import { useAsync } from 'react-async';
import { HmAlert, ExceptionMessage } from '../HmAlert';
import { FieldStateInitializer } from 'hooks/formUtils';

// adapts react-select for use in FormField
export const HmSelect = forwardRef(({disabled = false, isDisabled = disabled, isInvalid, className, ...props }: any, ref) => {
  return (
    <ReactSelect
      ref={ref}
      isDisabled={isDisabled}
      styles={{ menuPortal: base => ({ ...base, zIndex: 10001 }) }}
      theme={theme => ({
        ...theme,
        borderRadius: 4
      })}
      menuPortalTarget={document.body}
      className={ui.classNames(className, { 'reactselect-is-invalid': isInvalid })}
      {...props}
    />
  );
});

export const HmAsyncSelect = forwardRef(({disabled = false, isDisabled = disabled, isInvalid, className, ...props }: any, ref) => {
  return (
    <Async
      ref={ref}
      isDisabled={isDisabled}
      styles={{ menuPortal: base => ({ ...base, zIndex: 10001 }) }}
      theme={theme => ({
        ...theme,
        borderRadius: 4
      })}
      menuPortalTarget={document.body}
      className={ui.classNames(className, { 'reactselect-is-invalid': isInvalid })}
      {...props}
    />
  );
});

interface ReactSelectProps {
  options?: any[];
  isLoading?: boolean;
  isClearable?: boolean;
  isMulti?: boolean;
  getOptionLabel?: (opt: any) => any;
  getOptionValue?: (opt: any) => any;
  formatGroupLabel?: (opt: any) => any;
  defaultValue?: any;
  loadOptions?: any;
  onChange?: (opt: any) => void;
  components?: any;
  defaultOptions?: any;
  inputValue?: string;
  defaultInputValue ?: string;
  filterOption ?: (candidate: any, value: any) => boolean;
}

interface SelectProps extends ReactSelectProps {
  promiseFn?: (args: any) => Promise<any>;
  watchFn?: (cur, prev) => boolean;
  params?: any;
  selectAll?: boolean;
  filter ?: (option: any) => boolean;
}

type SelectComponentProps = SelectProps & FormFieldProps;

// see this for style info
// https://react-select.com/styles#styles

export function initializeReactSelect({
  getOptionValue = opt => opt,
  onChange,
  isLoading = false,
  defaultValue,
  isMulti
}: ReactSelectProps): FieldStateInitializer {
  function getOptionOrDefault(option, values) {
    return option && getOptionValue(option) === values;
  }

  function getOptionOrDefaultMulti(option, selected) {
    return Array.isArray(selected) && selected.some(v => getOptionValue(option) === v);
  }

  function selectValues(isMulti, options, value) {
    if (!options) return options;
    // handle grouping
    if (options && options.length && options[0].options) options = options.reduce((a, o) => [...a, ...o.options], []);
    if (isMulti) {
      if (!value) value = defaultValue || [];
      const arr = options.filter(o => {
        const ov = getOptionValue(o);
        return value.some(v => isEquivalent(o, v, ['_http', '_link']) || ov === v);
      });
      return arr;
    } else {
      const o = options.find(o => isEquivalent(o, value, ['_http', '_link']) || value === getOptionValue(o));
      return o;
    }
  }

  return ({
    value,
    setter,
    setError,
    props: { ref, disabled: isDisabled, options, loadOptions, ...fieldProps }
  }) => {
    const v = selectValues(isMulti, options, value);
    return {
      value: loadOptions ? undefined : v || defaultValue || null,
      isDisabled,
      loadOptions,
      dependencies: [isDisabled, options, isLoading],
      options,
      ...fieldProps,
      defaultValue: defaultValue || v,
      getOptionValue,
      isOptionSelected: isMulti ? getOptionOrDefaultMulti : getOptionOrDefault,
      isMulti,
      onChange: (value: any) => {
        onChange && onChange(value);
        if (value === null || value === undefined) setter(isMulti ? [] : null, isMulti);
        else setter(isMulti ? value.map(getOptionValue) : getOptionValue(value), isMulti);
        setError(false); // clear errors set outside of the control
      }
    };
  };
}

export const AsyncSelect: FunctionComponent<SelectComponentProps> = ({
  onChange,
  getOptionValue = _ => _,
  isMulti = false,
  selectAll = false,
  ...props
}) => {
  return (
    <FormField
      fieldType={{
        initializer: initializeReactSelect({
          onChange,
          getOptionValue,
          isMulti
        }),
        component: HmAsyncSelect
      }}
      {...props}
    />
  );
};

export const Select: FunctionComponent<SelectComponentProps> = ({
  name,
  onChange,
  options = [],
  promiseFn = () => Promise.resolve(options),
  watchFn,
  params,
  getOptionValue = _ => _,
  isMulti = false,
  selectAll = false,
  defaultValue,
  filter,
  ...props
}) => {
  const api = useFieldApi(name);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optionsPromise = useMemo(() => promiseFn, [options.length, params]);
  const { data, error, isLoading } = useAsync<any>({ promiseFn: optionsPromise, watchFn, ...params });

  useEffect(() => {
    if (data && !isLoading && selectAll) {
      api.setValue(data.map(getOptionValue), true);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, selectAll]);

  if (error) {
    return (
      <HmAlert>
        <ExceptionMessage error={error} />
      </HmAlert>
    );
  }

  if (!data) return null;

  return (
    <FormField
      name={name}
      fieldType={{
        initializer: initializeReactSelect({
          isLoading,
          onChange,
          getOptionValue,
          isMulti,
          defaultValue
        }),
        component: HmSelect
      }}
      options={filter ? data.filter(filter) : data}
      {...props}
    />
  );
};
