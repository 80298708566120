import React, { FunctionComponent, ReactNode, useContext } from 'react';
import { useFormState } from '../../hooks/useFormState';
import { HmDrawer } from '../HmDrawer';
import { Col, Button, ButtonToolbar, ModalFooter, Form } from 'react-bootstrap';
import { HmDialogContext } from '../HmDialog';
import { HmButton } from './HmSubmit';
import { HmGuard } from 'components/security/HmGuard';
import { useDetailParams } from 'hooks/useDetail';

interface HmFormDrawerProps {
  saveLabel?: ReactNode;
  cancelLabel?: ReactNode;
  watch  ?: string[];
  onCancel ?: () => void;
  allowUnchanged ?: boolean;
}

export const HmFormDrawer: FunctionComponent<HmFormDrawerProps> = ({
  children,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  watch= [],
  allowUnchanged = false,
  ...props
}) => {
  const { api } = useFormState();

  return (
    <HmDrawer show={api.state.pristine === false} {...props}>
      <Col md={{ offset: 4 }} lg={{offset: 3}} xs="auto">
        <ButtonToolbar>
          <HmButton variant="primary" type="submit" allowUnchanged={allowUnchanged} watch={watch}>
            {saveLabel}
          </HmButton>
          <Button
            id="cancel"
            type="reset"
            variant="secondary"
            onClick={e => {
              e.preventDefault();
              api.reset();
            }}>
            {cancelLabel}
          </Button>
        </ButtonToolbar>
      </Col>
      {children}
    </HmDrawer>
  );
};

interface HmDialogFormDrawerProps {
  saveLabel?: ReactNode;
  saveLabelId?: string;
  cancelLabel?: ReactNode;
  onCancel ?: () => void;
  closeOnCancel ?: boolean;
  watch ?: string[];
  keepData ?: boolean;
  allowUnchanged ?: boolean;
  addAnother ?: boolean;
}

export const HmDialogFormDrawer: FunctionComponent<HmDialogFormDrawerProps> = ({
  children,
  saveLabel = 'Save',
  saveLabelId,
  cancelLabel = 'Cancel',
  closeOnCancel = true,
  onCancel = () => { return; },
  watch = [],
  keepData = false,
  allowUnchanged = false,
  addAnother = false,
  ...props
}) => {
  const { api } = useFormState();
  const { dialog, actions } = useContext(HmDialogContext);

  return (
      <ModalFooter {...props}>
        <ButtonToolbar className="order-1">
          <Button
            type="reset"
            variant="secondary"
            onClick={e => {
              e.preventDefault();
              if (keepData !== true) api.reset();
              onCancel && onCancel();
              if (closeOnCancel) dialog(actions.close());
            }}>
            {cancelLabel}
          </Button>
          <HmButton id={saveLabelId} variant="primary" type="submit" allowUnchanged={allowUnchanged} watch={watch}>
            {saveLabel}
          </HmButton>
           {addAnother && <AddAnotherCheckbox />}
        </ButtonToolbar>
        <HmGuard access={api.state.access} component={null}>
          {children}
        </HmGuard>
      </ModalFooter>
  );
};



const AddAnotherCheckbox = () => {
  const {closeOnSave = true, setCloseOnSave} = useDetailParams();
  if (!setCloseOnSave) return null;
  const onChange = ({target}) => {
    setCloseOnSave(!target.checked);
  } ;
  return (
    <Form.Check name="addAnother" id="addAnother" checked={!closeOnSave} onChange={onChange} label="Add Another" custom className="btn" />
  )
}
