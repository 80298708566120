import React, { FunctionComponent, createContext, useReducer } from 'react';
import tour, { TourState, finishTour, completeTourStep, TourStep } from 'api/v1/tour';
import { useAsync } from 'react-async';
import { useUserState } from 'hooks/useUserState';

type TourContextState = {
  tour: TourState;
  isActive: boolean;
  isLoading: boolean;
  actions: {
    activate: () => Promise<TourState>,
    completeStep: (step: TourStep | number) => Promise<void>,
    end: () => void;
    finish: () => Promise<void>,
  }
}

export const TourContext = createContext<TourContextState>({} as TourContextState);

function tourReducer(state, action) {
  const { type: typeFn, payload } = action;
  if (typeof typeFn === 'function') action = typeFn(state, payload);
  else if (typeof action === 'function') action = action(state);
  else action = state;
  return action;
}

function setTourAction(state, {active, tour = undefined}) {
  const newState = {
    ...state,
    isActive: active,
  };
  if (tour !== undefined) {
    newState.tour = tour;
  }
  localStorage.setItem('tour:state', JSON.stringify({isActive: active}));

  return newState;
}

function initTourState(state, {tour, dispatch}) {
  return {
    ...state,
    tour,
    actions: {
      activate: () => tourPromise({activate: true}).then(tour => {
        dispatch({type: setTourAction, payload: {active: true, tour }});
        return tour;
      }),
      completeStep: (step) => completeTourStep(step).then(tourPromise).then(tour => dispatch({type: initTourState, payload: {tour, dispatch}})),
      end: () => dispatch({type: setTourAction, payload: { active: false }}),
      finish: () => finishTour().then(() => {
        dispatch({type: setTourAction, payload: { active: false }});
      }),
    }
  }
}


const tourPromise = ({activate = false, user = null}:any): Promise<TourState> => {
  let tourState: any = localStorage.getItem('tour:state');
  if (tourState) tourState = JSON.parse(tourState)
  else tourState = {isActive: false};
  if (activate === true ) {
    tourState.isActive = activate;
  }
  if (!tourState.isActive || !user || !user.isLoggedIn) return Promise.resolve({ cards: [], currentStep: null, nextStep: null, lastCompletedStep: null, daysRemainingInTrial: 0, hasClientFinishedTour: false } as any) ;
  return tour.state();
}
export const TourContextProvider : FunctionComponent<any> = (props) => {
  const [user] = useUserState();
  const [ state, dispatch ] = useReducer(tourReducer, {
    tour: {},
    isActive: user.isLoggedIn && user.isTrialUser === true,
    isLoading: false,
    actions: {}
  });

  const { isLoading } = useAsync({
    promiseFn: tourPromise,
    user,
    onResolve: (tour) => {
      return dispatch({type: initTourState, payload: {tour, dispatch}});
    }
  });

  return (
    <TourContext.Provider value={{ ...state, dispatch, isLoading }}>
      {props.children}
    </TourContext.Provider>
  );
};

export default TourContext;
