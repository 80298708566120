export const eachPropertyPair = (object, callback, includePrototypeHierarchyAttributes = false) => {
  const includeHierarchy =
    includePrototypeHierarchyAttributes === undefined ? true : includePrototypeHierarchyAttributes;

  // tslint:disable-next-line: forin
  for (const methodName in object) {
    const value = object[methodName];
    const isOwnProperty = object.hasOwnProperty(methodName);

    if (isOwnProperty || includeHierarchy) callback(methodName, value, isOwnProperty);
  }
};

export const hasProps = obj => {
  return Object.keys(obj).length > 0;
};

export const reduce = (obj, alwaysRemove = [] as any) => {
  return Object.keys(obj).reduce((acc, key) => {
    const remove = hasProps(obj) ? alwaysRemove.find(i => i === key) : false;
    return remove || obj[key] === null || obj[key] === undefined
      ? acc
      : { ...acc, [key]: obj[key] };
  }, {});
};

export default {
  eachPropertyPair,
  hasProps,
  reduce
};
