import React, { useEffect, useLayoutEffect, useRef, createContext, useMemo, RefObject, FunctionComponent } from 'react';
import { useFilter } from 'hooks/useFilter';
import { useHistory, useLocation } from 'react-router';
import { removeState, Updater } from 'hooks/useSharedState';
import { FormContextProvider } from './forms';
import { clone, Filter } from 'lib.core';

interface HmFilterProps<T extends {} = {}> {
  name: string;
  top ?: number;
  value ?: T;
  destroy ?: boolean;
  useQuery ?: boolean;
  updateSearch ?: boolean;
  loadSearch ?: boolean;
  persist ?: boolean;
  defaultSort ?: string;
}

export type FilterContextState<T = any> = {
  name: string;
  filter: Filter<T>;
  actions: RefObject<Map<string, () => any>>;
  dispatch: Updater<Filter<T>>;
  tryRefresh: () => Promise<any>;
  tryAction: (action: string, ...params) => any;
}

export const FilterContext = createContext({} as FilterContextState);

export const HmFilter: FunctionComponent<HmFilterProps> = ({
    name,
    top = 25,
    value = {},
    persist = false,
    destroy = !persist,
    useQuery = false,
    updateSearch = useQuery,
    loadSearch = useQuery,
    defaultSort = '',
    ...props
  }) => {
    const history = useHistory();
    const location = useLocation();

    const handlingChange = useRef(false);

    const [filter, setFilter] = useFilter(name, value, {
      top,
      sort: defaultSort,
      query: loadSearch ? location.search : '',
      persist
    });

    const action = useRef(new Map([['reset', () => setFilter(f => {
      f.reset();
      return f;
    }, true)]]));

    useEffect(() => {
      if (updateSearch && !handlingChange.current) {
        handlingChange.current = true;
        updateSearch && history.push({ search: filter.getQsValue() });
      } else {
        handlingChange.current = false;
      }

      persist && localStorage.setItem(`filter:${name}`, filter.getPersistedValue());

    }, [filter.value, filter.top, filter.skip, updateSearch, persist]);

    // handles values changing typically from browser forward/back
    useEffect(() => {
      if (loadSearch && !handlingChange.current) {
        handlingChange.current = true;
        setFilter(f => { f.parseQsValue(location.search); return f; });
      } else {
        handlingChange.current = false;
      }
    }, [location.search, loadSearch]);

    useLayoutEffect(() => {
      return () => {
        if (destroy) {
          localStorage.removeItem(`filter:${name}`);
          removeState(name);
        }
      };
    }, [destroy, name]);

    const context = useMemo(() => ({
      name,
      filter,
      actions: action,
      dispatch: setFilter,
      tryRefresh: () => {
        if (!action.current || !action.current.has('refresh')) return Promise.resolve();
        const act = action.current.get('refresh') as any;
        return act(true);
      },
      tryAction: (actionName, ...params) => {
        if (!action.current || !action.current.has(actionName)) return;
        const act = action.current.get(actionName) as any;
        return act(...params);
      }
    }), [filter, name]);

    return (
      <FilterContext.Provider value={context}>
        <FormContextProvider access value={clone(filter.value)}>
          {props.children}
        </FormContextProvider>
      </FilterContext.Provider>
    );
  }
