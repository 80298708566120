import { withMock, authHttp, resourceHttp, handleNullResponse } from '../../../';
import { HmRequestOptions } from '../../../http';

const request = resourceHttp.url('/billing/billingcontacts');

export const getBillingContacts = ({wildcard}, options: HmRequestOptions = {}) =>
  withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ './billingcontacts.mock.js'))
    .url(`/${wildcard}`)
    .get() as Promise<any>;

export const saveBillingContacts = ({wildcard, values: {userIds = []}}, options: HmRequestOptions = {}) =>
  {
    if (userIds.length === 0) return Promise.resolve([]);

    return withMock(request, options, () => import(/* webpackChunkName: "mockApi" */ '../../../generic.mock'))
      .url(`/${wildcard}`)
      .post({userIds})
      .catch(handleNullResponse);
  };
