
const percent = new Intl.NumberFormat('en-US', { style: 'percent', maximumFractionDigits: 2 });
const numberByLocale = new Intl.NumberFormat();

export function formatPercent(value, isWholeNumber = true) {
  if (isNaN(value)) value = 0;
  if (typeof value === 'string') value = parseFloat(value);
  return percent.format(isWholeNumber ? value / 100 : value);
}

export function formatNumber(value) {
  return isNaN(value) ? value : numberByLocale.format(value);
}