import React from 'react';
import { Dropdown, Button } from 'react-bootstrap';
import { useCurrentWildcard } from '../../hooks/useInstanceState';
import { useClientContext } from '../../hooks/useClientContext';
import { checkInstanceFeature } from 'lib.security';

export const InstancePicker = ({ active, hideSingle = false, feature = true }: any) => {
  const wildcard = useCurrentWildcard();
  const { instances, changeInstance, features } = useClientContext();
  const  filteredFeatures = features.filter(checkInstanceFeature(feature))

  const visibleInstances = hideSingle || feature ? instances.filter(i => i.wildcard !== wildcard  && filteredFeatures.some(f => f.instanceId === i.id)) : instances;

  if (visibleInstances.length < 1 || !wildcard) {
    return null;
  }

  const InstanceButton: any = instances.length > 1 ? Dropdown.Toggle : Button;

  return (
    <Dropdown className="instance-picker" onSelect={changeInstance as any}>
      <InstanceButton id="toggleInstance">{wildcard}</InstanceButton>
      <InstanceDropDown
        onChange={changeInstance}
        instances={visibleInstances}
      />
    </Dropdown>
  );
};

const InstanceDropDown = ({ onChange, instances }) => {
  if (instances.length === 0) {
    return null;
  }
  return (
    <Dropdown.Menu className="instance-picker">
      {instances.map((instance, idx) => (
        <Dropdown.Item
          key={idx}
          eventKey={instance.id}>
          {instance.name} ({instance.wildcard})
        </Dropdown.Item>
      ))}
    </Dropdown.Menu>
  );
};
