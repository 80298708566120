import { useEffect } from 'react';
import { useHistory } from 'react-router';

export function useHashObserver(timeout: number = 1000) {
  const history = useHistory();

  useEffect(() => {
    let observer;
    let timeoutId;

    if (typeof MutationObserver === 'undefined') {
      return;
    }

    const reset = () => {
      if (timeoutId) {
        clearTimeout(timeoutId);

        timeoutId = null;
      }

      if (observer) {
        observer.disconnect();
      }
    };

    const createScrollToElement = (id: string) => {
      return () => {
        const element = document.getElementById(id);

        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });

          reset();

          return true;
        }

        return false;
      };
    };

    history.listen((location: any, action: string) => {
      if (timeoutId) {
        reset();
      }

      if (action !== 'PUSH') {
        return;
      }

      if (typeof location.hash !== 'string') {
        return;
      }

      const elementId = location.hash.slice(1);

      if (!elementId) {
        return;
      }

      const scrollToElement = createScrollToElement(elementId);

      setTimeout(() => {
        if (scrollToElement()) {
          return;
        }

        observer = new MutationObserver(scrollToElement);

        observer.observe(document, {
          attributes: true,
          childList: true,
          subtree: true
        });

        timeoutId = setTimeout(reset, timeout);
      });
    });
  }, [history, timeout]);
}
