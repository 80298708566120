import React, { forwardRef, FunctionComponent } from 'react';
import { Variant } from 'components/typography/Text';
import { ToggleButtonGroup, ToggleButton } from 'react-bootstrap';
import { ui } from 'lib.core';
import { FormFieldProps } from './FormField';
import { FormField } from '.';
import { FieldStateInitializer } from 'hooks/formUtils/initializers';

export type ToggleButtonFieldProps = {
  name: string;
  id?: string;
  options: any[];
  variant?: Variant;
  value?: any;
  isInvalid?: boolean;
  type?: 'radio' | 'checkbox';
  onChange?: (value: any) => void;
  getOptionLabel?: (option: any) => any;
  getOptionValue?: (option: any) => any;
  placeholder?: any;
  disabled?: boolean;
  size?: 'sm' | 'lg';
  className?: any;
};

export const FormToggleButton = forwardRef<any, ToggleButtonFieldProps>(
  (
    {
      name,
      id = name,
      variant,
      value,
      options,
      isInvalid = false,
      getOptionLabel = o => o.label,
      getOptionValue = o => o.key,
      placeholder,
      type = 'radio',
      disabled,
      className,
      size,
      ...props
    },
    ref
  ) => {
    return (
      <ToggleButtonGroup
        ref={ref}
        name={name}
        id={id}
        size={size}
        type={type as any}
        className={ui.classNames(className, { 'is-invalid': isInvalid, 'btn-group-sm': size === 'sm', 'btn-group-lg': size === 'lg' }) || undefined}
        {...props}>
        {options.map((option, i) => (
          <ToggleButton
            key={i}
            variant={variant as any}
            disabled={disabled}
            className={value === getOptionValue(option) ? 'active' : undefined}
            value={i}>
            {getOptionLabel(option)}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    );
  }
);

export const ToggleButtonField: FunctionComponent<FormFieldProps & ToggleButtonFieldProps> = ({
  name,
  id = name,
  variant = 'primary',
  onChange,
  options,
  getOptionLabel = o => o.label,
  getOptionValue = o => o.key,
  componentProps,
  ...props
}) => {
  return (
    <FormField
      name={name}
      {...props}
      fieldType={{
        initializer: initializeToggle,
        component: FormToggleButton
      }}
      componentProps={{
        id,
        variant,
        onChange,
        options,
        getOptionLabel,
        getOptionValue,
        ...componentProps
      }}
    />
  );
};

export const initializeToggle: FieldStateInitializer = ({
  value,
  setter,
  setError,
  props: {
    ref,
    type,
    disabled,
    options = [],
    defaultValue,
    getOptionLabel,
    getOptionValue,
    onSelect,
    ...fieldProps
  }
}) => {
  const mapped = options.map(getOptionValue);

  const v = isSet(value, mapped.some(o => o === null))
    ? mapped.find(o => o === value)
    : mapped.find(o => o === defaultValue);

  return {
    value: v,
    disabled,
    options,
    ...fieldProps,
    getOptionLabel,
    getOptionValue,
    onChange: (selected: any, e) => {
      const ov = getOptionValue(options[selected]);
      onSelect && onSelect(ov, e);
      setter(ov, true);
      setError(false); // clear errors set outside of the control
    },
    dependencies: [disabled, options, v]
  };
};

function isSet(value, hasNulls) {
  return (hasNulls && value === null) || (value !== undefined && value !== null);
}
