import React from 'react';
import { Routes } from './routes';
import { HmUrl } from 'lib.app/routes';
import { BrowserRouter as Router } from 'react-router-dom';
import { HmContextProvider, initializeAction } from './HmContext';
import { Toaster } from './components/HmAlert';
import { myStatusInit } from './api/v1/auth/my';
import { useAsync } from 'react-async';
import config from './config';
import { CypressBridge } from './components/dev/Cypress';
import { HmPageLoading } from './components/HmLoading';
import { ClientContextProvider } from './components/clients/ClientContext';
import { TimeContextProvider } from './components/clients/TimeContext';
import { TourContextProvider } from 'areas/tour/components/TourContext';
import { init as sentryInit } from '@sentry/browser';
import { ErrorBoundary } from 'components';
import { ServerFault } from 'areas/Errors';
import { useManifestChanged } from 'hooks/useManifestChanged';
import { useActivityTimeout } from 'hooks/useActivityTimeout';

if (config.analytics.segmentio) {
  // eslint-disable-next-line no-undef
  analytics.load(config.analytics.segmentio);
}

if (config.sentry.enabled) {
  sentryInit({
    dsn: config.sentry.dsn
  });
}


// application initialization
const setupApplication = async () => {

  let dataInit;
  try {
    const initData = await myStatusInit();
    dataInit = initializeAction({}, initData);
  } catch (e) {
    dataInit = initializeAction({ user: { isLoggedIn: false } }, {});
  }

  config.app.initialized = true;

  return dataInit;
};

const suppressIfLoginPage = () => window.location.pathname === HmUrl.login;

const App = () => {
  return (
    <Router>
      <Toaster name="toast" />
      <div id="hmHeader" />
      <AppContext />
      <div id="hmFooter" />
      <div id="drawers" />
    </Router>
  );
};

const AppContext = () => {
  let { isLoading, data, reload } = useAsync(setupApplication);
  useManifestChanged(() => window.location.reload(true), config.app.versionTimeout);
  useActivityTimeout(() => { window.location.href = HmUrl.logout;}, config.app.timeout, suppressIfLoginPage);

  if (isLoading) {
    return <HmPageLoading />;
  }

  return (
    <HmContextProvider value={data} reload={reload}>
      <ErrorBoundary container={ServerFault}>
        <ClientContextProvider>
          <TourContextProvider>
            <TimeContextProvider>
              <Routes />
              <CypressBridge />
            </TimeContextProvider>
          </TourContextProvider>
        </ClientContextProvider>
      </ErrorBoundary>
    </HmContextProvider>
  );
};

export default App;
