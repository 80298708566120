import React, { FunctionComponent } from 'react';

type AddressProps = {
  city?: string;
  state?: string;
  zip?: string;
  addressLine1: string;
  addressLine2?: string;
  county?: string;
  unitNumber?: string;
  includeUnitNumber?: boolean;
};

export type ContactProps = AddressProps & {
  phone?: string;
  email?: string;
};

export const HmAddress: FunctionComponent<AddressProps> = ({
  city,
  state,
  zip,
  addressLine1,
  addressLine2,
  county,
  unitNumber,
  includeUnitNumber = false
}) => {
  const address = [addressLine1];
  if (addressLine2) {
    address.push(addressLine2);
  }
  if (city) {
    address.push(
      [city, county ? county + ' County' : county, [state, zip].filter(_ => _).join(' ')]
        .filter(_ => _)
        .join(', ')
    );
  }
  if (includeUnitNumber && unitNumber) {
    address.push(`Unit #${unitNumber}`);
  }

  return (
    <div className="address-info">
      {address.map((i, idx) => (
        <span key={idx}>{i}</span>
      ))}
    </div>
  );
};