import React, { Fragment, FunctionComponent } from 'react';
import { Col, Form } from 'react-bootstrap';
import { FormField } from '.';
import states from 'lib.core/states';

export const Address = ({
  Container = Fragment,
  labelSrOnly = false,
  prefix = '',
  address1Name = 'addressLine1',
  address1Label = 'Address Line 1',
  address2Name = 'addressLine2',
  address2Label = 'Address Line 2',
  cityName = 'city',

  cityLabel = 'City',
  stateName = 'state',
  stateLabel = 'State',
  zipName = 'zip',
  zipLabel = 'Zip',
  countyName = 'county',
  countyLabel = 'County',
  showCounty = true,
  disabled = false,
}) => {

  return (
    <Container>
      <Form.Row>
        <Col>
          <FormField
            name={fieldName(address1Name, prefix)}
            label={address1Label}
            labelSrOnly={labelSrOnly}
            disabled={disabled}
            placeholder="Address line 1"
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormField
            name={fieldName(address2Name, prefix)}
            label={address2Label}
            labelSrOnly
            disabled={disabled}
          />
        </Col>
      </Form.Row>
      <Form.Row>
        <Col>
          <FormField
            name={fieldName(cityName, prefix)}
            label={cityLabel}
            labelSrOnly={labelSrOnly}
            disabled={disabled}
            placeholder="City"
          />
        </Col>
        <Col>
          <FormField
            name={fieldName(stateName, prefix)}
            label={stateLabel}
            labelSrOnly={labelSrOnly}
            fieldType="select"
            placeholder="-- State --"
            options={states}
            disabled={disabled}
          />
        </Col>
        <Col>
          <FormField
            name={fieldName(zipName, prefix)}
            label={zipLabel}
            labelSrOnly={labelSrOnly}
            disabled={disabled}
            placeholder="Zip"
          />
        </Col>
        {showCounty && (
          <Col>
            <FormField
              name={fieldName(countyName, prefix)}
              label={countyLabel}
              labelSrOnly={labelSrOnly}
              disabled={disabled}
              placeholder="County"
            />
          </Col>
        )}
      </Form.Row>
    </Container>
  );
};

function fieldName(base: string, prefix ?: string): string {
  if (!prefix) {
    return base;
  }
  return [prefix, base.charAt(0).toUpperCase(), base.substring(1)].join('');
}
