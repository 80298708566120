import React, { FunctionComponent, ReactNode, useMemo, useRef } from 'react';
import { Form, InputGroup, Row } from 'react-bootstrap';
import {
  useFormFieldState,
  initialize,
  FormFieldApi,
  useFieldApi
} from 'hooks/useFormFieldState';
import { HmAccessKey } from 'lib.security';
import { HmElementGuard } from 'components/security/HmGuard';
import { useFormState } from 'hooks/useFormState';
import { FieldStateInitializer } from 'hooks/formUtils';
import { ui } from 'lib.core';
import { MutedText } from 'components';
import { formFields } from './formFields';
import { IconTimes } from 'components/icons';

export interface FormFieldProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label: ReactNode;
  labelSrOnly?: boolean;
  defaultValue?: any;
  type?: string;
  as?: any;
  optional?: boolean;
  addOn?: ReactNode;
  prependText?: ReactNode;
  prepend?: ReactNode;
  append?: ReactNode;
  appendText?: ReactNode;
  tooltip?: ReactNode;
  component?: ReactNode;
  placeholder?: string;
  column?: boolean;
  fieldOnly?: boolean;
  fieldSize?: 'sm' | 'lg';
  inputClassName?: string;
  access?: HmAccessKey | boolean;
  sm?: any;
  md?: any;
  lg?: any;
  xl?: any;
  xs?: any;
  getField ?: (params: [any, FormFieldApi]) => void;
  fieldType?: FieldStateInitializer | keyof typeof formFields | object;
  watch?: any[];
  options?: any[];
  componentProps?: any;
  clearable?: boolean;
  validator?: (value: any, values: any) => false | string;
}

function useInitializer(fieldInitializer) {
  const ref = useRef(fieldInitializer);

  if (typeof fieldInitializer === 'string' && formFields[fieldInitializer]) {
    return formFields[fieldInitializer];
  }
  if (typeof fieldInitializer === 'function') {
    return {
      initializer: fieldInitializer,
      component: Form.Control
    };
  }
  if (fieldInitializer && fieldInitializer.initializer) return fieldInitializer;

  throw new Error('Invalid field initializer');
}

export const HookFormField: FunctionComponent<FormFieldProps> = ({
  name,
  id = name,
  clearable = false,
  label,
  labelSrOnly = false,
  optional = false,
  fieldType = initialize.input,
  column = false,
  sm,
  md,
  lg,
  xl,
  xs,
  fieldOnly = false,
  fieldSize,
  watch = [],
  getField,
  componentProps = {},
  access,
  children,
  className,
  inputClassName = fieldOnly ? className : undefined,
  ...props
}) => {
  if (labelSrOnly && !props.placeholder && typeof label === 'string') {
    props.placeholder = label;
  }

  const fieldRef = useRef(null as any);
  const { initializer, component, ...rest } = useInitializer(fieldType);
  const {api: {state}} = useFormState();
  const { props: field, api, dependencies } = useFormFieldState(name, initializer, {
    ref: fieldRef.current,
    ...rest,
    className: inputClassName,
    ...componentProps,
    ...props
  });
  getField && getField([field, api]);

  const labelProps = { column, sm, md, lg, xl, xs } as any;

  const { append = clearable ? <ClearField name={name} /> : undefined, prepend, appendText, prependText, addOn, helpers, ...fieldProps } = field;

  const Control = component || Form.Control;
  const fieldAccess = (access || state.access) as any;
  const fieldMemo = useMemo(
    () =>
      append || prepend || appendText || prependText ? (
          <InputGroup className={ui.classNames({ 'is-invalid': !api.valid })} size={fieldSize}>
            {(prepend || prependText) && (
              <InputGroup.Prepend>
                {prependText && <InputGroup.Text>{prependText}</InputGroup.Text>}
                {prepend}
              </InputGroup.Prepend>
            )}
            <HmElementGuard access={fieldAccess} disabled><Control ref={fieldRef} {...fieldProps} isInvalid={!api.valid} /></HmElementGuard>
            {(append || appendText) && (
              <InputGroup.Append>
                {append}
                {appendText && <InputGroup.Text>{appendText}</InputGroup.Text>}
              </InputGroup.Append>
            )}
          </InputGroup>
      ) : (
        <HmElementGuard access={fieldAccess} disabled><Control ref={fieldRef} {...fieldProps} isInvalid={!api.valid} size={fieldSize} /></HmElementGuard>
      ),
    [...dependencies, ...watch]
  );

  return useMemo(() => {
    return fieldOnly ? (
      fieldMemo
    ) : (
      <Form.Group as={column ? Row : 'div'} controlId={id} className={className}>
        <Form.Label srOnly={labelSrOnly} {...labelProps as any}>
          {label} {optional === true ? <MutedText>(Optional)</MutedText> : null}
        </Form.Label>
        <div className={ui.classNames('hm-field', { col: column })}>
          {fieldMemo}
          {addOn && <Form.Text>{addOn}</Form.Text>}
          {children}
          <Form.Control.Feedback type="invalid">{api.errors}</Form.Control.Feedback>
        </div>
      </Form.Group>
    );
  }, [...dependencies, ...watch]);
};

export const ClearField = ({name, className ='input-overlay input-action'}) => {
  const api = useFieldApi(name);
  if (!api.value) return null;

  return <span onClick={e => {
    e.preventDefault();
    e.stopPropagation();
    return api.clear(null);
  }} className={className} ><IconTimes /></span>
}