import React, { ReactElement } from 'react';
import { Row, Col, Button } from 'react-bootstrap';
import { IconSpinner } from '../icons';
import { useFormState } from '../../hooks/useFormState';
import { HmGuard, HmElementGuard } from 'components/security/HmGuard';

interface HmSubmitProps {
  isWorking ?: boolean;
  disabled ?: boolean;
  btnText ?: string;
  button ?: ReactElement<any>;
  extrasClassName ?: string;
  children ?: any;
}

export const HmSubmit = ({ isWorking = false, disabled, button, btnText = 'Submit', extrasClassName = 'text-right', children, ...props }: HmSubmitProps) => {
  const SubmitButton = button || (
    <Button
      variant="primary"
      size="lg"
      type="submit"
      disabled={isWorking || disabled}
      {...props}
      >
      {btnText}
      {isWorking && <IconSpinner />}
    </Button>
  );

  return (
    <Row className="align-items-center">
      <Col className="submit-group">
        {SubmitButton}
      </Col>
      {children &&
      <Col className={extrasClassName}>
        {children}
      </Col>
      }
    </Row>
  );
};

export const HmButton = ({disabled = false, watch = [], children, allowUnchanged = false, ...props}: any) => {
  const {api} = useFormState();
  if (watch.length > 0) {
    disabled = !watch.map(api.getValue).every(v => Array.isArray(v) ? v.length > 0 : !!v);
  }

  return (
    <HmGuard access={api.state.access} component={null}>
      <Button disabled={disabled || api.state.submitting || (api.state.pristine && allowUnchanged === false)} {...props}>
        {children}
        {api.state.submitting && <IconSpinner />}
      </Button>
    </HmGuard>
  );
};
