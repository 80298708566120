import React from 'react';
import { library, Icon } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon, Props as FaProps } from '@fortawesome/react-fontawesome';
import {
  faArrowDown,
  faArrowUp,
  faBan,
  faBars,
  faBed,
  faBox,
  faBoxes,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCog,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFilter,
  faHistory,
  faHome,
  faIdCard,
  faInfoCircle,
  faLock,
  faLockOpen,
  faLongArrowAltLeft,
  faMoneyCheckAlt,
  faPen,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faReply,
  faSearch,
  faShoppingCart,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStopwatch,
  faTimes,
  faUser,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Variant } from 'components/typography/Text';

library.add(
  faArrowDown,
  faArrowUp,
  faBan,
  faBars,
  faBed,
  faBox,
  faBox,
  faBoxes,
  faBoxes,
  faBuilding,
  faCalendar,
  faCalendarCheck,
  faCamera,
  faCaretDown,
  faCaretUp,
  faChartBar,
  faCheck,
  faCheckSquare,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faClipboardList,
  faClock,
  faCloudDownloadAlt,
  faCog,
  faDollarSign,
  faDownload,
  faEnvelope,
  faExclamationTriangle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faFilter,
  faHistory,
  faHome,
  faIdCard,
  faInfoCircle,
  faLock,
  faLockOpen,
  faLongArrowAltLeft,
  faMoneyCheckAlt,
  faPen,
  faPhone,
  faPlus,
  faPrint,
  faQuestionCircle,
  faReply,
  faSearch,
  faShoppingCart,
  faSortDown,
  faSortUp,
  faSpinner,
  faSquare,
  faStar,
  faStopwatch,
  faTimes,
  faUser,
  faUsers,
);

export default library;
export type IconProps = Pick<FaProps, Exclude<keyof FaProps, 'icon'>>;

const variantMap = {
  success: 'check',
  danger: 'ban',
  info: 'info-circle',
  warning: 'exclamation-triangle',
}

export const IconVariant = ({variant, ...props}: IconProps & { variant: Variant }) => {
  const icon = variantMap[variant];
  if (!icon) return null;

  return <FontAwesomeIcon icon={icon} {...props} />
}

export const IconApplications = (props: IconProps) => {
  return <FontAwesomeIcon icon="id-card" {...props} />;
}

export const IconArrowDown = (props: IconProps) => {
  return <FontAwesomeIcon icon="arrow-down" {...props} />;
};

export const IconArrowUp = (props: IconProps) => {
  return <FontAwesomeIcon icon="arrow-up" {...props} />;
};

export const IconBars = (props: IconProps) => {
  return <FontAwesomeIcon icon="bars" {...props} />;
};

export const IconBack = (props: IconProps) => {
  return <FontAwesomeIcon icon="reply" {...props} />;
};

export const IconBan = (props: IconProps) => {
  return <FontAwesomeIcon icon="ban" {...props} />;
};

export const IconBed = (props: IconProps) => {
  return <FontAwesomeIcon icon="bed" {...props} />;
};

export const IconCalendar = (props: IconProps) => {
  return <FontAwesomeIcon icon="calendar" {...props} />;
};

export const IconCalendarCheck = (props: IconProps) => {
  return <FontAwesomeIcon icon="calendar-check" {...props} />;
};

export const IconCamera = (props: IconProps) => {
  return <FontAwesomeIcon icon="camera" {...props} />;
};

export const IconCaretDown = (props: IconProps) => {
  return <FontAwesomeIcon icon="caret-down" {...props} />;
};

export const IconCaretUp = (props: IconProps) => {
  return <FontAwesomeIcon icon="caret-up" {...props} />;
};

export const IconChartBar = (props: IconProps) => {
  return <FontAwesomeIcon icon="chart-bar" {...props} />;
};

export const IconCheck = (props: IconProps) => {
  return <FontAwesomeIcon icon="check" {...props} />;
};

export const IconCheckSquare = (props: IconProps) => {
  return <FontAwesomeIcon icon="check-square" {...props} />;
};

export const IconChevronDown = (props: IconProps) => {
  return <FontAwesomeIcon icon="chevron-down" {...props} />;
};

export const IconChevronLeft = (props: IconProps) => {
  return <FontAwesomeIcon icon="chevron-left" {...props} />;
};

export const IconChevronRight = (props: IconProps) => {
  return <FontAwesomeIcon icon="chevron-right" {...props} />;
};

export const IconChevronUp = (props: IconProps) => {
  return <FontAwesomeIcon icon="chevron-up" {...props} />;
};

export const IconCloudDownloadAlt = (props: IconProps) => {
  return <FontAwesomeIcon icon="cloud-download-alt" {...props} />;
};

export const IconClock = (props: IconProps) => {
  return <FontAwesomeIcon icon="clock" {...props} />;
};

export const IconDateTime = (props: IconProps) => {
  return <FontAwesomeIcon icon="clock" mask="calendar" transform="shrink-8 down-2 right-2" {...props} />;
};

export const IconSettings = (props: IconProps) => {
  return <FontAwesomeIcon icon="cog" {...props} />;
};

export const IconSpinner = (props: IconProps) => {
  return <FontAwesomeIcon icon="spinner" spin {...props} />;
};

export const IconSquare = (props: IconProps) => {
  return <FontAwesomeIcon icon="square" {...props} />;
};

export const IconDownload = (props: IconProps) => {
  return <FontAwesomeIcon icon="download" {...props} />;
};

export const IconEmail = (props: IconProps) => {
  return <FontAwesomeIcon icon="envelope" {...props} />;
};

export const IconDanger = (props: IconProps) => {
  return <FontAwesomeIcon icon="exclamation-triangle" {...props} />;
};

export const IconPayment = (props: IconProps) => {
  return <FontAwesomeIcon icon="money-check-alt" {...props} />;
};

export const IconBanPayment = (props: IconProps) => {
  return <FontAwesomeIcon mask="money-check-alt" icon="ban" transform="shrink-4 right-4" {...props} />;
};

export const IconExternalLinkAlt = (props: IconProps) => {
  return <FontAwesomeIcon icon="external-link-alt" {...props} />;
};

export const IconEye = (props: IconProps) => {
  return <FontAwesomeIcon icon="eye" {...props} />;
};

export const IconEyeSlash = (props: IconProps) => {
  return <FontAwesomeIcon icon="eye-slash" {...props} />;
};


export const IconFavorite = (props: IconProps) => {
  return <FontAwesomeIcon icon="star" {...props} />;
};

export const IconFilter = (props: IconProps) => {
  return <FontAwesomeIcon icon="filter" {...props} />;
};

export const IconHistory = (props: IconProps) => {
  return <FontAwesomeIcon icon="history" {...props} />;
};

export const IconHome = (props: IconProps) => {
  return <FontAwesomeIcon icon="home" {...props} />;
};

export const IconInfoCircle = (props: IconProps) => {
  return <FontAwesomeIcon icon="info-circle" {...props} />;
};

export const IconLock = (props: IconProps) => {
  return <FontAwesomeIcon icon="lock" {...props} />;
};

export const IconLockOpen = (props: IconProps) => {
  return <FontAwesomeIcon icon="lock-open" {...props} />;
};

export const IconPen = (props: IconProps) => {
  return <FontAwesomeIcon icon="pen" {...props} />;
};

export const IconPhone = (props: IconProps) => {
  return <FontAwesomeIcon icon="phone" {...props} />;
};

export const IconPlus = (props: IconProps) => {
  return <FontAwesomeIcon icon="plus" {...props} />;
};

export const IconPrint = (props: IconProps) => {
  return <FontAwesomeIcon icon="print" {...props} />;
};

export const IconQuestion = (props: IconProps) => {
  return <FontAwesomeIcon icon="question-circle" {...props} />;
};

export const IconResidents = (props: IconProps) => {
  return <FontAwesomeIcon mask="home" icon="users" transform="shrink-8 down-4" {...props} />;
};

export const IconSearch = (props: IconProps) => {
  return <FontAwesomeIcon icon="search" {...props} />;
};

export const IconShoppingCart = (props: IconProps) => {
  return <FontAwesomeIcon icon="shopping-cart" {...props} />;
};

export const IconSortDown = (props: IconProps) => {
  return <FontAwesomeIcon icon="sort-down" {...props} />;
};

export const IconSortUp = (props: IconProps) => {
  return <FontAwesomeIcon icon="sort-up" {...props} />;
};

export const IconSort = ({dir, ...props}: IconProps & { dir: 'asc' | 'desc'}) => {
  return dir === 'asc' ? <IconSortUp {...props} /> : <IconSortDown {...props} />;
};

export const IconStopwatch = (props: IconProps) => {
  return <FontAwesomeIcon icon="stopwatch" {...props} />;
};

export const IconTimes = (props: IconProps) => {
  return <FontAwesomeIcon icon="times" {...props} />;
};

export const IconDelete = IconTimes;

export const IconUser = (props: IconProps) => {
  return <FontAwesomeIcon icon="user" {...props} />;
};

export const IconUsers = (props: IconProps) => {
  return <FontAwesomeIcon icon="users" {...props} />;
};

export const IconUnit = (props: IconProps) => {
  return <FontAwesomeIcon icon="home" {...props} />;
};

export const IconUnits = (props: IconProps) => {
  return <FontAwesomeIcon icon="building" {...props} />;
};

export const IconWorkOrders = (props: IconProps) => {
  return <FontAwesomeIcon icon="clipboard-list" {...props} />;
};

export const IconItem = (props: IconProps) => {
  return <FontAwesomeIcon icon="box" {...props} />;
};

export const IconItems = (props: IconProps) => {
  return <FontAwesomeIcon icon="boxes" {...props} />;
};

export const IconDollar = (props: IconProps) => {
  return <FontAwesomeIcon icon="dollar-sign" {...props} />;
};
