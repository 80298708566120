import { useMemo } from "react";
import qs, {IParseOptions} from "qs";
import { merge } from "lib.core";
import { useLocation } from "react-router";

const defaults: IParseOptions = {
  ignoreQueryPrefix: true,
  parseArrays: true,
  strictNullHandling: true,
};

export function useQueryString<T = any>(initialValue?: T, options: IParseOptions = defaults) {
  const location = useLocation();

  return useMemo(() => {
    const query = qs.parse(location.search, options);
    return merge(initialValue || {}, query);
  }, [location.search, options]);
}