import wretch, { Wretcher, WretcherOptions } from 'wretch';
import mockMiddleware from './mockMiddleware';
import config from '../config';
import { clone } from '../lib.core';
import { HmUrl } from 'lib.app/routes';

const httpConfig = {
  accessToken: config.api.devToken,
  endpoint: config.api.endpoint,
  expires: config.api.devToken ? 1200 : 0,
  setLocation: window.location.replace,
  unauthorized: () => httpConfig.setLocation(`${HmUrl.login}?returnUrl=${encodeURIComponent(window.location.pathname)}`),
  forbidden:() => httpConfig.setLocation(`${HmUrl.login}?returnUrl=${encodeURIComponent(window.location.pathname)}`),
  notFound: () => httpConfig.setLocation(HmUrl.notFound),
  mocksEnabled: config.api.mockResponses,
  mockDefault: {
    status: 200,
    statusText: 'OK'
  }
};

interface HttpConfig {
  accessToken: string;
  endpoint: string;
  expires: number;
  mocksEnabled: boolean;
  setLocation: (url: string) => void;
  unauthorized: () => void;
  forbidden: () => void;
  notFound: () => void;
  mockDefault: { status: number; statusText: string };
}

type KeysOfType<T, U> = { [k in keyof T]: T[k] extends U ? k : never }[keyof T];
type HttpConfigName = keyof HttpConfig;

export interface HmMockOptions {
  promiseFn?: () => Promise<any>;
  status?: number;
  statusText?: string;
}

export interface HmRequestOptions extends WretcherOptions {
  mock?: HmMockOptions;
  forceMock?: boolean;
}

export const getHttpConfig = (
  name?: HttpConfigName | undefined
): string | number | boolean | any => {
  if (!name) return { ...httpConfig };
  return httpConfig[name];
};

export function setHttpConfig (name: HttpConfigName, value: any) {
  (httpConfig[name] as any) = value;
};

export const setAccessToken = (token, expires) => {
  httpConfig.accessToken = token;
  httpConfig.expires = expires;
};

export const withOptions = (w: Wretcher, options: HmRequestOptions = {}) => {
  if (!options) return w;

  if (options.mock && (options.forceMock === true || httpConfig.mocksEnabled === true)) {
    w = w.middlewares([mockMiddleware]);
    options.mock = clone(getHttpConfig('mockDefault'), options.mock);
  } else {
    delete options.mock;
  }
  return w.options(clone({ forceMock: false }, options));
};

export const withMock = (
  w: Wretcher,
  options: HmRequestOptions,
  mockPromiseFn: () => Promise<any>
) => withOptions(w, clone(options, { mock: { promiseFn: mockPromiseFn } }));

export function processBadApiRequest(errJson) {
  // not the right format
  if (!errJson || !errJson.length || !errJson[0].fieldName) return errJson;

  return errJson.reduce((err, fieldError) => {
    const path = cleanFieldName(fieldError.fieldName);
    if (!err[path]) err[path] = [];
    err[path].push(...fieldError.messages);
    return err;
  }, {});
}

function cleanFieldName(fielName) {
  const parts = fielName.split('.');
  return parts.map(p => p.charAt(0).toLowerCase() + p.substring(1)).join('.');
}


const http = wretch()
  .url(config.api.endpoint)
  .errorType('json')
  .options(config.api.options as any)
  .resolve(resolve =>
    resolve
      .unauthorized(res => {
        httpConfig.unauthorized()
      })
      .forbidden(res => {
        httpConfig.forbidden()
      })
      .badRequest((res, w) => {
        res.json = processBadApiRequest(res.json);
        throw res.json;
      })
      .json(_ => _)
  );

export const authHttp = http
  .options(config.api.options as any);

export default http;
