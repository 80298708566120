import { useContext, FormEvent, useEffect, useRef } from 'react';
import { getValue, createValue } from '../components/forms/utils';
import {
  setPathValue,
  addError,
  reset,
  setSubmitting,
  setValues,
  clear,
  mergeValues,
  setPristine
} from '../components/forms/reducers';
import { FormContext, FormState as FormContextState, FormAccessContext } from '../components/forms/FormContext';
import { hasError } from './formUtils/hasError';

export interface HmFormProps<T> {
  onSubmit: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  noValidate: boolean;
}

export type FormSubmit = (api: FormApi<any>, e?: FormEvent<HTMLFormElement>) => Promise<void>;
export type FormSumbitFn = (form: HTMLFormElement, e ?: FormEvent<HTMLFormElement>) => Promise<void>;

export interface FormApi<T = any> {
  values: T;
  state: FormContextState<T>;
  getValue: (path: string, orDefault?: any) => any;
  setValue: (path: string, value: any) => void;
  clearValue: (path: string) => void;
  setValues: (values: any, touched?: string[]) => void;
  mergeValues: (values: any) => void;
  setError: (error: any, clear?: boolean) => void;
  getErrors: (path: string) => any[];
  getTouched: (path: string) => boolean;
  getValid: (path: string) => boolean;
  reset: () => void;
  setSubmitting: (val: boolean) => void;
  errorSummary: () => string[];
  setPristine: (pristine: boolean) => void;
  submit: FormSubmit;
  validate: () => any;
  buildValue: (path: Array<{path: string, value: any}>) => any;
}

function submitForm<T>(formApi: FormApi<T>) {

  const validateAndSubmit = async (form:HTMLFormElement, e ?: FormEvent<HTMLFormElement>) => {
    const validity = checkFormValidity(form);

    if (validity !== true) {
      formApi.setError(validity);
      return;
    }

    const result = formApi.validate();
    if (result !== true) {
      return;
    }

    formApi.setSubmitting(true);

    try {
      await formApi.submit(formApi, e);
    } catch (error) {
      formApi.setError(error);
    }
    formApi.setSubmitting(false);
  };

  return [async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();

    return validateAndSubmit(e.target as HTMLFormElement, e);
  }, validateAndSubmit];
}

export interface FormState<T> {
  api: FormApi<T>;
  validateAndSubmit: FormSumbitFn;
  form: HmFormProps<T>;
}

export function useFormState<T = any>(submitFn: FormSubmit = () => Promise.resolve()) {
  const api = useFormApi<T>(submitFn);
  const [onSubmit, validateAndSubmit] = submitForm<T>(api);

  return {
    api,
    validateAndSubmit,
    form: {
      onSubmit,
      noValidate: true
    }
  };
}

function useFormApi<T>(onSubmit): FormApi<T> {
  const mounted = useRef(true);
  const { dispatch, state, validator } = useContext(FormContext) as any;
  const { access } = useContext(FormAccessContext);
  state.access = access;

  useEffect(() => () => {
    mounted.current = false;
  }, []);

  return {
    values: state.value,
    state,
    getValue: (path, orDefault) => getValue(state.value, path, orDefault),
    setValue: (path, value) => mounted.current && dispatch(setPathValue.send({ path, value })),
    clearValue: (path) => mounted.current && dispatch(clear.send(path)),
    setValues: (values, touched: string[] = []) => mounted.current && dispatch(setValues.send(values, touched)),
    mergeValues: values => mounted.current && dispatch(mergeValues.send(values)),
    setError: (error, clear = false) => dispatch(addError.send(error, clear)),
    getErrors: path => getValue(state.errors, path, []),
    getTouched: path => getValue(state.touched, path, false),
    getValid: path => getValue(state.valid, path, true) && getValue(state.touched, path, true),
    reset: () => mounted.current && dispatch(reset.send()),
    setSubmitting: (val: boolean) => mounted.current && dispatch(setSubmitting.send(val)),
    submit: onSubmit,
    errorSummary: () => reduceErrors(state.errors),
    setPristine: (pristine: boolean) => mounted.current && dispatch(setPristine.send(pristine)),
    validate: () => {
      const result = validator(state.value, state);
      if (result !== true) {
        mounted.current && dispatch(addError.send(result, true));
      }
      return result;
    },
    buildValue: (input) => {
      return input.reduce((obj, value) => {
        createValue(value.path, value.value, obj);
        return obj;
      }, {});
    }
  };
}

function checkFormValidity(form: HTMLFormElement) {
  if (form.classList.contains('no-validate')) {
    return true;
  }

  const fields = [...form.elements];
  let firstError: any = null;

  const errors = fields.reduce((errs, field) => {
    const error = hasError(field as HTMLInputElement);
    if (error) {
      errs[field.getAttribute('name') as any] = error;
      if (!firstError) {
        firstError = field;
      }
    }
    return errs;
  }, {});

  if (firstError) {
    firstError.focus();
    return errors;
  }
  return true;
}

function reduceErrors(errors) {
  if (!errors) return [];
  if (typeof errors === 'string') return errors;

  if (Array.isArray(errors)) {
    return errors.reduce((err, item) => {
      return err.concat(reduceErrors(item));
    }, []);
  }

  return Object.keys(errors).reduce((err, key) => {
    return err.concat(reduceErrors(errors[key]));
  }, [] as any);
}
