import { useContext } from 'react';
import { HmContext, HmUser, updateUser, UserAction } from '../HmContext';


export type UserDispatch = (user: HmUser, isLoggedIn ?: boolean, action ?: UserAction) => void;

export const useUserState = (): [HmUser, UserDispatch] => {
  const { dispatch, user } = useContext(HmContext);

  const userDispatch = (userData: HmUser, isLoggedIn = true, action) =>  {
    if (action === 'logout') {
      userData = { username: userData.username} as HmUser;
      action = 'login';
    }
    dispatch({ type: updateUser.action, payload: {...userData, isLoggedIn, action }});
  };

  return [user, userDispatch];
};
