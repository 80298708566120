import React, { FunctionComponent, useEffect, memo, useRef,  } from 'react';
import { Form } from 'react-bootstrap';
import { useFormState } from '../../hooks/useFormState';
import { FormContextProvider, FormContextProviderProps } from './FormContext';
import { isEquivalent } from '../../lib.core';
import { FormState } from './FormState';

export const HookForm: FunctionComponent<any> = memo(({ children, value, valueDep = value, onSubmit, autoSubmit = [], autoSubmitDelay = 500, showFormState = false, ...props }) => {
  const { form: formProps, api, validateAndSubmit } = useFormState(onSubmit);
  const form = useRef<any>(null);
  const previousAutoValue = useRef([]);
  const handler = useRef<any>(null);
  const count = useRef(0);

  const auto = autoSubmit.map(api.getValue);
  useEffect(() => {
    if (!autoSubmit || autoSubmit.length === 0 || form.current === null) return;

    if (previousAutoValue.current.length === 0) {
      // just store for later use
      previousAutoValue.current = auto;
      return;
    }
    if (!isEquivalent(auto, previousAutoValue.current)) {
      if (handler.current) clearTimeout(handler.current);

      handler.current = setTimeout(() => {
        previousAutoValue.current = auto;
        validateAndSubmit(form.current);
      }, autoSubmitDelay);

      return () => {
        handler.current && clearTimeout(handler.current);
        handler.current = null;
      };
    }

  }, auto);

  useEffect(() => {
    count.current++;
    if (count.current === 1) return;
    api.setValues(value);
  }, [valueDep])

  return (
    <Form ref={form} {...props} {...formProps}>
      {children}
      {showFormState && <FormState />}
    </Form>
  );
});

HookForm.defaultProps = {
  value: undefined,
  onSubmit: undefined,
};

interface FormContextFormProps<T> extends FormContextProviderProps<T> {
  showFormState?: boolean;
  valueDep?: any;
}

export const FormContextForm: FunctionComponent<FormContextFormProps<any>> = ({value, meta, children, access, validator, onSubmit, ...props}) => {
  const contextProps = {value, meta, validator, access};


  return (
    <FormContextProvider {...contextProps}>
      <HookForm value={value} {...props} onSubmit={onSubmit}>
        {children}
      </HookForm>
    </FormContextProvider>
  );
};

export const HmFormApi =  ({
  children,
}) => {
  const formState = useFormState();
  return children(formState);
};
