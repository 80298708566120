import React, { FunctionComponent } from 'react';
import { ReactComponent as Discover } from '@lindsey-software/housing-manager-ux/content/img/pay-discover.svg';
import { ReactComponent as Mastercard } from '@lindsey-software/housing-manager-ux/content/img/pay-mastercard.svg';
import { ReactComponent as Amex } from '@lindsey-software/housing-manager-ux/content/img/pay-amex.svg';
import { ReactComponent as Visa } from '@lindsey-software/housing-manager-ux/content/img/pay-visa.svg';
import { ReactComponent as Echeck } from '@lindsey-software/housing-manager-ux/content/img/pay-echeck.svg';
import { IconPayment } from './fontawesome';

export const IconPaymentMethod: FunctionComponent<{
  type: keyof typeof paymentMethods;
  className?: any;
}> = ({ type, ...props }) => {
  const Icon = paymentMethods[type] || IconPayment;
  return <Icon {...props} />;
};

const paymentMethods = {
  discover: Discover,
  3: Discover,
  mastercard: Mastercard,
  2: Mastercard,
  visa: Visa,
  1: Visa,
  amex: Amex,
  4: Amex,
  echeck: Echeck,
  8: Echeck
};
