import React, { FunctionComponent, ReactNode, useReducer, createContext, useContext, Dispatch, useCallback, useRef, useEffect } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import { HmElementGuard, HmAccessGuard } from './security/HmGuard';

interface DialogProps {
  title ?: ReactNode;
  centered ?: boolean;
  modalSize ?: 'sm' | 'md' | 'lg' | 'xl';
  trigger: ReactNode;
}

const dialogActions = {
  close: 'close',
  open: 'open',
};
type DialogActions = {
  close: () => any;
  open: () => any;
};

interface HmDialogContextState {
  dialog: Dispatch<any>;
  actions: DialogActions;
}

export const HmDialogContext = createContext({} as HmDialogContextState);

const actions: DialogActions = Object.keys(dialogActions).reduce((action, key) => {
  const a = dialogActions[key];
  if (typeof a === 'string') {
    action[key] = () => ({ type: key });
  } else {
    action[key] = (...params) => ({ type: key, ...params });
  }
  return action;
} , {} as DialogActions);

function dialogReducer(state, {type}) {
  switch (type) {
    case dialogActions.close:
      return {...state, show: false};
    case dialogActions.open:
      return {...state, show: true};
  }
  return state;
}

export const Dialog: FunctionComponent<DialogProps> = ({
  title,
  children,
  modalSize = 'sm',
  centered = false,
  trigger}) => {
  const mounted = useRef(true);
  const showing = useRef(false);
  const [state, dispatch] = useReducer(dialogReducer, { show: showing.current});
  const dialog = (action) => {
    if (mounted.current) {
      dispatch(action);
    }
  };
  useEffect(() => () => {mounted.current = false; }, []);
  useEffect(() => () => {showing.current = state.show; }, [state.show]);

  return (
    <HmDialogContext.Provider value={{dialog, actions}}>
      {typeof trigger === 'function' ? trigger({dialog, actions}) : trigger}

      <Modal
        show={state.show}
        onHide={() => dialog(actions.close())}
        centered={centered}
        size={modalSize as any}>
        <Modal.Header closeButton>
          {title && <Modal.Title>{title}</Modal.Title>}
        </Modal.Header>
        {children}
      </Modal>

    </HmDialogContext.Provider>
  );
};

interface DialogButtonProps {
  variant ?: any;
  action: keyof typeof dialogActions;
  onClick ?: (e) => Promise<void>;
  as ?: any;
  [x: string]: any;
}

export const DialogButton: FunctionComponent<DialogButtonProps> = ({children, action, onClick, preventDefault=true, ...props}) => {
  const {dialog, actions} = useContext(HmDialogContext);

  const handleClick = useCallback(async (e) => {
    preventDefault && e.preventDefault();
    onClick && await onClick(e);
    dialog(actions[action]());
  }, [action]);

  return (
    <Button {...props} onClick={handleClick}>
      {children}
    </Button>
  );

};

interface HmDialogProps {
  title ?: ReactNode;
  centered ?: boolean;
  disabled ?: boolean;
  disablePortal ?: boolean;
  modalSize ?: 'sm' | 'md' | 'lg' | 'xl';
  variant?: any;
  noAccessProps?: any;
  label: ReactNode;
  labelId?: string;
  size ?: 'sm' | 'lg';
}

/**
 * A helper for creating a Dialog triggered from a button
 */
export const HmDialog: FunctionComponent<HmDialogProps> = ({
  label,
  labelId,
  title,
  children,
  modalSize = 'md',
  centered = false,
  disabled = false,
  disablePortal = false,
  noAccessProps = { disabled: true },
  ...props
}) => {
  const dialogProps = {
    title,
    modalSize,
    centered,
    disablePortal
  };
  return (
      <Dialog
        {...dialogProps}
        trigger={(
          <HmAccessGuard {...noAccessProps}>
            <DialogButton id={labelId} action="open" disabled={disabled} {...props}>{label}</DialogButton>
          </HmAccessGuard>
        )}>
        {children}
      </Dialog>
  );
};

/**
 * A Helper for creating a dialog from a Dropdown
 */
export const HmDropdownDialog: FunctionComponent<HmDialogProps> = ({
  label,
  labelId,
  title = label,
  children,
  modalSize = 'md',
  centered = false,
  noAccessProps = { disabled: true },
  disabled = false}) => {
  const dialogProps = {
    title,
    modalSize,
    centered
  };
  return (
    <Dialog
        {...dialogProps}
        trigger={({dialog, actions}) => {
          return (
            <HmAccessGuard {...noAccessProps}>
            <DropdownItem as="button" id={labelId} disabled={disabled} onClick={() => dialog(actions.open())}>
              {label}
            </DropdownItem>
            </HmAccessGuard>
          );
        }}
        >
        {children}
      </Dialog>
  );
};

interface HmConfirmProps {
  title ?: ReactNode;
  centered ?: boolean;
  modalSize ?: 'sm' | 'md' | 'lg' | 'xl';
  confirmLabel ?: ReactNode;
  variant?: any;
  label: ReactNode;
  cancelLabel ?: ReactNode;
  onConfirm: () => Promise<void>;
  size ?: 'sm' | 'lg';
  disabled?: boolean;
}

export const HmConfirm: FunctionComponent<HmConfirmProps> = ({
  onConfirm,
  label,
  confirmLabel = 'Confirm',
  cancelLabel = 'Cancel',
  title,
  children,
  modalSize = 'md',
  centered = false,
  ...props
}) => {
  const dialogProps = {
    title,
    modalSize,
    centered
  };

  return (
    <Dialog
      {...dialogProps}
      trigger={
        <DialogButton action="open" {...props}>
          {label}
        </DialogButton>}>
      <Modal.Body>
        {React.Children.only(children)}
      </Modal.Body>
      <Modal.Footer>
        <DialogButton action="close" variant="secondary">{cancelLabel}</DialogButton>
        <DialogButton action="close" onClick={onConfirm}>{confirmLabel}</DialogButton>
      </Modal.Footer>
    </Dialog>
  );
};
